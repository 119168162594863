var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "search-browse-filter-comp",
      class: {
        "no-result-found": _vm.noResults,
        "category-page pt-sm-4 pt-xs-4": _vm.isCategoryPage
      }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "shareResourceModal",
          attrs: { size: "medium", heading: _vm.i18n.shareModal.title }
        },
        [
          _c("vx-share-resource", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.shareModal,
              resource: _vm.shareResource
            },
            on: { "share-resource-success": _vm.onShareResourceSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "selectListModal",
          attrs: {
            size: "small",
            heading: _vm.i18n.selectAList.selectListHeading
          }
        },
        [
          _c("vx-save-cart", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.selectAList,
              "is-bulk-enabled": _vm.isBulkEnabled,
              "selected-bulk-products": _vm.selectedBulkProducts,
              "is-product": "true"
            },
            on: { "list-saved": _vm.onSelectListSuccess },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "shareItemModal",
          attrs: {
            size: "large",
            heading: _vm.i18n.shareItemModal.shareItemHeading
          }
        },
        [
          _c("vx-share-item", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.shareItemModal,
              "is-bulk-enabled": _vm.isBulkEnabled
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            !_vm.isCategoryPage
              ? _c(
                  "h1",
                  {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass:
                      "search-heading-desktop visible-lg visible-md hidden-sm hidden-xs px-lg-6 px-md-5 px-sm-3 px-xs-3 pt-sm-2 py-xs-3 my-md-5",
                    attrs: { role: !_vm.noResults ? "alert" : null }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.i18n.searchResults) + "\n      "
                    ),
                    _c("span", { staticClass: "searched-term" }, [
                      _c("q", [_vm._v(_vm._s(_vm.searchData.freeTextSearch))])
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "search-results-heading-section d-flex justify-content-between hidden-lg hidden-md visible-sm visible-xs py-xs-4 pt-sm-4 pb-sm-3 mx-sm-5",
                class: { "empty-search-heading": _vm.noResults }
              },
              [
                !_vm.isCategoryPage
                  ? _c(
                      "h1",
                      {
                        staticClass: "my-xs-0 my-sm-0",
                        attrs: { role: !_vm.noResults ? "alert" : null }
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.i18n.searchResults) +
                            "\n        "
                        ),
                        _c("span", { staticClass: "searched-term" }, [
                          _c("q", { attrs: { role: "heading" } }, [
                            _vm._v(_vm._s(_vm.searchData.freeTextSearch))
                          ])
                        ])
                      ]
                    )
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            !_vm.isCategoryPage && _vm.noResults
              ? _c(
                  "div",
                  {
                    staticClass: "no-results-content pt-xs-3",
                    attrs: {
                      role:
                        !_vm.isCategoryPage && _vm.noResults ? "alert" : null
                    }
                  },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.i18n.noResultMessage) + "\n    "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showContentTab && !_vm.noResults
              ? [
                  _c("div", { staticClass: "tab-container px-sm-5" }, [
                    _c(
                      "ul",
                      {
                        staticClass: "nav nav-tabs px-md-2",
                        attrs: {
                          id: "tabs",
                          role: "tablist",
                          "aria-orientation": "horizontal"
                        }
                      },
                      [
                        _c(
                          "li",
                          {
                            staticClass: "tab-item",
                            class: { active: !_vm.defaultContentTab }
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  id: "tab-products",
                                  "aria-controls": "pane-products",
                                  href: "#pane-products",
                                  "data-toggle": "tab",
                                  role: "tab",
                                  tabindex: "0"
                                }
                              },
                              [_vm._v(_vm._s(_vm.i18n.products))]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.globals.siteConfig.contentSearchEnabled
                          ? _c(
                              "li",
                              {
                                staticClass: "tab-item",
                                class: { active: _vm.defaultContentTab }
                              },
                              [
                                _vm.contentSearchData &&
                                _vm.contentSearchData.length
                                  ? _c(
                                      "a",
                                      {
                                        staticClass: "nav-link",
                                        attrs: {
                                          id: "tab-content",
                                          "aria-controls": "pane-content",
                                          href: "#pane-content",
                                          "data-toggle": "tab",
                                          role: "tab",
                                          tabindex: "0"
                                        }
                                      },
                                      [_vm._v(_vm._s(_vm.i18n.contentTab))]
                                    )
                                  : _vm._e()
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "tab-content",
                      attrs: { id: "content", role: "tablist" }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "card tab-pane fade",
                          class: { "in active": !_vm.defaultContentTab },
                          attrs: {
                            id: "pane-products",
                            role: "tabpanel",
                            "aria-labelledby": "tab-products"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "px-xs-3 card-header",
                              attrs: { role: "tab", id: "heading-products" }
                            },
                            [
                              _c("h5", { staticClass: "mb-0" }, [
                                _c(
                                  "a",
                                  {
                                    class: {
                                      collapsed: !_vm.globals.isVanityfair()
                                    },
                                    attrs: {
                                      "data-toggle": "collapse",
                                      href: "#collapse-products",
                                      "data-parent": "#content",
                                      "aria-expanded": _vm.globals.isVanityfair(),
                                      "aria-controls": "collapse-products"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(_vm.i18n.products) +
                                        " (" +
                                        _vm._s(_vm.searchData.products.length) +
                                        ")\n                "
                                    ),
                                    _c("span", { staticClass: "icon expanded" })
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "px-sm-3 px-md-0 collapse",
                              class: { in: _vm.globals.isVanityfair() },
                              attrs: {
                                id: "collapse-products",
                                role: "tabpanel",
                                "aria-labelledby": "heading-products"
                              }
                            },
                            [
                              _c("div", { staticClass: "card-body" }, [
                                _c(
                                  "div",
                                  {
                                    class: {
                                      "no-result-found m-xs-5 p-xs-5":
                                        _vm.defaultContentTab
                                    }
                                  },
                                  [
                                    _vm.isCategoryPage &&
                                    _vm.isSampleCart &&
                                    _vm.defaultContentTab
                                      ? _c(
                                          "h3",
                                          {
                                            directives: [
                                              {
                                                name: "focus",
                                                rawName: "v-focus"
                                              }
                                            ],
                                            staticClass:
                                              "search-heading-desktop visible-lg visible-md hidden-sm hidden-xs px-lg-6 px-md-5 px-sm-3 px-xs-3 pt-sm-2 py-xs-3 my-md-5"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.i18n.noCategoryResuts
                                                    .heading
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    !_vm.isCategoryPage && _vm.defaultContentTab
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "no-results-content pt-xs-3 px-lg-6 px-md-5 px-sm-3 px-xs-3 pt-sm-2 py-xs-3 my-md-5"
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm.i18n.noResultMessage
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isCategoryPage &&
                                    _vm.isSampleCart &&
                                    _vm.defaultContentTab
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "no-results-content pt-xs-3 px-lg-6 px-md-5 px-sm-3 px-xs-3 pt-sm-2 py-xs-3 my-md-5"
                                          },
                                          [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.noCategoryResuts
                                                    .message1
                                                )
                                              )
                                            ]),
                                            _vm._v(" "),
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.i18n.noCategoryResuts
                                                    .message2
                                                )
                                              )
                                            ])
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.noResults && !_vm.defaultContentTab
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "search-browse row mx-xs-0"
                                      },
                                      [
                                        _c("focus-trap", [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "facet-container col-lg-3",
                                              class: {
                                                "filter-mobile-view": !_vm.filterHidden
                                              }
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "row" },
                                                [
                                                  !_vm.filterHidden
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "refine-results-heading col-xs-12 hidden-lg hidden-md visible-sm visible-xs py-xs-3 px-sm-3 px-xs-3"
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "heading-cls",
                                                              attrs: {
                                                                tabindex: "1"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.i18n
                                                                    .refineResults
                                                                ) +
                                                                  "\n                        "
                                                              )
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c("span", {
                                                            staticClass:
                                                              "icon-x",
                                                            attrs: {
                                                              "aria-label":
                                                                _vm.i18n
                                                                  .iconXTitle,
                                                              role: "button",
                                                              tabindex: "0",
                                                              title:
                                                                _vm.i18n
                                                                  .iconXTitle
                                                            },
                                                            on: {
                                                              click:
                                                                _vm.toggleFilter,
                                                              keyup: function(
                                                                $event
                                                              ) {
                                                                if (
                                                                  !(
                                                                    "button" in
                                                                    $event
                                                                  ) &&
                                                                  _vm._k(
                                                                    $event.keyCode,
                                                                    "enter-space",
                                                                    undefined,
                                                                    $event.key,
                                                                    undefined
                                                                  )
                                                                ) {
                                                                  return null
                                                                }
                                                                return _vm.toggleFilter(
                                                                  $event
                                                                )
                                                              }
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "facet-wrapper col-xs-12 px-xs-0"
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass: "sr-only"
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.i18n
                                                                .filterPanel
                                                            )
                                                          )
                                                        ]
                                                      ),
                                                      _vm._v(" "),
                                                      !_vm.filterHidden
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "hidden-lg hidden-md visible-sm visible-xs pt-xs-3 pb-xs-4 px-md-3 px-sm-3 px-xs-3"
                                                            },
                                                            [
                                                              _c(
                                                                "vx-dropdown-primary",
                                                                {
                                                                  ref: "sortBy",
                                                                  attrs: {
                                                                    dropdownValues:
                                                                      _vm.sortsArray
                                                                  },
                                                                  on: {
                                                                    "selected-option": function(
                                                                      $event
                                                                    ) {
                                                                      _vm.updateSortBy(
                                                                        $event
                                                                      )
                                                                    }
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      _vm._l(
                                                        _vm.searchData.facets,
                                                        function(filter) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "facet-category ml-md-3 pl-md-4 pr-md-3 px-sm-3 px-xs-3"
                                                            },
                                                            [
                                                              filter.name.toLowerCase() ===
                                                              _vm.facetName.ratingLabel.toLowerCase()
                                                                ? _c("div", [
                                                                    _vm.isBazaarVoice ===
                                                                    "true"
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "category-name",
                                                                                attrs: {
                                                                                  role:
                                                                                    "heading"
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "filter-name"
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      _vm._s(
                                                                                        filter.name
                                                                                      )
                                                                                    )
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      : _vm._e()
                                                                  ])
                                                                : _c("div", [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "category-name d-flex",
                                                                        attrs: {
                                                                          role:
                                                                            "heading"
                                                                        }
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "span",
                                                                          {
                                                                            staticClass:
                                                                              "filter-name"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                filter.name
                                                                              )
                                                                            )
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        filter.count
                                                                          ? _c(
                                                                              "p",
                                                                              {
                                                                                staticClass:
                                                                                  "facet-count"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\n                                (" +
                                                                                    _vm._s(
                                                                                      filter.count
                                                                                    ) +
                                                                                    ")\n                              "
                                                                                )
                                                                              ]
                                                                            )
                                                                          : _vm._e()
                                                                      ]
                                                                    )
                                                                  ]),
                                                              _vm._v(" "),
                                                              filter.name.toLowerCase() ===
                                                              _vm.facetName.ratingLabel.toLowerCase()
                                                                ? _c("div", [
                                                                    _vm.isBazaarVoice ===
                                                                    "true"
                                                                      ? _c(
                                                                          "div",
                                                                          _vm._l(
                                                                            filter.values,
                                                                            function(
                                                                              option,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "div",
                                                                                {
                                                                                  key:
                                                                                    option.name
                                                                                },
                                                                                [
                                                                                  option.name.charAt(
                                                                                    0
                                                                                  ) !==
                                                                                  "0"
                                                                                    ? _c(
                                                                                        "div",
                                                                                        {
                                                                                          staticClass:
                                                                                            "form-group rating-facet"
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "input",
                                                                                            {
                                                                                              ref:
                                                                                                option.name +
                                                                                                "-radio",
                                                                                              refInFor: true,
                                                                                              staticClass:
                                                                                                "m-a-0",
                                                                                              attrs: {
                                                                                                type:
                                                                                                  "radio",
                                                                                                name:
                                                                                                  "rating",
                                                                                                id:
                                                                                                  "radio-" +
                                                                                                  option.name
                                                                                              },
                                                                                              domProps: {
                                                                                                checked:
                                                                                                  option.selected
                                                                                              },
                                                                                              on: {
                                                                                                click: function(
                                                                                                  $event
                                                                                                ) {
                                                                                                  _vm.updateFilterList(
                                                                                                    filter.name,
                                                                                                    option
                                                                                                  )
                                                                                                }
                                                                                              }
                                                                                            }
                                                                                          ),
                                                                                          _vm._v(
                                                                                            " "
                                                                                          ),
                                                                                          _c(
                                                                                            "label",
                                                                                            {
                                                                                              staticClass:
                                                                                                "checked-rating",
                                                                                              attrs: {
                                                                                                for:
                                                                                                  "radio-" +
                                                                                                  option.name
                                                                                              }
                                                                                            },
                                                                                            [
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  staticClass:
                                                                                                    "sr-only"
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      option.name
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "vx-star-rating",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "product-rating": option.name.charAt(
                                                                                                      0
                                                                                                    ),
                                                                                                    i18n:
                                                                                                      _vm.i18n,
                                                                                                    "show-rating":
                                                                                                      _vm.showRating,
                                                                                                    "show-reviews":
                                                                                                      _vm.showReviews,
                                                                                                    "number-of-stars": option.name.charAt(
                                                                                                      0
                                                                                                    )
                                                                                                  }
                                                                                                }
                                                                                              ),
                                                                                              _vm._v(
                                                                                                " "
                                                                                              ),
                                                                                              _c(
                                                                                                "span",
                                                                                                {
                                                                                                  attrs: {
                                                                                                    "aria-hidden":
                                                                                                      "true"
                                                                                                  }
                                                                                                },
                                                                                                [
                                                                                                  _vm._v(
                                                                                                    _vm._s(
                                                                                                      _vm
                                                                                                        .i18n
                                                                                                        .facetUpLabel
                                                                                                    )
                                                                                                  )
                                                                                                ]
                                                                                              )
                                                                                            ],
                                                                                            1
                                                                                          )
                                                                                        ]
                                                                                      )
                                                                                    : _vm._e()
                                                                                ]
                                                                              )
                                                                            }
                                                                          )
                                                                        )
                                                                      : _vm._e()
                                                                  ])
                                                                : _c(
                                                                    "div",
                                                                    _vm._l(
                                                                      filter.values,
                                                                      function(
                                                                        option,
                                                                        index
                                                                      ) {
                                                                        return _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              "div",
                                                                              {
                                                                                staticClass:
                                                                                  "form-group"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "input",
                                                                                  {
                                                                                    ref:
                                                                                      option.name +
                                                                                      "-checkbox",
                                                                                    refInFor: true,
                                                                                    staticClass:
                                                                                      "m-a-0",
                                                                                    attrs: {
                                                                                      type:
                                                                                        "checkbox",
                                                                                      id:
                                                                                        "checkbox-" +
                                                                                        option.name +
                                                                                        filter.name
                                                                                    },
                                                                                    domProps: {
                                                                                      checked:
                                                                                        option.selected
                                                                                    },
                                                                                    on: {
                                                                                      click: function(
                                                                                        $event
                                                                                      ) {
                                                                                        _vm.updateFilterList(
                                                                                          filter.name,
                                                                                          option
                                                                                        )
                                                                                      }
                                                                                    }
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "label",
                                                                                  {
                                                                                    staticClass:
                                                                                      "d-flex",
                                                                                    attrs: {
                                                                                      for:
                                                                                        "checkbox-" +
                                                                                        option.name +
                                                                                        filter.name
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _c(
                                                                                      "p",
                                                                                      [
                                                                                        _vm._v(
                                                                                          _vm._s(
                                                                                            option.name
                                                                                          )
                                                                                        )
                                                                                      ]
                                                                                    ),
                                                                                    _vm._v(
                                                                                      " "
                                                                                    ),
                                                                                    option.count &&
                                                                                    filter.name.toLowerCase() !==
                                                                                      _vm.facetName.availableForLocationLabel.toLowerCase()
                                                                                      ? _c(
                                                                                          "p",
                                                                                          {
                                                                                            staticClass:
                                                                                              "facet-count"
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              "\n                                    (" +
                                                                                                _vm._s(
                                                                                                  option.count
                                                                                                ) +
                                                                                                ")\n                                  "
                                                                                            )
                                                                                          ]
                                                                                        )
                                                                                      : _vm._e()
                                                                                  ]
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        )
                                                                      }
                                                                    )
                                                                  )
                                                            ]
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    2
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-default col-xs-12 hidden-lg hidden-md visible-sm visible-xs",
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.resetFilter()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.i18n
                                                              .clearAllText
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "clear-all-filter visible-lg visible-md hidden-sm hidden-xs mr-md-5",
                                                      attrs: {
                                                        tabindex: "0",
                                                        role: "button"
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.resetFilter()
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            _vm.i18n
                                                              .clearAllText
                                                          ) +
                                                          "\n                      "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "tile-container col-lg-9 pt-xs-0 px-md-2"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "header-bar d-flex flex-column mx-xs-3 pb-xs-0 pt-md-4",
                                                class: {
                                                  "no-border pb-md-4": !_vm.isBulkEnabled
                                                }
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex header-dropdown"
                                                  },
                                                  [
                                                    _vm.searchData &&
                                                    _vm.searchData.pagination &&
                                                    _vm.searchData.pagination
                                                      .totalResults
                                                      ? _c(
                                                          "h1",
                                                          {
                                                            staticClass:
                                                              "heading pb-sm-2 pb-xs-2 pb-md-0 m-xs-0 pt-xs-3 pb-xs-2",
                                                            attrs: {
                                                              "aria-hidden":
                                                                "true"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm.i18n
                                                                    .products
                                                                ) +
                                                                " -\n                        "
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "product-length-section"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm
                                                                      .searchData
                                                                      .pagination
                                                                      .totalResults
                                                                  ) +
                                                                    "\n                          " +
                                                                    _vm._s(
                                                                      _vm.i18n
                                                                        .countUnit
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.searchData &&
                                                    _vm.searchData.pagination &&
                                                    _vm.searchData.pagination
                                                      .totalResults
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "sr-only",
                                                            attrs: {
                                                              "aria-hidden": _vm.isMobile()
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.i18n
                                                                  .products
                                                              ) +
                                                                "\n                        " +
                                                                _vm._s(
                                                                  _vm.searchData
                                                                    .pagination
                                                                    .totalResults
                                                                ) +
                                                                "\n                        " +
                                                                _vm._s(
                                                                  _vm.i18n
                                                                    .countUnit
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.filterHidden
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "visible-lg visible-md hidden-sm hidden-xs px-md-0"
                                                          },
                                                          [
                                                            _c(
                                                              "vx-dropdown-primary",
                                                              {
                                                                ref: "sortBy",
                                                                attrs: {
                                                                  dropdownValues:
                                                                    _vm.sortsArray
                                                                },
                                                                on: {
                                                                  "selected-option": function(
                                                                    $event
                                                                  ) {
                                                                    _vm.updateSortBy(
                                                                      $event
                                                                    )
                                                                  }
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    !_vm.noResults
                                                      ? _c("button", {
                                                          staticClass:
                                                            "icon-filter hidden-lg hidden-md",
                                                          attrs: {
                                                            tabindex: "0",
                                                            "aria-label":
                                                              _vm.i18n
                                                                .iconFilterTitle,
                                                            title:
                                                              _vm.i18n
                                                                .iconFilterTitle
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.toggleFilter(
                                                                $event
                                                              )
                                                            },
                                                            keyup: function(
                                                              $event
                                                            ) {
                                                              if (
                                                                !(
                                                                  "button" in
                                                                  $event
                                                                ) &&
                                                                _vm._k(
                                                                  $event.keyCode,
                                                                  "enter-space",
                                                                  undefined,
                                                                  $event.key,
                                                                  undefined
                                                                )
                                                              ) {
                                                                return null
                                                              }
                                                              _vm.toggleFilter(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        })
                                                      : _vm._e()
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex applied-filter-section"
                                                  },
                                                  _vm._l(
                                                    _vm.appliedFilterList,
                                                    function(currentFilter) {
                                                      return _vm
                                                        .appliedFilterList
                                                        .length > 0
                                                        ? _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "applied-filter d-flex"
                                                            },
                                                            [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "filter-list tag align-items-center"
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "tag-content",
                                                                      attrs: {
                                                                        "aria-label":
                                                                          "Range:" +
                                                                          currentFilter.filterValue
                                                                      }
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        _vm._s(
                                                                          currentFilter.filterValue
                                                                        )
                                                                      )
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c("span", {
                                                                    staticClass:
                                                                      "icon-x",
                                                                    attrs: {
                                                                      tabindex:
                                                                        "0",
                                                                      role:
                                                                        "button",
                                                                      "aria-label":
                                                                        _vm.i18n
                                                                          .iconXTitle,
                                                                      title:
                                                                        _vm.i18n
                                                                          .iconXTitle
                                                                    },
                                                                    on: {
                                                                      keyup: function(
                                                                        $event
                                                                      ) {
                                                                        if (
                                                                          !(
                                                                            "button" in
                                                                            $event
                                                                          ) &&
                                                                          _vm._k(
                                                                            $event.keyCode,
                                                                            "enter-space",
                                                                            undefined,
                                                                            $event.key,
                                                                            undefined
                                                                          )
                                                                        ) {
                                                                          return null
                                                                        }
                                                                        _vm.removeFilters(
                                                                          currentFilter
                                                                        )
                                                                      },
                                                                      click: function(
                                                                        $event
                                                                      ) {
                                                                        _vm.removeFilters(
                                                                          currentFilter
                                                                        )
                                                                      }
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        : _vm._e()
                                                    }
                                                  )
                                                ),
                                                _vm._v(" "),
                                                _vm.isBulkEnabled &&
                                                _vm.searchData.products.length >
                                                  0
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-between applied-filter-section mt-sm-4"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "form-group",
                                                            attrs: {
                                                              title:
                                                                _vm.i18n
                                                                  .selectAllTitle
                                                            }
                                                          },
                                                          [
                                                            _c("input", {
                                                              attrs: {
                                                                type:
                                                                  "checkbox",
                                                                id: "selectAll"
                                                              },
                                                              domProps: {
                                                                checked:
                                                                  _vm.selectAll
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.onSelectAll(
                                                                    $event
                                                                  )
                                                                }
                                                              }
                                                            }),
                                                            _c(
                                                              "label",
                                                              {
                                                                attrs: {
                                                                  for:
                                                                    "selectAll"
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.i18n
                                                                      .selectAll
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "products-selected"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm
                                                                  .selectedBulkProducts
                                                                  .length
                                                              ) +
                                                                "\n                        " +
                                                                _vm._s(
                                                                  _vm.i18n
                                                                    .productsSelected
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm._m(0)
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "list-page-wrapper",
                                                class: {
                                                  "no-filter": !_vm
                                                    .appliedFilterList.length
                                                }
                                              },
                                              [
                                                _c("vx-list-page", {
                                                  attrs: {
                                                    products: _vm.productList,
                                                    "is-favorites":
                                                      _vm.isFavorites,
                                                    "is-bazaar-voice":
                                                      _vm.isBazaarVoice,
                                                    "is-bulk-enabled":
                                                      _vm.isBulkEnabled,
                                                    "search-browse-i18n":
                                                      _vm.searchBrowseI18n,
                                                    "single-product-enabled":
                                                      _vm.singleProductEnabled
                                                  },
                                                  on: {
                                                    loadMoreProducts:
                                                      _vm.loadMore
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "row bulk-btn-container"
                                          },
                                          [
                                            _vm.showPanel
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "bulk-btn-container-inner col-sm-12 px-xs-0 px-sm-5 px-md-4 d-flex justify-content-end pr-md-3 bulk-btn"
                                                  },
                                                  [
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.i18n.share
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.handleShareItem(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n.share
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                                        class: {
                                                          disabled:
                                                            _vm.isCompareDisabled
                                                        },
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.i18n.compare,
                                                          disabled:
                                                            _vm.isCompareDisabled
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.handleCompare(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n.compare
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.compareCount
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.i18n.addToList
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.handleSelectList(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n.addToList
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "button",
                                                      {
                                                        staticClass:
                                                          "btn button-color text-trans mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                                        attrs: {
                                                          "aria-label":
                                                            _vm.i18n.addToCart
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.handleAddToCart(
                                                              $event
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n.addToCart
                                                            ) +
                                                            "\n                    "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e()
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("div", {
                                          ref: "lazyLoad",
                                          attrs: { id: "lazyLoad" }
                                        })
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            ]
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _vm.contentSearchData &&
                      _vm.contentSearchData.length &&
                      _vm.globals.siteConfig.contentSearchEnabled
                        ? _c(
                            "div",
                            {
                              staticClass: "card tab-pane fade",
                              class: { "in active": _vm.defaultContentTab },
                              attrs: {
                                id: "pane-content",
                                role: "tabpanel",
                                "aria-labelledby": "tab-content"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "px-xs-3 card-header pt-xs-3 pb-xs-2",
                                  attrs: { role: "tab", id: "heading-content" }
                                },
                                [
                                  _c("h5", { staticClass: "mb-0" }, [
                                    _vm.contentSearch.pagination &&
                                    _vm.contentSearch.pagination.totalResults
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "collapsed",
                                            attrs: {
                                              "data-toggle": "collapse",
                                              href: "#collapse-content",
                                              "data-parent": "#content",
                                              "aria-expanded": "false",
                                              "aria-controls":
                                                "collapse-content"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.i18n.contentTab) +
                                                " (" +
                                                _vm._s(
                                                  _vm.contentSearch.pagination
                                                    .totalResults
                                                ) +
                                                ")\n                "
                                            ),
                                            _c("span", {
                                              staticClass: "icon expanded"
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "px-sm-3 px-md-0 collapse",
                                  attrs: {
                                    id: "collapse-content",
                                    role: "tabpanel",
                                    "aria-labelledby": "heading-content"
                                  }
                                },
                                [
                                  _c("div", { staticClass: "card-body" }, [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "tile-container col-lg-12 pt-xs-0 px-sm-3 py-sm-3"
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "header-bar d-flex flex-column pb-xs-0 pb-md-3 px-md-3 py-md-5 mx-sm-0 pb-sm-3 mx-md-3"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "d-flex header-dropdown"
                                              },
                                              [
                                                _vm.contentSearch &&
                                                _vm.contentSearch.pagination &&
                                                _vm.contentSearch.pagination
                                                  .totalResults
                                                  ? _c(
                                                      "h1",
                                                      {
                                                        staticClass:
                                                          "heading pb-sm-2 pb-xs-2 pb-md-0 m-xs-0 visible-lg visible-md visible-sm hidden-xs",
                                                        attrs: {
                                                          "aria-hidden": "true"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n
                                                                .contentTab
                                                            ) +
                                                            " -\n                      "
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "product-length-section"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  _vm
                                                                    .contentSearch
                                                                    .pagination
                                                                    .totalResults
                                                                ) +
                                                                "\n                        " +
                                                                _vm._s(
                                                                  _vm.i18n
                                                                    .countUnit
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.searchData &&
                                                _vm.searchData.pagination &&
                                                _vm.searchData.pagination
                                                  .totalResults
                                                  ? _c(
                                                      "span",
                                                      {
                                                        staticClass: "sr-only",
                                                        attrs: {
                                                          "aria-hidden": _vm.isMobile()
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.i18n.products
                                                          ) +
                                                            "\n                      " +
                                                            _vm._s(
                                                              _vm.searchData
                                                                .pagination
                                                                .totalResults
                                                            ) +
                                                            "\n                      " +
                                                            _vm._s(
                                                              _vm.i18n.countUnit
                                                            )
                                                        )
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _vm.filterHidden
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "visible-lg visible-md visible-sm visible-xs px-md-0 content-dropdown py-xs-3"
                                                      },
                                                      [
                                                        _c(
                                                          "vx-dropdown-primary",
                                                          {
                                                            ref:
                                                              "contentSortBy",
                                                            attrs: {
                                                              dropdownValues:
                                                                _vm.contentSortArray
                                                            },
                                                            on: {
                                                              "selected-option": function(
                                                                $event
                                                              ) {
                                                                _vm.updateContentSortBy(
                                                                  $event
                                                                )
                                                              }
                                                            }
                                                          }
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm._m(1)
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(_vm.contentSearchData, function(
                                          product
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "px-md-2 py-md-3 mx-md-3 content-section py-sm-3 py-xs-3 px-xs-3 px-sm-0"
                                            },
                                            [
                                              product.contentType ===
                                              _vm.contentType.page
                                                ? _c(
                                                    "a",
                                                    {
                                                      attrs: {
                                                        href:
                                                          _vm.globals
                                                            .contextPath +
                                                          product.url
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(product.title)
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "a",
                                                    {
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.openShareModal(
                                                            product
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(product.title)
                                                      )
                                                    ]
                                                  ),
                                              _vm._v(" "),
                                              _c("p", [
                                                _vm._v(
                                                  _vm._s(product.description)
                                                )
                                              ])
                                            ]
                                          )
                                        }),
                                        _vm._v(" "),
                                        _c("div", {
                                          ref: "lazyLoad",
                                          attrs: { id: "lazyLoad" }
                                        })
                                      ],
                                      2
                                    )
                                  ])
                                ]
                              ),
                              _vm._v(" "),
                              _vm.showBackToTop
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-secondary back-to-top-icon",
                                      on: {
                                        click: function($event) {
                                          _vm.onBackToTop($event)
                                        }
                                      }
                                    },
                                    [
                                      _c("span", {
                                        staticClass: "icon-chevron-up"
                                      })
                                    ]
                                  )
                                : _vm._e()
                            ]
                          )
                        : _vm._e()
                    ]
                  )
                ]
              : [
                  _c("div", { staticClass: "card-body" }, [
                    !_vm.noResults
                      ? _c(
                          "div",
                          { staticClass: "search-browse row mx-xs-0" },
                          [
                            _c("focus-trap", [
                              _c(
                                "div",
                                {
                                  staticClass: "facet-container col-lg-3",
                                  class: {
                                    "filter-mobile-view": !_vm.filterHidden
                                  }
                                },
                                [
                                  _c("div", { staticClass: "row" }, [
                                    !_vm.filterHidden
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "refine-results-heading col-xs-12 hidden-lg hidden-md visible-sm visible-xs py-xs-3 px-sm-3 px-xs-3"
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "heading-cls",
                                                attrs: { tabindex: "1" }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.i18n.refineResults
                                                  ) + "\n                  "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("span", {
                                              staticClass: "icon-x",
                                              attrs: {
                                                "aria-label":
                                                  _vm.i18n.iconXTitle,
                                                role: "button",
                                                tabindex: "0",
                                                title: _vm.i18n.iconXTitle
                                              },
                                              on: {
                                                click: _vm.toggleFilter,
                                                keyup: function($event) {
                                                  if (
                                                    !("button" in $event) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter-space",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  return _vm.toggleFilter(
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "facet-wrapper col-xs-12 px-xs-0"
                                      },
                                      [
                                        _c("span", { staticClass: "sr-only" }, [
                                          _vm._v(_vm._s(_vm.i18n.filterPanel))
                                        ]),
                                        _vm._v(" "),
                                        !_vm.filterHidden && !_vm.hideSortBy
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "hidden-lg hidden-md visible-sm visible-xs pt-xs-3 pb-xs-4 px-md-3 px-sm-3 px-xs-3"
                                              },
                                              [
                                                _c("vx-dropdown-primary", {
                                                  ref: "sortBy",
                                                  attrs: {
                                                    dropdownValues:
                                                      _vm.sortsArray
                                                  },
                                                  on: {
                                                    "selected-option": function(
                                                      $event
                                                    ) {
                                                      _vm.updateSortBy($event)
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm._l(_vm.searchData.facets, function(
                                          filter
                                        ) {
                                          return _c(
                                            "div",
                                            {
                                              staticClass:
                                                "facet-category ml-md-3 pl-md-4 pr-md-3 px-sm-3 px-xs-3"
                                            },
                                            [
                                              filter.name.toLowerCase() ===
                                              _vm.facetName.ratingLabel.toLowerCase()
                                                ? _c("div", [
                                                    _vm.isBazaarVoice === "true"
                                                      ? _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "category-name",
                                                              attrs: {
                                                                role: "heading"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "filter-name"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      filter.name
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      : _vm._e()
                                                  ])
                                                : _c("div", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "category-name d-flex",
                                                        attrs: {
                                                          role: "heading"
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "filter-name"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                filter.name
                                                              )
                                                            )
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        filter.count
                                                          ? _c(
                                                              "p",
                                                              {
                                                                staticClass:
                                                                  "facet-count"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          (" +
                                                                    _vm._s(
                                                                      filter.count
                                                                    ) +
                                                                    ")\n                        "
                                                                )
                                                              ]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    )
                                                  ]),
                                              _vm._v(" "),
                                              filter.name.toLowerCase() ===
                                              _vm.facetName.ratingLabel.toLowerCase()
                                                ? _c("div", [
                                                    _vm.isBazaarVoice === "true"
                                                      ? _c(
                                                          "div",
                                                          _vm._l(
                                                            filter.values,
                                                            function(
                                                              option,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key:
                                                                    option.name
                                                                },
                                                                [
                                                                  option.name.charAt(
                                                                    0
                                                                  ) !== "0"
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "form-group rating-facet"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "input",
                                                                            {
                                                                              ref:
                                                                                option.name +
                                                                                "-radio",
                                                                              refInFor: true,
                                                                              staticClass:
                                                                                "m-a-0",
                                                                              attrs: {
                                                                                type:
                                                                                  "radio",
                                                                                name:
                                                                                  "rating",
                                                                                id:
                                                                                  "radio-" +
                                                                                  option.name
                                                                              },
                                                                              domProps: {
                                                                                checked:
                                                                                  option.selected
                                                                              },
                                                                              on: {
                                                                                click: function(
                                                                                  $event
                                                                                ) {
                                                                                  _vm.updateFilterList(
                                                                                    filter.name,
                                                                                    option
                                                                                  )
                                                                                }
                                                                              }
                                                                            }
                                                                          ),
                                                                          _vm._v(
                                                                            " "
                                                                          ),
                                                                          _c(
                                                                            "label",
                                                                            {
                                                                              staticClass:
                                                                                "checked-rating",
                                                                              attrs: {
                                                                                for:
                                                                                  "radio-" +
                                                                                  option.name
                                                                              }
                                                                            },
                                                                            [
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  staticClass:
                                                                                    "sr-only"
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      option.name
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "vx-star-rating",
                                                                                {
                                                                                  attrs: {
                                                                                    "product-rating": option.name.charAt(
                                                                                      0
                                                                                    ),
                                                                                    i18n:
                                                                                      _vm.i18n,
                                                                                    "show-rating":
                                                                                      _vm.showRating,
                                                                                    "show-reviews":
                                                                                      _vm.showReviews,
                                                                                    "number-of-stars": option.name.charAt(
                                                                                      0
                                                                                    )
                                                                                  }
                                                                                }
                                                                              ),
                                                                              _vm._v(
                                                                                " "
                                                                              ),
                                                                              _c(
                                                                                "span",
                                                                                {
                                                                                  attrs: {
                                                                                    "aria-hidden":
                                                                                      "true"
                                                                                  }
                                                                                },
                                                                                [
                                                                                  _vm._v(
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .i18n
                                                                                        .facetUpLabel
                                                                                    )
                                                                                  )
                                                                                ]
                                                                              )
                                                                            ],
                                                                            1
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            }
                                                          )
                                                        )
                                                      : _vm._e()
                                                  ])
                                                : _c(
                                                    "div",
                                                    _vm._l(
                                                      filter.values,
                                                      function(option, index) {
                                                        return _c("div", [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "form-group"
                                                            },
                                                            [
                                                              _c("input", {
                                                                ref:
                                                                  option.name +
                                                                  "-checkbox",
                                                                refInFor: true,
                                                                staticClass:
                                                                  "m-a-0",
                                                                attrs: {
                                                                  type:
                                                                    "checkbox",
                                                                  id:
                                                                    "checkbox-" +
                                                                    option.name +
                                                                    filter.name
                                                                },
                                                                domProps: {
                                                                  checked:
                                                                    option.selected
                                                                },
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    _vm.updateFilterList(
                                                                      filter.name,
                                                                      option
                                                                    )
                                                                  }
                                                                }
                                                              }),
                                                              _vm._v(" "),
                                                              _c(
                                                                "label",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                  attrs: {
                                                                    for:
                                                                      "checkbox-" +
                                                                      option.name +
                                                                      filter.name
                                                                  }
                                                                },
                                                                [
                                                                  _c("p", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        option.name
                                                                      )
                                                                    )
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  option.count &&
                                                                  filter.name.toLowerCase() !==
                                                                    _vm.facetName.availableForLocationLabel.toLowerCase()
                                                                    ? _c(
                                                                        "p",
                                                                        {
                                                                          staticClass:
                                                                            "facet-count"
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            "\n                              (" +
                                                                              _vm._s(
                                                                                option.count
                                                                              ) +
                                                                              ")\n                            "
                                                                          )
                                                                        ]
                                                                      )
                                                                    : _vm._e()
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ])
                                                      }
                                                    )
                                                  )
                                            ]
                                          )
                                        })
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "btn btn-default col-xs-12 hidden-lg hidden-md visible-sm visible-xs",
                                        on: {
                                          click: function($event) {
                                            _vm.resetFilter()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.i18n.clearAllText) +
                                            "\n                "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "clear-all-filter visible-lg visible-md hidden-sm hidden-xs mr-md-5",
                                        attrs: {
                                          tabindex: "0",
                                          role: "button"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.resetFilter()
                                          }
                                        }
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(_vm.i18n.clearAllText) +
                                            "\n                "
                                        )
                                      ]
                                    )
                                  ])
                                ]
                              )
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tile-container col-lg-9 pt-xs-0 pl-md-2 pr-md-4 px-sm-3"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "header-bar d-flex flex-column mx-xs-3 pb-xs-0 pt-md-4",
                                    class: {
                                      "no-border pb-md-4": !_vm.isBulkEnabled
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex header-dropdown" },
                                      [
                                        _vm.searchData &&
                                        _vm.searchData.pagination &&
                                        _vm.searchData.pagination.totalResults
                                          ? _c(
                                              "h1",
                                              {
                                                staticClass:
                                                  "heading pb-sm-2 pb-xs-2 pb-md-0 m-xs-0 pt-xs-3 pb-xs-2",
                                                attrs: { "aria-hidden": "true" }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(_vm.i18n.products) +
                                                    " -\n                  "
                                                ),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "product-length-section"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.searchData
                                                          .pagination
                                                          .totalResults
                                                      ) +
                                                        "\n                    " +
                                                        _vm._s(
                                                          _vm.i18n.countUnit
                                                        ) +
                                                        "\n                  "
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.searchData &&
                                        _vm.searchData.pagination &&
                                        _vm.searchData.pagination.totalResults
                                          ? _c(
                                              "span",
                                              {
                                                staticClass: "sr-only",
                                                attrs: {
                                                  "aria-hidden": _vm.isMobile()
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(_vm.i18n.products) +
                                                    "\n                  " +
                                                    _vm._s(
                                                      _vm.searchData.pagination
                                                        .totalResults
                                                    ) +
                                                    "\n                  " +
                                                    _vm._s(_vm.i18n.countUnit)
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.filterHidden && !_vm.hideSortBy
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "visible-lg visible-md hidden-sm hidden-xs px-md-0"
                                              },
                                              [
                                                _c("vx-dropdown-primary", {
                                                  ref: "sortBy",
                                                  attrs: {
                                                    dropdownValues:
                                                      _vm.sortsArray
                                                  },
                                                  on: {
                                                    "selected-option": function(
                                                      $event
                                                    ) {
                                                      _vm.updateSortBy($event)
                                                    }
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        !_vm.noResults
                                          ? _c("button", {
                                              staticClass:
                                                "icon-filter hidden-lg hidden-md",
                                              attrs: {
                                                tabindex: "0",
                                                "aria-label":
                                                  _vm.i18n.iconFilterTitle,
                                                title: _vm.i18n.iconFilterTitle
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.toggleFilter($event)
                                                },
                                                keyup: function($event) {
                                                  if (
                                                    !("button" in $event) &&
                                                    _vm._k(
                                                      $event.keyCode,
                                                      "enter-space",
                                                      undefined,
                                                      $event.key,
                                                      undefined
                                                    )
                                                  ) {
                                                    return null
                                                  }
                                                  _vm.toggleFilter($event)
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex applied-filter-section"
                                      },
                                      _vm._l(_vm.appliedFilterList, function(
                                        currentFilter
                                      ) {
                                        return _vm.appliedFilterList.length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "applied-filter d-flex"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "filter-list tag align-items-center"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "tag-content",
                                                        attrs: {
                                                          "aria-label":
                                                            "Range:" +
                                                            currentFilter.filterValue
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            currentFilter.filterValue
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("span", {
                                                      staticClass: "icon-x",
                                                      attrs: {
                                                        tabindex: "0",
                                                        role: "button",
                                                        title:
                                                          _vm.i18n.iconXTitle
                                                      },
                                                      on: {
                                                        keyup: function(
                                                          $event
                                                        ) {
                                                          if (
                                                            !(
                                                              "button" in $event
                                                            ) &&
                                                            _vm._k(
                                                              $event.keyCode,
                                                              "enter-space",
                                                              undefined,
                                                              $event.key,
                                                              undefined
                                                            )
                                                          ) {
                                                            return null
                                                          }
                                                          _vm.removeFilters(
                                                            currentFilter
                                                          )
                                                        },
                                                        click: function(
                                                          $event
                                                        ) {
                                                          _vm.removeFilters(
                                                            currentFilter
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      })
                                    ),
                                    _vm._v(" "),
                                    _vm.isBulkEnabled &&
                                    _vm.searchData.products.length > 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "d-flex justify-content-between applied-filter-section mt-sm-4"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "form-group",
                                                attrs: {
                                                  title: _vm.i18n.selectAllTitle
                                                }
                                              },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    type: "checkbox",
                                                    id: "selectAll"
                                                  },
                                                  domProps: {
                                                    checked: _vm.selectAll
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.onSelectAll($event)
                                                    }
                                                  }
                                                }),
                                                _c(
                                                  "label",
                                                  {
                                                    attrs: { for: "selectAll" }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(_vm.i18n.selectAll)
                                                    )
                                                  ]
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "products-selected"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.selectedBulkProducts
                                                      .length
                                                  ) +
                                                    "\n                  " +
                                                    _vm._s(
                                                      _vm.i18n.productsSelected
                                                    )
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm._m(2)
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "list-page-wrapper",
                                    class: {
                                      "no-filter": !_vm.appliedFilterList.length
                                    }
                                  },
                                  [
                                    _c("vx-list-page", {
                                      attrs: {
                                        products: _vm.productList,
                                        "is-favorites": _vm.isFavorites,
                                        "is-bazaar-voice": _vm.isBazaarVoice,
                                        "is-bulk-enabled": _vm.isBulkEnabled,
                                        "search-browse-i18n":
                                          _vm.searchBrowseI18n,
                                        "single-product-enabled":
                                          _vm.singleProductEnabled
                                      },
                                      on: { loadMoreProducts: _vm.loadMore }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "row bulk-btn-container" },
                              [
                                _vm.showPanel
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "bulk-btn-container-inner col-sm-12 px-xs-0  px-sm-5 px-md-4 d-flex justify-content-end pr-md-3 bulk-btn"
                                      },
                                      [
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                            attrs: {
                                              "aria-label": _vm.i18n.share
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleShareItem($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.i18n.share) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                            class: {
                                              disabled: _vm.isCompareDisabled
                                            },
                                            attrs: {
                                              "aria-label": _vm.i18n.compare,
                                              disabled: _vm.isCompareDisabled
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleCompare($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.i18n.compare) +
                                                " " +
                                                _vm._s(_vm.compareCount) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-tertiary mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                            attrs: {
                                              "aria-label": _vm.i18n.addToList
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleSelectList($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.i18n.addToList) +
                                                "\n              "
                                            )
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn text-trans button-color mx-sm-3 my-sm-3 mx-xs-2 my-xs-2",
                                            attrs: {
                                              "aria-label": _vm.i18n.addToCart
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.handleAddToCart($event)
                                              }
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(_vm.i18n.addToCart) +
                                                "\n              "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", {
                              ref: "lazyLoad",
                              attrs: { id: "lazyLoad" }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
            _vm._v(" "),
            _vm.guestListName
              ? [
                  _c("vx-save-cart", {
                    attrs: {
                      i18n: _vm.i18n.selectAList,
                      "is-bulk-enabled": _vm.isBulkEnabled,
                      "is-product": "true",
                      "guest-list-name": _vm.guestListName,
                      "selected-bulk-products": _vm.selectedBulkProducts
                    },
                    on: { "list-saved": _vm.onSelectListSuccess }
                  })
                ]
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-flex" }, [
      _c("hr", { staticClass: "d-flex" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-flex" }, [
      _c("hr", { staticClass: "d-flex" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row d-flex" }, [
      _c("hr", { staticClass: "d-flex" })
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-72e23c22", { render: render, staticRenderFns: staticRenderFns })
  }
}