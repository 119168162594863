/**
 * This component is the common add to cart button component
 */

import globals from '../globals';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import flyoutBannerMixin from '../vx-flyout-banner/vx-flyout-banner-mixin';
import SearchBrowseService from '../services/search-browse-service';
import AnalyticsService from '../services/analytics-service';
import ManageShoppingCartService from '../services/manage-shopping-cart-service';
import cookiesMixin from '../mixins/cookies-mixin';
import { cartEventBus } from '../../../modules/event-bus';

export default {
  name: 'vx-marketing-add-to-cart',
  components: {
    vxSpinner,
  },
  mixins: [flyoutBannerMixin, cookiesMixin],
  props: {
    // Copy text coming from properties file
    i18n: {
      type: Object,
    },
    behavior: {
      type: String,
      default: 'button',
    },
    sku: {
      type: String,
      default: '',
    },
    quantity: {
      type: Number,
      default: 1,
    },
    buttontext: {
      type: String,
      default: 'Add To Cart',
    },
    target: {
      type: String,
      default: 'cart',
    },
  },
  data() {
    return {
      globals,
      searchBrowseService: new SearchBrowseService(),
      analyticsService: new AnalyticsService(),
      queryString: [],
    };
  },
  computed: {},
  mounted() {
    this.parseQueryString();
    if (this.queryString.behavior) { this.behavior = this.queryString.behavior; }
    if (this.queryString.sku) { this.sku = this.queryString.sku; }
    if (this.queryString.quantity) { this.quantity = this.queryString.quantity; }
    if (this.queryString.target) { this.target = this.queryString.target; }
    if (this.queryString.buttontext) { this.buttontext = this.queryString.buttontext; }

    console.log('behavior', this.behavior);
    console.log('sku', this.sku);
    console.log('quantity', this.quantity);
    console.log('buttontext', this.buttontext);
    console.log('target', this.target);
    if (this.behavior === ('onload') || this.behavior === ('redirect')) {
      this.addToCart();
    }
  },
  methods: {
    parseQueryString() {
      if (window.location.search.length > 0) {
        const q = window.location.search.substring(1).split('&');
        if (q) {
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < q.length; i += 1) {
            const P = q[i].split('=');
            const key = decodeURIComponent(P[0]);
            const value = decodeURIComponent(P[1]);
            this.queryString[key.toLowerCase()] = value;
          }
        }
      }
    },
    /**
     * Function is triggered on click of the add to cart button
     */

    // TODO:
    // How to add multiple items
    // Handle the response code correctly.
    // fails https://qa1.dixie.com/deeplinktest?buttontext=Add%20me%202%20to%20cart&sku=16124%2F01
    // works https://qa1.dixie.com/deeplinktest?buttontext=Add%20me%202%20to%20cart&sku=15951%2F40
    addToCart() {
      this.$refs.spinner.showSpinner();
      const requestObjParams = {
        quantity: `${this.quantity}`,
        product: {
          code: `${this.sku}`,
        },
      };
      const requestConfig = {};
      requestConfig.data = requestObjParams;
      this.searchBrowseService.addToCart(
        requestConfig,
        this.handleAddProductResponse,
        this.handleAddProductErrorResponse,
      );
    },

    /**
     * Function handles the response of add to cart service call
     */
    handleAddProductResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        if (!Number.isNaN(response.data.quantityAdded) && response.data.quantityAdded < 1) {
          this.showFlyout(
            'error',
            `${response.data.statusMessage}`,
            true,
            Number(this.globals.siteConfig.bannerTimer),
          );
        } else {
          this.showFlyout(
            'success',
            `${response.data.quantityAdded ? response.data.quantityAdded : this.quantity} Product${this.quantity > 1 ? 's' : ''} Added Successfully to Cart`,
            true,
            Number(this.globals.siteConfig.bannerTimer),
          );
          cartEventBus.$emit('cart-update');
          cartEventBus.$emit('call-basic-cart');

          // Do not redirect if in SmartEdit
          if (this.behavior === 'redirect'
              && window.parent && window.parent.location.pathname.indexOf('smartedit') === -1) {
            this.redirectToCheckout();
          }
        }
      }
    },
    /**
     * Function handles the error of add to cart service call
     */
    handleAddProductErrorResponse(error) {
      this.$refs.spinner.hideSpinner();
      if (
        error
        && error.response
        && error.response.data
        && error.response.data.errors.length !== 0
        && error.response.data.errors[0]
        && error.response.data.errors[0].code
      ) {
        if (
          error.response.data.errors[0].code
          === this.i18n.maxPurchaseableQuantityErrorCode
        ) {
          this.showFlyout(
            'error',
            'Some Error while adding to cart',
            true,
          );
        } else if (
          error.response.data.errors[0].code === this.i18n.lowStockErrorCode
        ) {
          this.showFlyout('error', this.i18n.lowStockErrorMessage, true);
        }
      }
    },

    redirectToCheckout() {
      // sending the data to Google Analytics on Checkout
      const manageShoppingCartService = new ManageShoppingCartService();

      // Do not run if in SmartEdit
      if (window.parent && window.parent.location.pathname.indexOf('smartedit') > -1) { return; }
      if (!this.globals.getIsLoggedIn()) {
        if (this.target === 'checkout') {
          this.globals.setCookie('flow', 'checkout');
          this.globals.navigateToUrl('login');
        } else {
          this.globals.navigateToUrl('cart');
        }
      } else {
        this.$refs.spinner.showSpinner();
        manageShoppingCartService.validateCart(
          {},
          this.handleValidateCartResponse,
          this.handleValidateCartError,
        );
      }
    },
    /**
     * This function handles the response of flow from cart to checkout
     */
    handleValidateCartResponse(response) {
      const manageShoppingCartService = new ManageShoppingCartService();
      this.$refs.spinner.hideSpinner();
      const { data } = response;
      this.lowStockData = [];
      for (let i = 0; i < data.cartModificationDataList.length; i += 1) {
        if (
          data.cartModificationDataList[i].statusCode
            === this.ProductAvailability.LOW_STOCK
          || data.cartModificationDataList[i].statusCode
            === this.ProductAvailability.OFFLINE
        ) {
          this.lowStockData.push({
            entry: data.cartModificationDataList[i].entry,
            status: data.cartModificationDataList[i].statusMessage,
          });
        }
        if (
          data.cartModificationDataList[i].statusCode
          === this.ProductAvailability.NO_STOCK
        ) {
          this.lowStockData.push({
            entry: data.cartModificationDataList[i].entry,
            status: data.cartModificationDataList[i].statusMessage,
            noStock: true,
          });
        }
      }
      const { status } = response;
      if (status && this.lowStockData.length === 0) {
        if (data) {
          manageShoppingCartService.validateIncompatibleProducts(
            { params: { fields: 'CHECK_INCOMPATIBLE' } },
            this.validateIncompatibleProductsResponse,
            this.validateIncompatibleProductsError,
          );
        }
      } else if (this.lowStockData.length !== 0) {
        this.lowStockStatus = '';
        this.lowStockStatus = this.lowStockData[0].status;
        cartEventBus.$emit('cart-update');
      }
      this.$root.$on('cart-reloaded', (value) => {
        if (value && this.lowStockStatus) {
          this.showFlyout('error', this.lowStockStatus, true);
          this.lowStockStatus = '';
        }
      });
    },
    /**
     * This function handles the error of flow from cart to checkout
     */
    handleValidateCartError(error) {
      this.$refs.spinner.hideSpinner();
      if (
        error
        && error.response
        && error.response.data
        && error.response.data.errors.length !== 0
        && error.response.data.errors[0]
        && error.response.data.errors[0].code
      ) {
        if (
          error.response.data.errors[0].code
          === this.i18n.maxPurchaseableQuantityErrorCode
        ) {
          this.showFlyout(
            'error',
            this.i18n.maxPurchaseableQuantityErrorMessage,
            true,
          );
        } else if (
          error.response.data.errors[0].code === this.i18n.lowStockErrorCode
        ) {
          this.showFlyout(
            'error',
            this.i18n.lowStockErrorMessage,
            true,
          );
        }
      }
    },
    /**
     * This function opens incompatibility warning modal
     */
    validateIncompatibleProductsResponse(response) {
      if (!this.clickCount && response.data.incompatibleData.incompatible) {
        // Open the modal
        this.$refs.validateCheckoutModal.open();
        if (document.querySelector('.order-summary-section')) {
          document.querySelector('.order-summary-section').style.zIndex = 9;
          document
            .querySelector('.order-summary-section')
            .scrollIntoView(false);
        }
        this.clickCount += 1;
      } else {
        this.globals.navigateToUrl(this.target);
      }
    },
    validateIncompatibleProductsError() {},
    /**
     * This function handles the close of incompatibility warning modal
     */
    validateCheckoutModalClose() {
      if (document.querySelector('.order-summary-section')) {
        document.querySelector('.order-summary-section').style.zIndex = '';
      }
    },
  },
};
