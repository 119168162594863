import { Validator } from 'vee-validate';
import vxModal from '../../../../components/common/vx-modal/vx-modal.vue';
import vxForgotPassword from '../../../../components/registration-login/vx-forgot-password/vx-forgot-password.vue';
import globals from '../../../../components/common/globals';
import loginMixin from '../../../../components/common/mixins/login-mixin';
import flyoutBannerMixin from '../../../../components/common/vx-flyout-banner/vx-flyout-banner-mixin';
import headerMixin from '../../../../components/common/mixins/header-mixin';
import detectDeviceMixin from '../../../../components/common/mixins/detect-device-mixin';
import { globalEventBus } from '../../../../modules/event-bus';
import cookiesMixin from '../../../../components/common/mixins/cookies-mixin';
import vxSpinner from '../../../../components/common/vx-spinner/vx-spinner.vue';

/**
 * Login component  (eStores)
 */
export default {
  name: 'vx-login',
  mixins: [
    loginMixin,
    flyoutBannerMixin,
    headerMixin,
    detectDeviceMixin,
    cookiesMixin,
  ],
  props: {
    actionUrl: String,
    i18n: Object,
  },
  components: {
    vxModal,
    vxForgotPassword,
    vxSpinner,
  },
  data() {
    return {
      user: {
        email: '',
        password: '',
      },
      disableField: false,
      showPassword: false,
      serverError: '',
    };
  },
  methods: {
    /**
     * This function is called on click of submit button.
     * @param {Object} e contains the event of the element.
     * On successful validation submitLogin is called else focus is set back to first field in the form.
     */
    submitClicked(e) {
      e.preventDefault();
      this.$refs.spinner.showSpinner();
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.readCookie('guid_user') === 'current') {
            this.showFlyout('error', this.i18n.activeLoginSession, true);
            this.$refs.spinner.hideSpinner();
          } else {
            this.submitLogin();
          }
        } else {
          globals.setFocusByName(this.$el, globals.getElementName(this.errors));
          this.$refs.spinner.hideSpinner();
        }
      });
    },
    /**
     * This function toggles the password on hiding and showing the text.
     */
    togglePassword() {
      this.showPassword = !this.showPassword;
    },
    /**
     * This function is called on clicking forgot password link
     * @param {Object} callingParent contains the event of the link
     * Opens forgotPasswordModal by passing the event as a param.
     */
    handleForgotPassword(callingParent) {
      this.$refs.forgotPasswordModal.open(callingParent);
    },
    createAccount() {
      this.createAccountButHandler(this.user.profile);
    },
    handleErrorCallback(errorData) {
      if (errorData.response.data.error_description === 'Reset credentials') {
        this.disableField = true;
        this.showFlyout('error', this.i18n.invalidAttemptsError, true);
      } else {
        this.serverError = errorData.response.data.error_description;
      }
    },
    hideServerError() {
      this.serverError = '';
    },
    isASM() {
      return document.getElementById('_asm');
    },
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('profileName')) {
      const profileName = urlParams.get('profileName');
      this.user.profile = profileName;
    }
  },
  mounted() {
    this.handleUserUpdateSuccesMsg();
    globalEventBus.$emit('announce', 'Login Page');
    this.handleLoginErrorCode();
    const veeCustomErrorMessage = {
      en: {
        custom: {
          j_username: {
            required: this.i18n.emailRequiredError,
            email: this.i18n.emailInvalidError,
          },
          j_password: {
            required: this.i18n.passwordRequiredError,
            regex: this.i18n.passwordInvalidError,
          },
        },
      },
    };

    Validator.localize(veeCustomErrorMessage);
  },
};
