var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-login registration-and-login-sign-in" }, [
    _vm.isASM()
      ? _c("section", [
          _c("h1", { staticStyle: { "font-size": "32px" } }, [
            _vm._v("Log in to continue.")
          ])
        ])
      : _c(
          "section",
          [
            _c("vx-spinner", {
              ref: "spinner",
              attrs: {
                image: _vm.globals.assetsPath + "images/spinner.gif",
                "full-screen": ""
              }
            }),
            _vm._v(" "),
            _c(
              "vx-modal",
              {
                ref: "forgotPasswordModal",
                attrs: { heading: _vm.i18n.passwordModalHeading }
              },
              [
                _c("vx-forgot-password", {
                  attrs: { slot: "component", i18n: _vm.i18n },
                  slot: "component"
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "h1",
              { staticClass: "b2c-heading mx-xs-0 my-xs-0 text-center" },
              [_vm._v("\n  " + _vm._s(_vm.i18n.headingforEStore) + "\n")]
            ),
            _vm._v(" "),
            _c(
              "form",
              {
                ref: "loginForm",
                attrs: { action: _vm.actionUrl, method: "post" }
              },
              [
                _c("fieldset", [
                  _c("legend", { staticClass: "legend_ai" }, [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v(_vm._s(_vm.i18n.headingforEStore))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "my-xs-4 form-group" }, [
                    _c(
                      "label",
                      { attrs: { "aria-hidden": _vm.isDeviceAndroid() } },
                      [_vm._v(_vm._s(_vm.i18n.email))]
                    ),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.email,
                          expression: "user.email"
                        },
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required|email|max:80",
                          expression: "'required|email|max:80'"
                        }
                      ],
                      staticClass: "form-control",
                      class: [{ "input-error": _vm.errors.has("j_username") }],
                      attrs: {
                        type: "text",
                        maxlength: "80",
                        name: "j_username",
                        disabled: _vm.disableField,
                        "aria-label": _vm.i18n.email,
                        "aria-describedby": "username_error_msg"
                      },
                      domProps: { value: _vm.user.email },
                      on: {
                        focus: _vm.hideServerError,
                        input: [
                          function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.user, "email", $event.target.value)
                          },
                          function($event) {
                            _vm.user.email = $event.target.value
                          }
                        ]
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.errors.has("j_username"),
                            expression: "errors.has('j_username')"
                          }
                        ],
                        staticClass: "mt-xs-2",
                        attrs: { "aria-hidden": !_vm.errors.has("j_username") }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass: "error-msg",
                            attrs: {
                              "aria-live": "polite",
                              role: "alert",
                              id: "username_error_msg"
                            }
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.errors.first("j_username")) +
                                "\n      "
                            )
                          ]
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mb-xs-3 mb-sm-3 mb-md-4 form-group has-feedback"
                    },
                    [
                      _c(
                        "label",
                        { attrs: { "aria-hidden": _vm.isDeviceAndroid() } },
                        [_vm._v(_vm._s(_vm.i18n.password))]
                      ),
                      _vm._v(" "),
                      (_vm.showPassword ? "text" : "password") === "checkbox"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.password,
                                expression: "user.password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  regex: _vm.globals.PASSWORD_REGEX
                                },
                                expression:
                                  "{ required: true, regex: globals.PASSWORD_REGEX }"
                              }
                            ],
                            staticClass: "password form-control",
                            class: [
                              { "input-error": _vm.errors.has("j_password") }
                            ],
                            attrs: {
                              name: "j_password",
                              maxlength: "255",
                              disabled: _vm.disableField,
                              "aria-label": _vm.i18n.password,
                              "aria-errormessage": _vm.errors.first(
                                "j_password"
                              ),
                              "aria-describedby": "password_error_msg",
                              type: "checkbox"
                            },
                            domProps: {
                              checked: Array.isArray(_vm.user.password)
                                ? _vm._i(_vm.user.password, null) > -1
                                : _vm.user.password
                            },
                            on: {
                              focus: _vm.hideServerError,
                              change: function($event) {
                                var $$a = _vm.user.password,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.user,
                                        "password",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.user,
                                        "password",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.user, "password", $$c)
                                }
                              }
                            }
                          })
                        : (_vm.showPassword ? "text" : "password") === "radio"
                        ? _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.password,
                                expression: "user.password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  regex: _vm.globals.PASSWORD_REGEX
                                },
                                expression:
                                  "{ required: true, regex: globals.PASSWORD_REGEX }"
                              }
                            ],
                            staticClass: "password form-control",
                            class: [
                              { "input-error": _vm.errors.has("j_password") }
                            ],
                            attrs: {
                              name: "j_password",
                              maxlength: "255",
                              disabled: _vm.disableField,
                              "aria-label": _vm.i18n.password,
                              "aria-errormessage": _vm.errors.first(
                                "j_password"
                              ),
                              "aria-describedby": "password_error_msg",
                              type: "radio"
                            },
                            domProps: {
                              checked: _vm._q(_vm.user.password, null)
                            },
                            on: {
                              focus: _vm.hideServerError,
                              change: function($event) {
                                _vm.$set(_vm.user, "password", null)
                              }
                            }
                          })
                        : _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.user.password,
                                expression: "user.password"
                              },
                              {
                                name: "validate",
                                rawName: "v-validate",
                                value: {
                                  required: true,
                                  regex: _vm.globals.PASSWORD_REGEX
                                },
                                expression:
                                  "{ required: true, regex: globals.PASSWORD_REGEX }"
                              }
                            ],
                            staticClass: "password form-control",
                            class: [
                              { "input-error": _vm.errors.has("j_password") }
                            ],
                            attrs: {
                              name: "j_password",
                              maxlength: "255",
                              disabled: _vm.disableField,
                              "aria-label": _vm.i18n.password,
                              "aria-errormessage": _vm.errors.first(
                                "j_password"
                              ),
                              "aria-describedby": "password_error_msg",
                              type: _vm.showPassword ? "text" : "password"
                            },
                            domProps: { value: _vm.user.password },
                            on: {
                              focus: _vm.hideServerError,
                              input: function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.user,
                                  "password",
                                  $event.target.value
                                )
                              }
                            }
                          }),
                      _vm._v(" "),
                      _c("span", {
                        staticClass: "icon-eye toggle-password",
                        class: { "icn-eye-collapsed": _vm.showPassword },
                        attrs: {
                          tabindex: "0",
                          role: "button",
                          "aria-label": _vm.showPassword
                            ? _vm.i18n.iconEyeHideTitle
                            : _vm.i18n.iconEyeShowTitle
                        },
                        on: { click: _vm.togglePassword }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.errors.has("j_password"),
                              expression: "errors.has('j_password')"
                            }
                          ],
                          staticClass: "mt-xs-2",
                          attrs: {
                            "aria-hidden": !_vm.errors.has("j_password")
                          }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "password_error_msg"
                              }
                            },
                            [_vm._v(_vm._s(_vm.errors.first("j_password")))]
                          )
                        ]
                      )
                    ]
                  )
                ]),
                _vm._v(" "),
                _vm.globals.siteConfig.showForgotPassword
                  ? _c(
                      "p",
                      {
                        staticClass: " pt-sm-2 pt-xs-3 mx-xs-0 mt-xs-0 mb-xs-4"
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              tabindex: "0",
                              role: "link",
                              "aria-label": _vm.i18n.forgotPassword
                            },
                            on: {
                              click: function($event) {
                                _vm.handleForgotPassword($event)
                              },
                              keyup: function($event) {
                                if (
                                  !("button" in $event) &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter-space",
                                    undefined,
                                    $event.key,
                                    undefined
                                  )
                                ) {
                                  return null
                                }
                                _vm.handleForgotPassword($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.i18n.forgotPassword))]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.serverError
                  ? _c("div", { staticClass: "mt-xs-4 mb-xs-3" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.serverError))
                      ])
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "baseSiteId" },
                  domProps: { value: _vm.globals.siteId }
                }),
                _vm._v(" "),
                _c("input", {
                  attrs: { type: "hidden", name: "CSRFToken" },
                  domProps: { value: _vm.globals.csrfToken }
                }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn-block btn btn-primary",
                    attrs: {
                      type: "submit",
                      disabled: _vm.errors.any() || _vm.disableField
                    },
                    on: {
                      click: _vm.submitClicked,
                      keyup: function($event) {
                        if (
                          !("button" in $event) &&
                          _vm._k(
                            $event.keyCode,
                            "enter-space",
                            undefined,
                            $event.key,
                            undefined
                          )
                        ) {
                          return null
                        }
                        return _vm.submitClicked($event)
                      }
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.submit))]
                ),
                _vm._v(" "),
                _c("p", { staticClass: "pt-xs-2 mx-xs-0 my-xs-0" }, [
                  _c("a", [
                    _c(
                      "span",
                      {
                        attrs: { tabindex: "0", role: "button" },
                        on: {
                          click: _vm.createAccount,
                          keyup: function($event) {
                            if (
                              !("button" in $event) &&
                              _vm._k(
                                $event.keyCode,
                                "enter-space",
                                undefined,
                                $event.key,
                                undefined
                              )
                            ) {
                              return null
                            }
                            return _vm.createAccount($event)
                          }
                        }
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "btn-block btn no-account-pad button-color no-account-text"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.i18n.noGPeStoresAccount) +
                                " " +
                                _vm._s(_vm.i18n.registerHere) +
                                "\n          "
                            )
                          ]
                        )
                      ]
                    )
                  ])
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "pt-xs-2 mx-xs-0 my-xs-0 no-account-text" },
                  [
                    _vm._v(
                      "\n      " + _vm._s(_vm.i18n.contactUsHelpText) + "\n    "
                    )
                  ]
                )
              ]
            )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2a2fcd8d", { render: render, staticRenderFns: staticRenderFns })
  }
}