import globals from '../globals';
import CommonService from '../services/common-service';
import vxAutoSuggest from '../../search-browse/vx-auto-suggest/vx-auto-suggest.vue';
import vxDropdownPrimary from '../vx-dropdown-primary/vx-dropdown-primary.vue';
import headerMixin from '../mixins/header-mixin';
import vxMiniCart from '../../manage-shopping-cart/vx-mini-cart/vx-mini-cart.vue';
import vxLeftNav from '../../manage-profile-shopping-lists/vx-left-nav/vx-left-nav.vue';
import ManageProfileShoppingListService from '../services/manage-profile-shopping-lists-service';
import mobileMixin from '../mixins/mobile-mixin';
import vxSpinner from '../vx-spinner/vx-spinner.vue';
import cookiesMixin from '../mixins/cookies-mixin';
import { cookies } from '../mixins/vx-enums';
import vxModal from '../vx-modal/vx-modal.vue';

import { globalEventBus, cartEventBus } from '../../../modules/event-bus';

/**
 * Header section
 */
export default {
  name: 'vx-header-section',
  mixins: [headerMixin, mobileMixin, cookiesMixin],
  props: {
    // Header data including categories
    headerData: {
      type: Object,
      required: true,
    },
    // Text and label from properties file
    i18n: {
      type: Object,
      required: true,
    },
    // Flag to enable or disable the favourites option
    isFavoritesEnabled: {
      type: Boolean,
      default: false,
    },
    // Flag to know if the page is home page
    isHomePage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      globals,
      showMobileNav: false,
      selectedPrimary: '',
      cartItems: 0,
      cartId: '',
      screenWidth: window.screenwidth,
      desktopWidthMin: 1200,
      showMiniCart: false,
      userName: '',
      isMiniCartHovered: false,
      miniCartTimeout: 250,
      miniCartTimer: {},
      tabletView: false,
      toggleSearchIcon: false,
      cookies,
      windowWidth: window.innerWidth,
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      commonService: new CommonService(),
      isPromoEnabled: false,
      b2bUnitProfilesList: [],
      b2bUnitSelected: {},
      prevSelectedB2bUnit: {},
      presentSelectedB2bUnit: {},
      unitHeaderLogo: '',
      loginMenuOptions: [],
    };
  },
  computed: {
    // Configurations for header (includes flags)
    headerOptions() {
      return this.headerData.headerOptions;
    },
    // Login menu data in header data
    loginMenu() {
      /*
        if user is logged in and in mobile, we need to display menu options such as profile & preferences, address, payment methods etc
      */
      if (this.showMobileNav && this.globals.getIsLoggedIn() && this.globals.isEStores) {
        const initialData = [...this.headerData.loginMenu.menuOptions];
        //  the data available is not in the expected format, so we change it here
        const constructedData = this.headerData.accountMenu[0].links.map((item) => ({
          option: item.linkText,
          optionLink: item.linkTo,
          external: false,
          markAsViewAll: false,
          socialLink: false,
        }));
        // the name at 0th index should be first
        const resultantData = [initialData[0], ...constructedData];
        initialData.shift();
        // followed by menu options and the remaining of the initialData array
        this.loginMenuOptions = [...resultantData, ...initialData];
      } else {
        this.loginMenuOptions = this.headerData.loginMenu.menuOptions;
      }
      return this.loginMenuOptions;
    },
    // Categories data in header data
    navMenu() {
      return this.headerData.navMenu;
    },
    // Find store/distributor menu data
    findMenu() {
      return this.headerData.findMenu;
    },
    // Checkout header data
    checkoutMenu() {
      return this.headerData.headerOptions.checkoutMenu;
    },
  },
  components: {
    vxAutoSuggest,
    vxMiniCart,
    vxLeftNav,
    vxSpinner,
    vxModal,
    vxDropdownPrimary,
  },
  methods: {
    /* eslint no-undef: "error" */

    /**
     * Generate dropdown list for profiles
     */
    b2bUnitsDropDownValues() {
      if (this.headerData.headerOptions.userProfiles) {
        this.headerData.headerOptions.userProfiles.forEach((item, index) => {
          this.$set(this.b2bUnitProfilesList, index, {
            label: item.name,
            value: item.id,
          });
          if (this.globals.userInfo.unit === item.id) {
            this.b2bUnitSelected = {
              label: item.name,
              value: item.id,
            };
            if (this.globals.isEStores && this.$refs && this.$refs.b2bUnitsDropdown) {
              this.$refs.b2bUnitsDropdown.setDropdownLabel(item.name);
              this.unitHeaderLogo = item.distributorImage;
            }
          }
        });
      }
      return this.b2bUnitProfilesList;
    },
    /**
     * Hanldes dropdown selection and set value on scope, make API call to switch profiles
     * @param {Object} event - selecgted profile object
     */
    handleSelectedUnit(event) {
      if (event.value === this.b2bUnitSelected.value) {
        return;
      }
      this.prevSelectedB2bUnit = { ...this.b2bUnitSelected };
      this.b2bUnitSelected = event;
      this.$refs.b2bUnitsDropdown.setDropdownLabel(this.b2bUnitSelected.label);
      this.switchB2bUnitProfiles(event.value);
    },
    /**
     * Set default dropdow value
     */
    handleDefaultUnit() {
      this.$refs.b2bUnitsDropdown.setDropdownLabel(this.b2bUnitSelected.label);
    },
    /**
     * Make call to Switch profile rest service
     * @param {String} selectedUnit
     * @param {Boolean} clearCart
     */
    switchB2bUnitProfiles(selectedUnit, clearCart) {
      const requestConfig = {};
      const requestParams = {
        cartId: this.cartId,
        clearCart,
        unitUid: selectedUnit,
      };
      this.commonService.switchB2bUnitProfiles(
        requestConfig,
        this.handleSwitchProfilesResponse,
        this.handleSwitchProfilesError,
        requestParams,
      );
    },
    /**
     * Handles profile switching response
     * @param {Object} response - Success response
     */
    handleSwitchProfilesResponse() {
      globals.navigateToUrl('home');
    },
    /**
     * Handles profile switching error reponse
     * @param {Object} error
     */
    handleSwitchProfilesError(error) {
      this.presentSelectedB2bUnit = { ...this.b2bUnitSelected };
      this.b2bUnitSelected = this.prevSelectedB2bUnit;
      this.$refs.b2bUnitsDropdown.setDropdownLabel(
        this.prevSelectedB2bUnit.label,
      );
      if (
        error.response.data.errors
        && error.response.data.errors[0].code === '9110'
      ) {
        this.$refs.profileSwitchModal.open();
      }
    },
    /**
     * Hanldes clearing cart of current profile
     */
    clearCart() {
      this.switchB2bUnitProfiles(this.presentSelectedB2bUnit.value, true);
    },
    /**
     * Dynamically sets the height for hamburger menu
     */
    setHeaderHeight() {
      if (this.showMobileNav) {
        setTimeout(() => {
          this.$el.querySelector(
            '.header-left-section .mobile-view',
          ).style.height = `${window.innerHeight - 52}px`;
        }, 400);
      }
    },
    /**
     * Closes the hamburger menu
     */
    dismissMobileMenu() {
      if (this.screenWidth <= this.desktopWidthMin) {
        this.showMobileNav = !this.showMobileNav;
      }
    },
    /**
     * Updates the accessibility related attributes
     * @param {boolean} isExpand Indicates if the hamburger is displayed or hidden
     */
    updateAria(isExpand) {
      if (isExpand) {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },

    /**
     * Displays the secondary category menu and updates the accessibility related attributes for hamburger menu
     * @param {string} selectedItem Primary category item selected by the user
     */
    selectPrimary(selectedItem) {
      this.selectedPrimary = selectedItem;
      const self = this;
      if (this.screenWidth <= this.desktopWidthMin) {
        setTimeout(() => {
          self.$refs.navigationMenu.scrollTop = 0;
          document.querySelector('.js-secondary-action-el').focus();
        }, 150);
      }
      if (this.selectPrimary !== '') {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', true);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', true);
        }
      } else {
        if (document.querySelector('.navbar-bottom-right')) {
          document
            .querySelector('.navbar-bottom-right')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.main__inner-wrapper')) {
          document
            .querySelector('.main__inner-wrapper')
            .setAttribute('aria-hidden', false);
        }
        if (document.querySelector('.footer-section')) {
          document
            .querySelector('.footer-section')
            .setAttribute('aria-hidden', false);
        }
      }
    },
    /**
     * Checks if secondary categories are available for a primary category and returns boolean
     * @param {string} primaryItem Primary category item selected by the user
     */
    isSecondaryAvailable(primaryItem) {
      return primaryItem.secondaryList && primaryItem.secondaryList.length > 0;
    },
    /**
     * Sets focus to primary category item in hamburger when returning from secondary menu
     * @param {string} el Primary category item
     * @param {object} menu Categories menu
     */
    focusParent(el, menu) {
      this.selectedPrimary = '';
      for (const i in menu) {
        if (menu[i].primary === el) {
          setTimeout(() => {
            document
              .querySelectorAll('.primary-menu-item')[i].childNodes[0].focus();
          }, 100);
        }
      }
    },
    /**
     * The below method is used to get the parent
     * element with specific class
     * @param {*the current element} el
     * @param {*the target class } cls
     */
    findAncestor(el, cls) {
      while ((el = el.parentElement) && !el.classList.contains(cls));
      return el;
    },

    returnItemsImages(items) {
      const images = [];
      for (let i = 0; i < items.length; i += 1) {
        if (items[i].imageData) {
          const { imageData } = items[i];
          imageData.link = items[i].ctaLink;
          imageData.isExternalLink = items[i].external;
          images.push(imageData);
        }
      }
      return images;
    },
    /**
     * Handles the window resize
     */
    handleResize() {
      if (window.innerWidth !== this.windowWidth) {
        this.tabletView = this.isTablet();
        this.windowWidth = window.innerWidth;
      }
    },
    /**
     * Handles the navigation to lists landing page
     */
    navigateTomyLists() {
      if (this.globals.loggedIn || !this.globals.siteConfig.isGuestList) {
        this.globals.navigateToUrl('myList');
      } else {
        const wuid = this.readCookie(cookies.guestListUid)
          ? this.readCookie(cookies.guestListUid)
          : '';
        window.location = `${this.globals.getNavigationUrl(
          'listDetails',
        )}/?listName=${wuid}`;
      }
    },
    /**
     * Sets the height of secondary menu container (only for desktop)
     * @param {object} event Mouseover event
     * @param {number} count Number of secondary menu items for the hovered primary menu item
     */
    setHeaderFlyoutWidth(event, count) {
      const targetedElement = event.target;
      const oneColumnWidth = 323;
      const styleProp = targetedElement
        .querySelector('.secondary-menu')
        .getAttribute('style');
      const liList = targetedElement.querySelectorAll('.flyout-sec li');
      let liWidth = oneColumnWidth;
      let maxLiWidth = oneColumnWidth;

      if (styleProp) {
        return;
      }
      liList.each((listElement) => {
        const elementWidth = listElement.offsetWidth;

        if (liWidth < elementWidth && maxLiWidth < elementWidth) {
          liWidth = elementWidth;
          maxLiWidth = elementWidth;
        }
      });

      if (count > 10 && count < 16) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 3}px`;
      } else if (count > 15) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 4}px`;
      } else if (count < 6) {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth}px`;
        targetedElement.querySelector('.js-secondary-child')
          .style.maxWidth = '100%';
      } else {
        targetedElement.querySelector('.secondary-menu')
          .style.width = `${liWidth * 2}px`;
      }
    },
  },
  async mounted() {
    this.clearKauthData();
    this.tabletView = this.isTablet();
    this.b2bUnitProfilesList = this.b2bUnitsDropDownValues();
    this.isPromoEnabled = !(
      this.globals.siteConfig.isFindAStoreEnabled
      || this.globals.siteConfig.isFindADistributorEnabled
      || this.globals.siteConfig.isReferAFriend
    );
    this.loginAreaLabel = globals.getIsLoggedIn()
      ? this.i18n.loginSectionTitle
      : this.i18n.loginHeading;
    this.userName = this.headerData.loginMenu.menuOptions.length
      ? this.headerData.loginMenu.menuOptions[0].option
      : '';
    cartEventBus.$on('total-items-updated', (items, cartId) => {
      this.cartItems = items;
      this.cartId = cartId;
    });
    globalEventBus.$emit(
      'is-checkout',
      this.headerData.headerOptions.isCheckout,
    );
    globalEventBus.$on('updated-name', (val) => {
      this.userName = val;
    });

    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
  filters: {
    maxCharacters(value) {
      const charLimit = 120;
      return value.substring(0, charLimit);
    },
  },
};
