/* eslint-disable no-undef */
/* eslint-disable camelcase */
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'promise-polyfill/src/polyfill';
import Vue from 'vue';
import VeeValidate from 'vee-validate';
import VueTheMask from 'vue-the-mask';
import VueLazyload from 'vue-lazyload';
import router from '../router';
import globals from '../components/common/globals';

import store from '../components/common/store/store';
import messages from '../locale/estores/messages';

// EStores-specific module
import registerLoginModule from './modules/registration-login';

import commonModule from '../modules/common';
import manageShoppingCartModule from '../modules/manage-shopping-cart';
import searchBrowseModule from '../modules/search-browse';
import viewSiteContentModule from '../modules/view-site-content';
import pdpModule from '../modules/pdp';
import accessCustomerServiceModule from '../modules/access-customer-service';
import manageProfileShoppingListsModule from '../modules/manage-profile-shopping-lists';
import checkoutModule from '../modules/checkout';
import manageB2bOrgModule from '../modules/manage-b2b-org';
import quickOrderModule from '../modules/quick-order';
import nfrModule from '../modules/nfr';
import manageTransactionHistoryModule from '../modules/manage-transaction-history';
import manageSubscriptionModule from '../modules/manage-subscription';

import '../components/common/directives/directives';
import { globalEventBus } from '../modules/event-bus';
import siteConfig from '../config/estores-config';
import variables from './variables';

const scriptURL = document.currentScript.src;
const parts = scriptURL.split('/');
__webpack_public_path__ = `${parts.slice(0, parts.length - 3).join('/')}/`;

const config = {
  delay: 100,
  aria: false,
};

Vue.use(VeeValidate, config);
Vue.use(VueTheMask);
// Vue.config.devtools = true;
Vue.config.productionTip = false;
Vue.config.keyCodes = {
  // Enter or space Capturing
  'enter-space': [13, 32],
};
Vue.use(VueLazyload, {
  lazyComponent: true,
});
const vm = new Vue({
  el: '#estores',
  data: {
    messages: messages['en-US'],
    globals,
    siteConfig,
    variables,
    cartExists: false,
  },
  mixins: [
    commonModule,
    registerLoginModule,
    manageShoppingCartModule,
    searchBrowseModule,
    viewSiteContentModule,
    pdpModule,
    accessCustomerServiceModule,
    manageProfileShoppingListsModule,
    checkoutModule,
    manageB2bOrgModule,
    quickOrderModule,
    nfrModule,
    manageTransactionHistoryModule,
    manageSubscriptionModule,
  ],
  router,
  store,
  components: {},
  mounted() {
    /**
     * setting site specific variables
     */
    this.globals.siteVariables = this.variables;
    /**
     * scrolls to the section on click of the product
     */
    globalEventBus.$on('scroll-to', (val) => {
      const targetElement = document.querySelector(val);
      if (targetElement) targetElement.scrollIntoView({ behavior: 'smooth' });
    });
  },
  methods: {
    addProductToCart() {},
    addProductToFavorites() {},
    notifyProduct() {},
  },
  watch: {
    cartExists() {
      window.cartExists = this.cartExists;
    },
  },
  updated() {
    this.setInnerWrapperPadding();
  },
});

window.vm = vm;

Vue.config.errorHandler = (err, vm1, info) => {
  vm.sendToTrackJS(err, vm1, info);
};
