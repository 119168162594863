var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vx-breadcrumb" }, [
    _c(
      "ul",
      { staticClass: "breadcrumb" },
      _vm._l(_vm.breadcrumbs, function(item, index) {
        return _c(
          "li",
          { key: index },
          [
            item.url === "#"
              ? _c("span", {
                  attrs: { tabindex: "0", "aria-current": "page" },
                  domProps: { innerHTML: _vm._s(item.name) }
                })
              : [
                  item.name
                    ? _c("a", {
                        attrs: { href: _vm.globals.contextPath + item.url },
                        domProps: { innerHTML: _vm._s(item.name) }
                      })
                    : _vm._e()
                ]
          ],
          2
        )
      })
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-bb1bca0e", { render: render, staticRenderFns: staticRenderFns })
  }
}