var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-approval-details" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "viewTermsModal",
          attrs: { size: "large", heading: _vm.leaseAgrementData.legalTermName }
        },
        [
          _c(
            "div",
            {
              staticClass: "row",
              attrs: { slot: "component" },
              slot: "component"
            },
            [
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "terms-content col-xs-12" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", {
                      staticClass: "col-xs-12 terms-content-intro mt-xs-4",
                      domProps: {
                        innerHTML: _vm._s(_vm.leaseAgrementData.legalTermsText)
                      }
                    })
                  ])
                ])
              ])
            ]
          )
        ]
      ),
      _vm._v(" "),
      _vm.dataLoaded
        ? [
            _c("div", { staticClass: "col-xs-12 px-xs-0 pt-xs-4" }, [
              _c(
                "h1",
                {
                  staticClass:
                    "col-sm-4 col-xs-12 px-xs-0 my-xs-0 approval-details-page-title"
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.i18n.orderApprovalDetails.orderDetails) +
                      "\n      "
                  )
                ]
              ),
              _vm._v(" "),
              !_vm.statusUpdated
                ? _c(
                    "div",
                    {
                      staticClass:
                        "approval-buttons col-sm-8 col-xs-12 px-xs-0 d-flex pt-xs-4 pt-sm-0"
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "approve-order-button btn btn-default mr-sm-4",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.onStatusUpdate(
                                _vm.approvalDetailStatus.created
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.i18n.orderApprovalDetails.approveOrder)
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "reject-order-button btn btn-primary mt-xs-3 mt-sm-0",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.onStatusUpdate(
                                _vm.approvalDetailStatus.rejected
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.i18n.orderApprovalDetails.rejectOrder)
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-xs-12 px-xs-0 order-number pt-xs-4 pt-sm-5" },
              [
                _vm._v(
                  _vm._s(_vm.i18n.orderApprovalDetails.order) +
                    "\n      #" +
                    _vm._s(_vm.orderDetails.code)
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-8 col-sm-12 col-xs-12 pr-xs-0 pl-xs-0 d-flex flex-row flex-wrap"
              },
              [
                _c(
                  "div",
                  { staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0" },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderApprovalDetails.orderPlaced) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.getDate(_vm.orderDetails.created)) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0" },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderApprovalDetails.orderStatus) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info order-status" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderDetails.statusDisplay) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0" },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderApprovalDetails.orderPlacedBy) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderDetails.user.name) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0" },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderApprovalDetails.buisnessUnit) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "order-info" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.orderDetails.deliveryAddress.unit.uid) +
                          "\n        "
                      )
                    ])
                  ]
                ),
                _vm._v(" "),
                !_vm.isMultiple
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                      },
                      [
                        _c("div", { staticClass: "order-info-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.i18n.orderApprovalDetails.shippedTo) +
                              "\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.firstName
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.lastName
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries &&
                            _vm.orderDetails.deliveryGroup[0].value.splitEntries
                              .length !== 0 &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0] &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress &&
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.companyName
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.deliveryGroup[0].value
                                        .splitEntries[0].deliveryAddress
                                        .companyName
                                    )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.deliveryGroup[0].value
                                    .splitEntries[0].deliveryAddress.line1
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryAddress.line2
                              ? _c("span", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryAddress.line2
                                      ) +
                                      "\n          "
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("span", [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.town
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress.region
                                      .isocodeShort
                                  )
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.orderDetails.deliveryGroup[0].value
                                      .splitEntries[0].deliveryAddress
                                      .postalCode
                                  )
                                )
                              ])
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.deliveryGroup[0].value
                                    .splitEntries[0].deliveryAddress.country
                                    .isocode
                                ) + " "
                              )
                            ])
                          ]
                        )
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0" },
                  [
                    _c("div", { staticClass: "order-info-heading" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.i18n.orderApprovalDetails.billedTo) +
                          "\n        "
                      )
                    ]),
                    _vm._v(" "),
                    _vm.orderDetails.paymentInfo.cardType &&
                    _vm.orderDetails.paymentInfo.cardType.name &&
                    (_vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                      _vm.paymentTypes.googlePay.toLowerCase() ||
                      _vm.orderDetails.paymentInfo.cardType.name.toLowerCase() ===
                        _vm.paymentTypes.paypal.toLowerCase())
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ])
                          ]
                        )
                      : _vm.orderDetails.paymentInfo &&
                        _vm.orderDetails.paymentInfo.cardType
                      ? _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.accountHolderName
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.orderDetails.paymentInfo.cardType.name
                                )
                              )
                            ]),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.orderDetails.paymentInfo.cardNumber)
                              )
                            ]),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.expiryMonth
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo.expiryMonth
                                    ) +
                                      "/" +
                                      _vm._s(
                                        _vm.orderDetails.paymentInfo.expiryYear
                                      )
                                  )
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.orderDetails.paymentInfo.billingAddress
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.orderDetails.paymentInfo
                                        .billingAddress.postalCode
                                    )
                                  )
                                ])
                              : _vm._e()
                          ]
                        )
                      : _c(
                          "div",
                          { staticClass: "order-info d-flex flex-column" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.i18n.orderConfirmation.notAvailable
                                ) +
                                "\n        "
                            )
                          ]
                        )
                  ]
                ),
                _vm._v(" "),
                _vm.globals.isB2B() && !_vm.isMultiple
                  ? [
                      _vm._l(
                        _vm.orderDetails.deliveryGroup[0].value.splitEntries,
                        function(entry) {
                          return [
                            _vm.checkInstallable(
                              _vm.formattedEntries[entry.entryNumber]
                            )
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderApprovalDetails
                                                .installation
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.getInstallDate(
                                              _vm.orderDetails
                                                .scheduleInstallation
                                                .preferredDate
                                            )
                                          ) + " |"
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        { staticClass: "install-time" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.orderDetails
                                                  .scheduleInstallation
                                                  .preferredTime
                                              )
                                          )
                                        ]
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        }
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMultiple
                  ? _c(
                      "div",
                      {
                        staticClass: "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                      },
                      [
                        _c("div", { staticClass: "order-info-heading" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.i18n.orderApprovalDetails.deliveryMethod
                              ) +
                              "\n        "
                          )
                        ]),
                        _vm._v(" "),
                        _vm.orderDetails.deliveryMode
                          ? [
                              _c(
                                "div",
                                { staticClass: "order-info shipping-method" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderDetails.deliveryMode.name
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                          : [
                              _c(
                                "div",
                                { staticClass: "order-info shipping-method" },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.orderDetails.deliveryGroup[0].value
                                          .splitEntries[0].deliveryMode.name
                                      ) +
                                      "\n          "
                                  )
                                ]
                              )
                            ]
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isMultiple
                  ? [
                      _vm.orderDetails.deliveryMode
                        ? [
                            _vm.orderDetails.deliveryMode.description
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderApprovalDetails
                                                .deliveryInstructions
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.orderDetails.deliveryMode
                                              .description
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                        : [
                            _vm.orderDetails.deliveryGroup[0].value
                              .splitEntries[0].deliveryInstruction
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "order-info-heading" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.i18n.orderApprovalDetails
                                                .deliveryInstructions
                                            ) +
                                            "\n            "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "order-info" }, [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.orderDetails.deliveryGroup[0]
                                              .value.splitEntries[0]
                                              .deliveryInstruction
                                          ) +
                                          "\n            "
                                      )
                                    ])
                                  ]
                                )
                              : _vm._e()
                          ]
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.isB2B()
                  ? [
                      _vm.checkLeasable(_vm.orderDetails.deliveryGroup)
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "col-sm-3 col-xs-6 pt-xs-4 pt-sm-5 px-xs-0"
                            },
                            [
                              _c("div", { staticClass: "order-info-heading" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.i18n.orderApprovalDetails
                                        .leaseAgreement
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm.i18n.orderApprovalDetails
                                        .leaseAgreementName
                                    ) +
                                    "\n          "
                                )
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "order-info" }, [
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        _vm.showLeaseAgreement($event)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.orderApprovalDetails.download
                                      )
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        : _vm._e()
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "col-xs-12 px-xs-0 pt-xs-5 sub-title" },
                  [_vm._v(_vm._s(_vm.i18n.orderApprovalDetails.itemsOrdered))]
                ),
                _vm._v(" "),
                _vm._l(_vm.orderDetails.deliveryGroup, function(groups) {
                  return [
                    _vm.isMultiple
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "col-xs-12 px-xs-4 py-xs-4 multi-shipping-details d-flex flex-row flex-wrap"
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col-xs-12 col-sm-6 px-xs-0" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "order-info-heading" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.i18n.orderApprovalDetails
                                            .shippedTo
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "order-info d-flex flex-column"
                                  },
                                  [
                                    _c("span", { staticClass: "mb-xs-0" }, [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryAddress.firstName
                                          ) +
                                            "\n                  " +
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.lastName
                                            )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    groups.value.splitEntries &&
                                    groups.value.splitEntries.length !== 0 &&
                                    groups.value.splitEntries[0] &&
                                    groups.value.splitEntries[0]
                                      .deliveryAddress &&
                                    groups.value.splitEntries[0].deliveryAddress
                                      .companyName
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.companyName
                                            )
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          groups.value.splitEntries[0]
                                            .deliveryAddress.line1
                                        )
                                      )
                                    ]),
                                    _vm._v(" "),
                                    groups.value.splitEntries[0].deliveryAddress
                                      .line2
                                      ? _c("span", [
                                          _vm._v(
                                            ",\n                " +
                                              _vm._s(
                                                groups.value.splitEntries[0]
                                                  .deliveryAddress.line2
                                              ) +
                                              "\n              "
                                          )
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("span", [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryAddress.town
                                          ) + " "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryAddress.region
                                              .isocodeShort
                                          ) + " "
                                        )
                                      ]),
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            groups.value.splitEntries[0]
                                              .deliveryAddress.postalCode
                                          )
                                        )
                                      ])
                                    ]),
                                    _vm._v(" "),
                                    groups.value.splitEntries[0]
                                      .deliveryAddress &&
                                    groups.value.splitEntries[0].deliveryAddress
                                      .country &&
                                    groups.value.splitEntries[0].deliveryAddress
                                      .country.isocode
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryAddress.country.isocode
                                            )
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xs-12 col-sm-6 px-xs-0 pt-xs-4 pt-sm-0"
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "order-info-heading" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.i18n.orderApprovalDetails
                                            .deliveryMethod
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "order-info shipping-method" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          groups.value.splitEntries[0]
                                            .deliveryMode.name
                                        ) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ]
                            ),
                            _vm._v(" "),
                            groups.value.splitEntries[0].deliveryInstruction
                              ? [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-xs-12 col-sm-6 pt-xs-4 px-xs-0"
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "order-info-heading" },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.i18n.orderApprovalDetails
                                                  .deliveryInstructions
                                              ) +
                                              "\n              "
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "order-info" }, [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              groups.value.splitEntries[0]
                                                .deliveryInstruction
                                            ) +
                                            "\n              "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.globals.isB2B()
                              ? [
                                  _vm._l(groups.value.splitEntries, function(
                                    entry
                                  ) {
                                    return [
                                      _vm.checkInstallable(
                                        _vm.formattedEntries[entry.entryNumber]
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-xs-12 col-sm-6 pt-xs-4 px-xs-0"
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "order-info-heading"
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.i18n
                                                          .orderApprovalDetails
                                                          .installation
                                                      ) +
                                                      "\n                "
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "order-info" },
                                                [
                                                  _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getInstallDate(
                                                          _vm.orderDetails
                                                            .scheduleInstallation
                                                            .preferredDate
                                                        )
                                                      ) + " |"
                                                    )
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "install-time"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm.orderDetails
                                                              .scheduleInstallation
                                                              .preferredTime
                                                          )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(groups.value.splitEntries, function(productEntry) {
                      return productEntry.visible
                        ? _c(
                            "vx-product-tile",
                            { staticClass: "pb-xs-5 px-xs-0 col-xs-12" },
                            [
                              _vm.formattedEntries[productEntry.entryNumber]
                                .product.images &&
                              _vm.formattedEntries[productEntry.entryNumber]
                                .product.images.length !== 0
                                ? _c("template", { slot: "image-slot" }, [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href:
                                            _vm.globals.getNavigationUrl(
                                              "empty"
                                            ) +
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].product.url
                                        }
                                      },
                                      [
                                        _c("img", {
                                          attrs: {
                                            alt:
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.name,
                                            src: _vm.globals.getThumbnailImageUrl(
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].product.images
                                            )
                                          }
                                        })
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "template",
                                { slot: "details" },
                                [
                                  _vm.formattedEntries[productEntry.entryNumber]
                                    .product.name
                                    ? [
                                        _c(
                                          "p",
                                          {
                                            staticClass: "product-title mb-xs-2"
                                          },
                                          [
                                            _c("a", {
                                              attrs: {
                                                href:
                                                  _vm.globals.getNavigationUrl(
                                                    "empty"
                                                  ) +
                                                  _vm.formattedEntries[
                                                    productEntry.entryNumber
                                                  ].product.url
                                              },
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.formattedEntries[
                                                    productEntry.entryNumber
                                                  ].product.name
                                                )
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "sr-only" }, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.orderApprovalDetails.itemId
                                      ) +
                                        ":\n              " +
                                        _vm._s(
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.code
                                        )
                                    )
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    {
                                      staticClass: "product-code mb-xs-2",
                                      attrs: { "aria-hidden": "true" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.orderApprovalDetails.itemId
                                        ) +
                                          "\n              " +
                                          _vm._s(
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].product.code
                                          ) +
                                          "\n            "
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.globals.isB2B()
                                    ? _c("div", [
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ] &&
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product &&
                                        (_vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product.materialStatus ===
                                          "OBSOLETE" ||
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.materialStatus ===
                                            "PREDISCONTINUED") &&
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product.replacementProductCode &&
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product.replacementProductCode !== ""
                                          ? _c(
                                              "p",
                                              { staticClass: "error-text" },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.i18n
                                                        .orderApprovalDetails
                                                        .discontinued
                                                    ) +
                                                    " " +
                                                    _vm._s(
                                                      _vm.i18n
                                                        .orderApprovalDetails
                                                        .replacedBy
                                                    ) +
                                                    "\n              " +
                                                    _vm._s(
                                                      _vm.formattedEntries[
                                                        productEntry.entryNumber
                                                      ].product
                                                        .replacementProductCode
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ] &&
                                        _vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product &&
                                        (_vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product.materialStatus ===
                                          "OBSOLETE" ||
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ].product.materialStatus ===
                                            "PREDISCONTINUED") &&
                                        !_vm.formattedEntries[
                                          productEntry.entryNumber
                                        ].product.replacementProductCode
                                          ? _c(
                                              "p",
                                              { staticClass: "error-text" },
                                              [
                                                _vm._v(
                                                  "\n              " +
                                                    _vm._s(
                                                      _vm.i18n
                                                        .orderApprovalDetails
                                                        .discontinued
                                                    ) +
                                                    "\n            "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "p",
                                    { staticClass: "product-quantity mb-xs-3" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.i18n.orderApprovalDetails.quantity
                                        ) +
                                          " : " +
                                          _vm._s(productEntry.qty)
                                      )
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.promotionData[productEntry.entryNumber]
                                    ? _c(
                                        "p",
                                        {
                                          staticClass:
                                            "product-promo mb-xs-3 mr-sm-3"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.i18n.orderApprovalDetails
                                                .promo
                                            ) +
                                              ":\n              " +
                                              _vm._s(
                                                _vm.promotionData[
                                                  productEntry.entryNumber
                                                ]
                                              ) +
                                              "\n            "
                                          )
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.globals.isB2C() &&
                                  _vm.globals.siteConfig.isGiftingEnabled
                                    ? [
                                        _vm.checkGiftable(
                                          _vm.formattedEntries[
                                            productEntry.entryNumber
                                          ]
                                        )
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "helper-text mb-xs-3"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n
                                                        .orderApprovalDetails
                                                        .giftable
                                                    )
                                                  )
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].giftMessage
                                                ? [
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "gift-message-heading mb-xs-2"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm.i18n
                                                              .orderApprovalDetails
                                                              .giftMessageHeading
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "p",
                                                      {
                                                        staticClass:
                                                          "gift-message"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm
                                                              .formattedEntries[
                                                              productEntry
                                                                .entryNumber
                                                            ].giftMessage
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                : _vm._e()
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.globals.isB2B()
                                    ? [
                                        _vm.istallableProducts[
                                          productEntry.entryNumber
                                        ]
                                          ? [
                                              _c(
                                                "p",
                                                {
                                                  staticClass:
                                                    "helper-text mb-xs-3"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.i18n
                                                        .orderApprovalDetails
                                                        .installable
                                                    )
                                                  )
                                                ]
                                              )
                                            ]
                                          : _vm._e()
                                      ]
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("template", { slot: "right-section-slot" }, [
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .productPromotion &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .productPromotion.value &&
                                _vm.formattedEntries[productEntry.entryNumber]
                                  .productPromotion.value !=
                                  _vm.discount.discountZero
                                  ? _c(
                                      "p",
                                      { staticClass: "product-discount" },
                                      [
                                        _vm._v(
                                          "-$" +
                                            _vm._s(
                                              _vm.formattedEntries[
                                                productEntry.entryNumber
                                              ].productPromotion.value
                                            )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                !_vm.formattedEntries[productEntry.entryNumber]
                                  .product.notPurchasable
                                  ? _c(
                                      "p",
                                      {
                                        staticClass:
                                          "product-total-price mb-xs-3"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formattedEntries[
                                              productEntry.entryNumber
                                            ].totalPrice.formattedValue
                                          )
                                        )
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  { staticClass: "btn btn-primary" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.i18n.orderApprovalDetails
                                          .buyAgainButton
                                      )
                                    )
                                  ]
                                )
                              ])
                            ],
                            2
                          )
                        : _vm._e()
                    })
                  ]
                })
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "col-md-4 col-xs-12 px-xs-0 mb-xs-5 order-summary-container"
              },
              [
                _c("vx-order-summary", {
                  staticClass: "ml-sm-5",
                  attrs: {
                    i18n: _vm.i18n.orderSummary,
                    "order-details": _vm.orderDetails,
                    page: "orderApprovalDetails",
                    "is-shipping-multiple": _vm.isMultiple,
                    "is-giftable": _vm.isGiftable,
                    "is-installable": _vm.isInstallable
                  }
                }),
                _vm._v(" "),
                !_vm.statusUpdated
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "comments-section ml-md-5 mt-xs-4 mt-sm-0 mt-md-5"
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "comments-section-title pb-xs-2" },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.orderApprovalDetails.comments)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.approvalComments,
                              expression: "approvalComments"
                            }
                          ],
                          attrs: { type: "text" },
                          domProps: { value: _vm.approvalComments },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.approvalComments = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.orderDetails.approverComments
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "comments-section ml-md-5 mt-xs-4 mt-sm-0 mt-md-5"
                      },
                      [
                        _c(
                          "p",
                          { staticClass: "comments-section-title pb-xs-2" },
                          [
                            _vm._v(
                              _vm._s(_vm.i18n.orderApprovalDetails.comments)
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "comments-section-text" }, [
                          _vm._v(_vm._s(_vm.orderDetails.approverComments))
                        ])
                      ]
                    )
                  : _vm._e()
              ],
              1
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-7aeced73", { render: render, staticRenderFns: staticRenderFns })
  }
}