var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "access-customer-service-service-ticket" },
    [
      _vm._m(0),
      _vm._v(" "),
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("form", { ref: "ticketForm" }, [
        _c(
          "div",
          { staticClass: "serviceticket-section pt-sm-5" },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-12 pr-sm-0 px-xs-0 pl-sm-3" },
                [
                  _c(
                    "span",
                    {
                      staticClass: "contact-label gotham-medium",
                      attrs: { "aria-hidden": !_vm.isTablet() }
                    },
                    [_vm._v(_vm._s(_vm.i18n.serviceTicket.topicOfInquiry))]
                  ),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "topicDropdown",
                    attrs: {
                      "describedby-aria": "enquiry_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.topicOfInquiryList,
                      "aria-label": _vm.i18n.serviceTicket.topicOfInquiry,
                      name: "topicOfInquiry",
                      dropdownError: _vm.errors.has("topicOfInquiry"),
                      "placeholder-text": "one of the following"
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.topicOfInquiry = $event
                      }
                    },
                    model: {
                      value: _vm.topicOfInquiry,
                      callback: function($$v) {
                        _vm.topicOfInquiry = $$v
                      },
                      expression: "topicOfInquiry"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("topicOfInquiry"),
                          expression: "errors.has('topicOfInquiry')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "enquiry_error_msg"
                          }
                        },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(_vm.errors.first("topicOfInquiry")) +
                              "\n            "
                          )
                        ]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "Dispenser Concern"
              ? _c("vx-dispenser-concern", {
                  ref: "formFields",
                  attrs: { i18n: _vm.i18n.serviceTicket },
                  on: {
                    "open-file-modal": _vm.attachFiles,
                    removeAttachment: _vm.handleRemoveAttachment
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "Product Concern"
              ? _c("vx-product-concern", {
                  ref: "formFields",
                  attrs: { i18n: _vm.i18n.serviceTicket },
                  on: {
                    "open-file-modal": _vm.attachFiles,
                    removeAttachment: _vm.handleRemoveAttachment
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "General Inquiry"
              ? _c("vx-general-inquiry", {
                  ref: "formFields",
                  attrs: { i18n: _vm.i18n.serviceTicket },
                  on: {
                    "open-file-modal": _vm.attachFiles,
                    removeAttachment: _vm.handleRemoveAttachment
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "Request Dispenser Keys"
              ? _c("vx-dispenser-replacement", {
                  ref: "formFields",
                  attrs: { i18n: _vm.i18n.dispenserReplacement }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "Dispenser Concern" ||
            _vm.topicOfInquiry.label === "Product Concern" ||
            _vm.topicOfInquiry.label === "General Inquiry"
              ? _c("vx-contact-file-upload", {
                  on: {
                    "update:selectedFiles": _vm.updateSelectedFiles,
                    "update:errorFlag": _vm.updateErrorMessage
                  }
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.topicOfInquiry.label === "Dispenser Concern" ||
            _vm.topicOfInquiry.label === "Product Concern" ||
            _vm.topicOfInquiry.label === "General Inquiry" ||
            _vm.topicOfInquiry.label === "Request Dispenser Keys"
              ? _c(
                  "button",
                  {
                    staticClass:
                      "submit btn button-color ml-sm-3 mt-xs-4 mt-sm-5 mb-xs-5 mb-md-4",
                    attrs: {
                      type: "submit",
                      disabled: _vm.errors.any() || _vm.fileSizeExceed
                    },
                    on: { click: _vm.handleSubmit }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.i18n.serviceTicket.submit) +
                        "\n      "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
      _c("h2", { staticClass: "service-ticket-heading" }, [
        _vm._v("\n      We're here to help\n    ")
      ]),
      _vm._v(" "),
      _c("h4", { staticStyle: { "text-align": "center" } }, [
        _c("span", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("Please Note:")
        ]),
        _vm._v(" All fields are required unless\n      marked optional")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3e911aaf", { render: render, staticRenderFns: staticRenderFns })
  }
}