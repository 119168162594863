/**
 * Breadcrumb component will be used to display breadcrumbs on the page
 */

import globals from '../globals';

export default {
  name: 'vx-breadcrumb-render',
  components: {},
  props: {
    // breadcrumb details coming from parent component
    breadcrumbs: {
      type: Array,
    },
  },
  data() {
    return {
      globals,
    };
  },
  computed: {},
  methods: {},
};
