var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-history-filter" },
    [
      _vm._t("sortDropdown"),
      _vm._v(" "),
      _c("div", { staticClass: "pt-xs-4 pb-xs-3 filterHeading" }, [
        _vm._v(_vm._s(_vm.i18n.dateFilter))
      ]),
      _vm._v(" "),
      _vm._t("dateDropdown"),
      _vm._v(" "),
      _c("div", { staticClass: "col-xs-12 px-xs-0 filter-sort-buttons" }, [
        _c(
          "button",
          {
            staticClass: "col-xs-6 clear-filter-button btn btn-default",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.filterAction("clear")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.clearAll))]
        ),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "col-xs-6 save-filter-button btn btn-tertiary",
            attrs: { type: "button" },
            on: {
              click: function($event) {
                _vm.filterAction("apply")
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.apply))]
        )
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d3991336", { render: render, staticRenderFns: staticRenderFns })
  }
}