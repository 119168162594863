var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-contact-us" },
    [
      _vm.globals.siteConfig.isContactUsFormEnabled
        ? [
            _c(
              "div",
              { staticClass: "col-sm-5 col-xs-12 px-xs-0" },
              [
                _c("vx-live-chat-render", {
                  attrs: {
                    i18n: _vm.i18n.liveChat,
                    "live-chat-theme": _vm.liveChatTheme,
                    "show-contact-us": _vm.showContactUs,
                    "image-text-data": _vm.imageTextData,
                    "is-live-chat-enabled": _vm.isLiveChatEnabled
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-sm-7 col-xs-12 px-xs-0" },
              [
                _c("vx-service-ticket", {
                  attrs: {
                    i18n: _vm.i18n.serviceTicket,
                    "topic-of-inquiry": _vm.topicOfInquiry
                  }
                })
              ],
              1
            )
          ]
        : [
            _c(
              "div",
              { staticClass: "col-xs-12 px-xs-0" },
              [
                _c("vx-live-chat-render", {
                  attrs: {
                    i18n: _vm.i18n.liveChat,
                    "live-chat-theme": _vm.liveChatTheme,
                    "show-contact-us": _vm.showContactUs,
                    "image-text-data": _vm.imageTextData,
                    "is-live-chat-enabled": _vm.isLiveChatEnabled
                  }
                })
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-89602a06", { render: render, staticRenderFns: staticRenderFns })
  }
}