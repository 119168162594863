import passwordChecklistMessage from '../common/password-checkList-message-i18n';

export default {
  password: 'Password',
  newPasswordLabel: 'New Password',
  currentPasswordLabel: 'Enter Current Password',
  currentPasswordError:
    'The current password you entered is incorrect, please try again.',
  currentPasswordRequiredError: 'The current password field is required.',
  newPasswordRequiredError: 'The new password field is required.',
  currentPasswordInvalidError:
    'The current password you entered is incorrect, please try again.',
  newPasswordInvalidError:
    'The password selected does not meet the password guidelines, please try again.',
  newPasswordResetError:
    'The password you have entered matches your previous password. Please enter a different password.',
  passwordInstruction:
    'Password must contain upper/lower case letters, numbers, special characters(! @ # $ % ^ * ( ) _ + { } ; : . , -) and a minimum of 6 characters..',
  submit: 'Submit',
  showIconLabel: 'Show',
  resetPasswordButton: 'Reset Password',
  savePasswordButton: 'SAVE',
  passwordSuccessMsg:
    "Success! Your Password has been reset, we've logged you into your account.",
  iconEyeShowTitle: 'Show Password',
  iconEyeHideTitle: 'Hide Password',
  passwordCheck: passwordChecklistMessage,
  passwordInvalidError: 'Please enter in a valid Password',
}
