const manageSubscription = {
  subscriptionsLanding: {
    noSubscriptions: "Looks like you don't have any subscription",
    comingSoonLabel: 'COMING SOON',
    nextShipment: 'Next Shipment :',
    itemID: 'Item ID',
    quantityLabel: 'Quantity: ',
    subscriptionLabel: 'Subscription: ',
    subscribePopoverText: 'The price of the subscribed price might change. Subscription price on the day on the order is created will be the price of the product applicable for the order.',
    subscribeLearn: 'Learn More',
    subscribeInfoTitle: 'Subscription Info',
    subscribeAddressTitle: 'Subscription Address Info',
    subcriptionIconInfo: 'Info',
    subscription: ' Subscriptions',
    subscriptionID: 'Subscription ID',
    shipTo: 'Ship To',
    status: 'Status',
    with: 'With ',
    discount: '% discount',
    editSubscription: 'Edit subscription',
    edit: 'Edit',
    cancel: 'Cancel',
    inError: 'In Error -',
    active: 'ACTIVE',
    outOfStock: 'In Error - Not available online',
    paymentFailure: 'In Error - Payment Failure',
  },
  editSubscription: {
    change: 'Change',
    address: 'Address',
    cardLabel: 'Credit/Debit Card',
    save: 'SAVE',
    shipTo: 'Ship To',
    paymentMethod: 'Payment Method',
  },
  cancelSubscription: {
    heading: 'Cancel Subscription',
    cancelSubsText: 'Are you sure you want to cancel your subscription of ',
    cancelSubsquestion: '?',
    cancelInfoText: 'If the subscription order is in process/Shipped, then the cancellation will impact the subsequent scheduled delivery',
    keepSubscription: 'No, Keep Subscription',
    cancelSubscriptionButton: 'Cancel Subscription',
  },
};
export default manageSubscription;
