var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-cart-checkout" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "validateCheckoutModal",
          attrs: {
            heading: _vm.i18n.incompatibleProductHeading,
            size: "small"
          },
          on: { close: _vm.validateCheckoutModalClose }
        },
        [
          _c("template", { slot: "component" }, [
            _c("span", [
              _vm._v(_vm._s(_vm.i18n.incompatibleProductDescription))
            ]),
            _vm._v(" "),
            _c(
              "a",
              {
                on: {
                  click: function($event) {
                    _vm.globals.navigateToUrl("contactUs")
                  },
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    _vm.globals.navigateToUrl("contactUs")
                  }
                }
              },
              [_vm._v(_vm._s(_vm.i18n.incompatibleProductContact))]
            )
          ])
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "col-xs-12 checkout-button btn button-color text-trans checkout-btn",
          attrs: { disabled: _vm.leaseError || _vm.userReview ? true : false },
          on: {
            click: _vm.checkoutButtonClicked,
            keyup: function($event) {
              if (
                !("button" in $event) &&
                _vm._k(
                  $event.keyCode,
                  "enter-space",
                  undefined,
                  $event.key,
                  undefined
                )
              ) {
                return null
              }
              return _vm.checkoutButtonClicked($event)
            }
          }
        },
        [_vm._v(_vm._s(_vm.i18n.btnCheckout))]
      ),
      _vm._v(" "),
      _vm.userReview
        ? _c("div", { staticClass: "error-msg mt-xs-2 col-xs-12 pl-xs-0" }, [
            _vm._v(_vm._s(_vm.i18n.underReview))
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-786da1a6", { render: render, staticRenderFns: staticRenderFns })
  }
}