import updatePassword from '../common/profilePreferences-updatePassword-i18n';
import addPaymentForm from '../common/add-payment-form-i18n';

const manageProfileShoppingLists = {
  leftNav: {
    topHeading: 'Your Account',
    bottomHeading: 'Your Company',
  },
  addAddress: {
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    country: 'Country',
    bussinessUnit: 'Business Unit',
    companyName: 'Company Name',
    newUsercompanyName: 'Company Name',
    addressHeader: 'Ship to an Address',
    b2caddressLine1: 'Address 1',
    b2caddressLine2: 'Address 2 (optional)',
    b2baddressLine1: 'Address 1',
    b2baddressLine2: 'Address 2 (optional)',
    city: 'City',
    state: 'State',
    zipcode: 'Zip/Postal Code',
    palletShipments: 'This location accepts pallet shipments',
    save: 'SAVE',
    useShippingAddress: 'USE THIS ADDRESS',
    addressVerificationHeading: 'Address Verification',
    currentAddressHeading: 'Current Address',
    verifiedAddressHeading: 'Verified Address',
    useCurrentAddress: 'Use Current Address',
    useVerifiedAddress: 'Use Verified Address',
    defaultBillingAddress: 'Make default billing address',
    defaultShippingAddress: 'Make default shipping address',
    addAddressHeading: 'Add Address',
    subHeading: 'Please fill in your address details',
    editAddressHeading: 'Edit Address',
    emailRequiredError: 'Please enter in a valid Email Address',
    emailInvalidError: 'Please enter a valid Email Address',
    firstNameRequiredError: 'Please enter in a valid First Name',
    firstNameRegexError: 'Please enter in a valid First Name',
    firstNameMaxError: 'First Name should not be more than 35 characters',
    lastNameRequiredError: 'Please enter in a valid Last Name',
    lastNameRegexError: 'Please enter in a valid Last Name',
    lastNameMaxError: 'Last Name should not be more than 35 characters',
    phoneRequiredError: 'Please enter in a valid Phone Number',
    phoneMinError: 'Please enter valid Phone Number',
    countryError: 'Please select a Country',
    businessUnitError: 'Please select a Business Unit',
    companyNameError: 'Please enter in a valid Company Name',
    addressLine1Error: 'Please enter in a valid Address',
    addressLineRegexError:
    'At this time, orders cannot be shipped to PO Boxes',
    cityError: 'Please enter in a valid City',
    stateError: 'Please select a State',
    zipcodeError: 'Please enter in a valid Zip/Postal Code',
    zipCodeRegexError: 'Please enter in a valid Zip/Postal Code',
    companyNameRegexError: 'Please enter in a valid Company Name',
    submitAddress: 'Submit',
  },
  myAccountAddress: {
    addressHeading: 'Addresses',
    txtDefaultBillingAddress: 'Default Billing Address',
    txtDefaultShippingAddress: 'Default Shipping Address',
    txtDefaultPalletShipments: 'Accepts pallet shipments',
    txtUnderReview: 'Under Review',
    pendingReview: 'Pending Review',
    txtActive: 'Active',
    txtDisabled: 'Disabled',
    bussinessUnit: 'Business Unit',
    iconAddTitle: 'Add',
    iconEditTitle: 'Edit',
    iconDeleteTitle: 'Delete',
    a11yAddAddress: 'Add Address',
  },
  deleteAddress: {
    txtDelete: 'Are you sure you want to delete the address?',
    btnDelete: 'Delete',
  },
  paymentMethods: {
    topHeading: 'Payment Methods',
    paymentFormHeading: 'Add Payment Method',
    deleteExistingPaymentHeading: 'Delete Payment Method',
    deleteExistingPayment: 'Are you sure you want to delete this card?',
    addPaymentMethod: 'Add Payment Method',
    defaultPaymentMethod: 'Default Payment Method',
    yes: 'Delete',
    cancel: 'Cancel',
    deletePaymentSuccessMessage: 'Payment details succesfully deleted.',
    iconAddTitle: 'Add',
    iconEditTitle: 'Edit',
    iconDeleteTitle: 'Delete',
    a11yAddPayment: 'Add Payment',
    creditCardAddressError: 'We are unable to process your payment at this time. Please verify that your payment details are correct and try again. If the problem persists, contact us for further assistance.',
  },
  addPaymentForm,
  profilePreferences: {
    pageTitle: 'Profile & Preferences',
    txtPersonalDetails: 'Personal Details',
    birthDateLabel: 'Birthday',
    marketingChoices: "You're currently enrolled in email marketing.",
    marketingChoicesFalse:
      "You're currently not enrolled in any communications.",
    marketingChoicesOptedOut: 'You have opted-out of marketing emails',
    socialHelpText1: 'Your account is linked to',
    socialHelpText2:
      ", disconnect your account if you 'd like to create a password.",
    socialSuccesConnect: 'You have connected your social account successfully',
    iconEditTitle: 'Edit',
    iconFacebookTitle: 'Facebook',
    iconGoogleTitle: 'Google',
    updatePassword,
    communicationPreferences: {
      title: 'Communication Preferences',
      txtMarketingCommunication: 'Marketing Communications',
      txtPromotion:
        'Which types of promotions & special offers are you interested in',
      btnSave: 'Save',
      successMarketingPreferences:
        'Communication Preferences updated successfully',
      marketingChoicesOptedOut: 'You have opted-out of marketing emails',
    },
    taxExemption: {
      title: 'Tax Exemption Verification',
      titleStatus: 'Tax exempt status',
      status: 'Tax exemption in review',
      helpText: 'Please attach documentation of your organization’s sales tax exempt status. The file size can be up to 10MB and duplicate files cannot be attached. Please allow up to 2 business days for request to be verified. You will be contacted via email to confirm approval. Once approved, you will be able to place an order with no sales tax applied.',
      downloadCaption: 'Download a fomatted csv file.',
      dragDropCaption: 'Drag and drop file here',
      fileAttachCaption1: 'OR',
      fileAttachCaption2: 'Choose file to attach',
      removeText: 'Remove File',
      upload: 'SUBMIT',
      fileSize: 'File size: ',
      fileSizeUnit: 'kb',
      attachmentSizeLimitError:
        "File exceeded size limit and can't be attached",
      taxExemptionSuccess: 'Tax exemption updated successfully.',
      heading: 'Edit Company Tax Exemption',
    },
    editPersonalDetails: {
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email',
      mobileNo: 'Mobile Phone (optional)',
      phoneRequiredError: 'Please enter phone number',
      phoneMinError: 'Please enter valid phone number',
      save: 'Save',
      newPasswordLabel: 'New Password',
      currentPasswordLabel: 'Enter Current Password',
      currentPasswordError: 'Current Password is not valid.',
      passwordRequiredError: 'Please enter in a Password.',
      passwordInstruction:
        'Password must contain upper/lower case letters, numbers, special characters(! @ # $ % ^ * ( ) _ + { } ; : . , -) and a minimum of 6 characters..',
      emailInfo:
        'If you change your email address, you will be asked to re-enter your password to confirm your account update.',
      submit: 'Submit',
      successPersonalDetails: 'Profile updated successfully.',
      emailRequiredError: 'Please enter your Email Address',
      emailInvalidError: 'Please enter in a valid Email Address',
      firstNameRequiredError: 'Please provide your First Name',
      firstNameRegexError: 'Please enter in a valid First Name',
      firstNameMaxError: 'First Name should not be more than 35 characters',
      lastNameRequiredError: 'Please provide your Last Name',
      lastNameRegexError: 'Please enter in a valid Last Name',
      lastNameMaxError: 'Last Name should not be more than 35 characters',
      firstNameInvalidError: 'Please enter in a valid First Name',
      lastNameInvalidError: 'Please enter in a valid Last Name',
      phoneNumberInvalidError: 'Please enter in a valid Phone Number',
      currentPasswordInvalidError:
        'Sorry, that password is not valid for this account',
      iconEyeShowTitle: 'Show Password',
      iconEyeHideTitle: 'Hide Password',
      emailDuplicateError: 'Account already exists with the given email id',
      gender: 'Gender (Optional)',
      birthDay: 'Birthday (Optional)',
      monthRequiredError: 'Please select the Month',
      dateRequiredError: 'Please select the Date',
    },
    parentBusinessUnit: {
      title: 'Parent Business Unit',
      companyName: 'Company Name',
    },
    socialAccounts: {
      title: 'Social Accounts',
      facebook: 'Facebook',
      connect: 'Connect',
      disconnect: 'Disconnect',
      google: 'Google',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      mobileNo: 'Mobile no',
      save: 'Save',
      newPasswordLabel: 'Password',
      currentPasswordLabel: 'Enter Password',
      currentPasswordError: 'Password is not valid.',
      passwordInstruction:
        'Password must contain upper/lower case letters, numbers, special characters(! @ # $ % ^ * ( ) _ + { } ; : . , -) and a minimum of 6 characters..',
      submit: 'Set new password and disconnect',
      successSocialDetails: 'Social Account details updated successfully.',
      emailRequiredError: 'Please enter your Email Address',
      emailInvalidError: 'Please enter valid Email Address',
      helpText1: 'Enter a new password. After we disconnect your',
      helpText2: "account you'll use your email and new password to log in.",
      socialSuccesDisconnect:
        'You have been disconnected your social account successfully',
      socialFailureDisconnect:
        'An error occured while disconnecting your social account',
      iconEyeShowTitle: 'Show Password',
      iconEyeHideTitle: 'Hide Password',
    },
  },
  shoppingLists: {
    shoppingListsLabel: 'Lists',
    shoppingListsContent: 'Create lists of products you want to shop for.',
    createListLink: 'Create List',
    selectListLink: 'Select a List',
    deleteListLink: 'Remove Lists',
    checkbox: '',
    listName: 'Name',
    lastUpdated: 'Last Updated',
    noOfProducts: '# of Items',
    subtotal: 'Subtotal',
    refineResults: 'Refine Results',
    clearAllText: 'Clear All',
    asc: 'Name: A to Z',
    des: 'Name: Z to A',
    latest: 'Last Updated: Latest First',
    old: 'Last Updated: Old First',
    high: '# of Products: High to Low',
    low: '# of Products: Low to High',
    highPrice: 'Subtotal: High to Low',
    lowPrice: 'Subtotal: Low to High',
    sortBy: 'Sort by',
    iconFilterTitle: 'Filter',
  },
  listDetails: {
    listSubtotal: 'List Subtotal:',
    itemId: 'ITEM ID',
    mfg: 'MFG',
    cust: 'CUST',
    cmir: 'CMIR',
    addListToCart: 'Add List to Cart',
    addListToCartError:
      'One or more products were not added to your cart due to stock availability.',
    addToCart: 'Add to cart',
    findAStore: 'Find a Store',
    quantityLabel: 'Quantity',
    noProductMessage: 'You do not have any products in this list.',
    giftCheckbox: 'Gift this item',
    installationDetailsHeading: 'Installation Services',
    giftHeading: 'Gift Services',
    installationLink: 'learn more about installation services',
    giftLink: 'learn more about Gift services',
    shareListHeading: 'Share List',
    shareItemResponse: 'List Shared Successfully',
    deleteListHeading: 'Delete List',
    downloadImagePdfHeading: 'PDF & Image Downloads',
    editListNameHeading: 'Edit List Name',
    selectListHeading: 'Select a List',
    createNewListLabel: 'Create New List',
    showGuestUserAddListMessage:
      'You have not created a list. Please create one by clicking the add to list icon for any product.',
    addCustomAttributeHeading: 'Add Custom Attributes',
    save: 'Save',
    selectListResponse: 'List Saved Successfully',
    saveAListError: 'Please enter correct list name',
    discontinued: 'Discontinued',
    replacedBy: 'and Replaced By Similar Product',
    notifyMe: 'NOTIFY',
    notifyMeContent:
      'Please add the email address you would like to be notified with when this product becomes available.',
    notifyError: 'Please enter in a valid Email Address.',
    emailAddress: 'Email Address',
    submit: 'Submit',
    notifyMeResponse: 'Recipient will be informed when in Stock',
    notifyMeHeading: 'Notify Me',
    add: 'ADD',
    comingSoonLabel: 'COMING SOON',
    maxPurchaseableQuantityErrorCode: '197',
    maxPurchaseableQuantityErrorMessage:
      'One of the product in the cart exceeds Maximum allowable quantity. The quantity is updated to meet the threshold.',
    maxPurchaseableQuantityUpdateMessage1:
      'Maximum order quantity for this item is',
    maxPurchaseableQuantityUpdateMessage2:
      'your cart has been automatically updated.',
    lowStockErrorCode: '198',
    lowStockErrorMessage:
      'One of the product in the cart is running low on stock only available quantity is added to your cart',
    inStock: 'In Stock',
    outOfStock: 'Not available online',
    lowInventoryMessage: 'Low Inventory',
    availableSoon: 'Available Soon',
    iconShareTitle: 'Share',
    iconEditTitle: 'Edit',
    iconDeleteTitle: 'Delete',
    iconSaveTitle: 'Save List',
    iconDownloadTitle: 'Download PDF/Images',
    iconDownloadExcelTitle: 'Download product info',
    iconAddCustomAttributeTitle: 'Add Custom Attributes',
    iconAddToListTitle: 'Add to list',
    iconCertificationTitle: 'Green Certified',
    iconShippingTitle: 'Free Shipping Available',
    iconOnlineTitle: 'Only Available Online',
    iconSubscriptionTitle: 'Subscribe',
    iconSeasonalTitle: 'Seasonal Product',
    listFilterPlaceholder: 'Search in the list',
    maxValueUpdatedStatus:
      'The product quantity is adjusted to the maximum allowed quantity.',
    minValueUpdatedStatus:
      'The product quantity is adjusted to the minimum allowed quantity.',
    stockLevelUpdatedStatus:
      'The product quantity is adjusted to the stock availability.',
    shareMultipleItemResponse: 'Products Shared Successfully',
    downloadProductExcelHeading: 'Download Product Information for List',
    addProductsToListDescription: 'Enter Product SKUs to add:',
    productListExample: '(e.g 13102, 32295)',
    searchProductsForListDescription: 'Or search Products:',
    addProductToListSuccess: 'Items added to wishlist ',
    addProductToListError: 'Invalid product ID has been entered',
    applyProductCodes: 'Add Product to List',
    iconAddProductsToListTitle: 'Add Items by #',
    imageDownloadError:
      'No images are currently available for any products in your list.',
    defaultDownloadedFilename: 'imagedownload.zip',
    viewAttribute: 'View Attribute',
    hideAttribute: 'Hide Attribute',
    defaultExcelFilename: 'AllProducts.zip',
    removeCategory: 'Remove Category',
    addToCartSuccessMessage: 'item(s) have been added to your cart',
    notPurchasable: 'Not Purchasable',
  },
  downloadInfo: {
    tabPdf: 'PDF Download',
    tabImage: 'Image Download',
  },
  pdfDownload: {
    basicInformation:
      'Fill out any of the following information to include on the PDF',
    nameOnPdf: 'Name to include on the PDF (Optional)',
    phoneNumber: 'Phone Number (Optional)',
    emailAddress: 'Email Address (Optional)',
    message: 'Message (Optional)',
    barColor: 'Bar Color',
    optionalHeadlines: 'Optional Headlines and Settings',
    headlineLine1: 'Large headline on Line 1',
    headlineLine2: 'Medium headline on Line 2',
    headlineColor: 'Headline Color',
    coverPage: 'Cover Page',
    onlyOnFirstPage: 'Only on first page?',
    onlyOnFirstPageDescription:
      'Only display headlines on the first page of the PDF?',
    formatList: 'Format the list in the chosen format',
    oneColumnOption: 'Display in one column',
    oneColumnOptionDescription: 'Structure the content in one column',
    twoColumnOption: 'Display in two columns',
    twoColumnOptionDescription:
      'Divide and structure the content into two columns',
    threeColumnOption: 'Display in three columns',
    threeColumnOptionDescription:
      'Divide and structure the content into three columns',
    fullDescptionOption: 'Display as full detail',
    fullDescptionOptionDescription:
      'Divide and structure the content into two columns',
    category: 'Category description',
    categoryDescription: 'Provide category descriptions',
    productSellingStatement: 'Product selling statement',
    productSellingStatementDescription: 'Include product selling statement',
    createAndDownloadPdf: 'Create and download Pdf',
    phoneMinError: 'The Phone Number entered is invalid, please try again.',
    emailInvalidError: 'Please enter a valid email address.',
    dropdownInputDefault: 'Select a Cover',
    dropdownInputDefaultValue: '',
    disclaimerLine1:
      'For consumers and customers located in the European Economic Area (EEA):',
    disclaimerLine2:
      'Georgia-Pacific subscribes to the Safe Harbor privacy principles.',
    disclaimerLine3: 'Please visit our ',
    disclaimerLine4: 'Privacy Policy ',
    disclaimerLine5: 'to learn how you can exercise and amend your choices.',
    resetLink: 'Reset Form',
    foodServicesOptionsValue: 'foodServices',
    foodServiceOptions: 'Food Service',
    officeBuildingOptionsValue: 'officeBuilding',
    officeBuildingOptions: 'Office Building',
    highTrafficOptionsValue: 'highTraffic',
    highTrafficOptions: 'High Traffic',
    healthcareOptionsValue: 'healthcare',
    healthcareOptions: 'Healthcare',
    manufacturingOptionsValue: 'manufacturing',
    manufacturingOptions: 'Manufacturing',
    educationOptionsValue: 'education',
    educationOptions: 'Education',
    governmentOptionsValue: 'government',
    governmentOptions: 'Government',
    featureCheckedItem: 'Feature checked item(s) in the list',
    featureCheckedNone: 'None',
    featureCheckedFull: 'Full details',
    featureDropdownDefault: 'Select Options',
    privacyPolicyLink: 'https://www.gp.com/legal/legal-notices/privacy-notice',
    featureDropDown: 'Select items to feature',
    featureCheckedNoneDescription: 'No special formatting for checked items',
    featureCheckedFullDescription:
      'Create full details for checked items in list',
  },
  imageDownload: {
    imageDownloadDescription: 'Fill out any of the download options below.',
    imageDownloadcontent:
      'Note: Downloading time will increase with more images',
    allImageDownloadCheckbox: 'Include full set of images.',
    allImageDownloadOption: 'Download all images for all products',
    imageDownloadFileFormat: 'Image Format Options',
    imageDownloadFileSize: 'Image Size Options',
    imageDownloadButton: 'DOWNLOAD',
    imageDownloadJpg: 'JPG',
    imageDownloadPng: 'PNG',
    imageDownloadGif: 'GIF',
    imageDownloadOriginalSize: 'Original Size (2000px x 2000px)',
    imageDownloadLarge: 'Large (1200px x 1200px)',
    imageDownloadMedium: 'Medium (515px x 515px)',
    imageDownloadSmall: 'Small (300px x 300px)',
    imageDownloadThumbnail: 'Thumbnail (96px x 96px)',
    disclaimerLine1:
      'For consumers and customers located in the European Economic Area (EEA):',
    disclaimerLine2:
      'Georgia-Pacific subscribes to the Safe Harbor privacy principles.',
    disclaimerLine3: 'Please visit our ',
    disclaimerLine4: 'Privacy Policy ',
    disclaimerLine5: 'to learn how you can exercise and amend your choices.',
    formatJPG: 'jpg',
    formatPNG: 'png',
    formatGIF: 'gif',
    sizeDefault: '2000',
    size1200: '1200',
    size515: '515',
    size300: '300',
    size96: '96',
    privacyPolicyLink: 'https://www.gp.com/legal/legal-notices/privacy-notice',
  },
  addCustomAttribute: {
    attribute1: 'Custom Attribute 1',
    attribute2: 'Custom Attribute 2',
    attribute3: 'Custom Attribute 3',
    attributePlaceholder: 'Add a custom attribute to your current list',
    save: 'Save changes',
  },
  createList: {
    createListLabel: 'List Name',
    save: 'Save',
    createListContent:
      'Create the list here, then go shopping and add items to this list.',
    createListSuccess: 'List saved successfully',
    existingListError:
      'That List Name already exists, please try a different List Name.',
    emptyListError: 'Please enter in a List Name.',
    maxListNameError: 'The List Name is invalid, please try again.',
  },
  editListName: {
    editListLabel: 'List Name',
    edit: 'Edit',
    save: 'Save',
    editListSuccess: 'Wishlist edited Successfully',
    listNameRequiredError: 'Please enter in a List Name.',
    listNameMaxError: 'The List Name is invalid, please try again.',
  },
  deleteList: {
    content: 'Are you sure you want to delete this List?',
    multipleListContent: 'Are you sure you want to delete these Lists?',
    delete: 'Delete',
    deleteListSuccess: 'List deleted successfully',
  },
  shareList: {
    shareListDescription: 'Add the email addresses you would like to share',
    shareListContent: 'list with. Separate multiple emails with a comma.',
    shareListEmail: 'Email Address',
    shareListButton: 'Share',
    shareListError: 'The Email Address entered is invalid, please try again.',
    shareListRequired: 'Please enter in an Email Address',
    content:
      'Fill out the information below to send an email with information about the products in this list.',
    recipientEmail: 'Recipient Emails*',
    helperText: 'Separate multiple email addresses by a comma.',
    senderName: "Sender's Name*",
    senderEmail: "Sender's Email Address*",
    a11ySenderOptionalMsgLabel: 'Please Enter Optional Message',
    subject: 'Subject*',
    message: 'Message (optional)',
    attachPDF: 'Include 2 Column PDF',
    addLink: 'Include Link to Copy List',
    disclaimerLine1:
      'For consumers and customers located in the European Economic Area (EEA):',
    disclaimerLine2: 'Georgia-Pacific subscribes to the Privacy Policy.',
    disclaimerLine3: 'Please visit our ',
    disclaimerLine4: 'Privacy Policy ',
    disclaimerLine5: 'to learn how you can exercise and amend your choices.',
    privacyPolicyLink: 'https://www.gp.com/legal/legal-notices/privacy-notice',
    sendEmail: 'Send Email',
    recipientEmailValidation: 'Please enter a valid email address.',
    senderNameRequired: "Please enter the Sender's Name.",
    senderNameRegexError: "Please enter in a valid Sender's Name",
    senderEmailRequired: 'Please enter a valid email address.',
    subjectRequired: 'Please enter a subject for the email.',
    subjectText1: 'This product list,',
    subjectText2: 'is being shared from the Georgia-Pacific Product Catalog.',
    shareItemResponse: 'List Shared Successfully',
    shareMultipleItemResponse: 'Products Shared Successfully',
  },
  downloadProductInfo: {
    downloadProductInfoDescription:
      'Select which option to download product info below',
    downloadProductInfoContent:
      'Note: Downloading time will increase when selecting All Products',
    downloadProductInfoFormat: 'File download options',
    downloadProductInfoOptionAll: 'All Products',
    downloadProductInfoOptionCurrent: 'Current list',
    downloadProductInfoButton: 'Download Zip',
    downloadProductInfoCurrentValue: 'currentList',
    downloadProductInfoAllproductsValue: 'allProducts',
  },
  supportTicket: {
    supportTicketHeading: 'Support Ticket',
    tickets: 'Tickets',
    topicOfInquiry: 'Topic of Inquiry',
    createdBy: 'Created By',
    dateOpened: 'Date Opened',
    status: 'Status',
    serviceAgent: 'Service Agent',
    noDataAvailable: 'no data available',
    createTicket: 'Create Ticket',
    accordionToggleA11y: 'Accordion toggle',
    contactNumber: 'Call: 1-866-435-5647',
    contactTime: '(8am - 7pm ET) Mon - Thurs',
    contactTimeA11y: '8am thru 7pm Eastern Time Monday thru Thursday',
    contactTime2: '(8am - 6pm ET) Friday',
    contactTime2A11y:
      '(8:00 AM through 6:00 PM Eastern Time) Monday through Friday',
    liveChat: 'Live chat',
    liveChatTime: '(9am - 5pm ET)',
    liveChatTimeA11y: '9am thru 5pm Easetern Time',
    ariaLabelAscending: 'Tickets: activate to sort column ascending',
    ariaLabelDescending: 'Tickets: activate to sort column descending',
    ariaSortAscending: 'Ascending',
    ariaSortDescending: 'Descending',
    ticketNumberAsc: 'Ticket # Ascending',
    ticketNumberDsc: 'Ticket # Descending',
    topicOfInquiryAsc: 'Topic of Inquiry A to Z',
    topicOfInquiryDsc: 'Topic of Inquiry Z to A',
    createdByAsc: 'Created By A to Z',
    createdByDsc: 'Created By Z to A',
    dateOpenedLatest: 'Date Opened Latest',
    dateOpenedOldest: 'Date Opened Oldest',
    statusAsc: 'Status A to Z',
    statusDsc: 'Status Z to A',
    sortBy: 'Sort By',
  },
  productIconsTitle: {
    freeShipping: 'Free Shipping Eligible',
    onlineOnly: 'Only Available Online',
    subscribable: 'Subscription Eligible',
    seasonal: 'Seasonal Product',
    bundleAvailable: 'Bundle Eligible',
    certification: 'Certifications Available',
    customisation: 'Customizable Product',
    installation: 'Installation Service Available',
    sample: 'Sample Eligible',
  },
};

export default manageProfileShoppingLists;
