import passwordChecklistMessage from './password-checkList-message-i18n';

export default {
  heading: 'Create an Account',
  subHeading: 'Your Information',
  addToMarketComm:
    'Email me savings, product info, availability & more from Georgia-Pacific’s family of brands.',
  companyModalHeading: 'Give us More Info',
  termasConditionHeading: 'Terms and Condition',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email Address',
  password: 'Password',
  passwordInstruction:
    'Password must contain upper/lower case letters, numbers, special characters(! @ # $ % ^ * ( ) _ + { } ; : . , -) and a minimum of 6 characters..',
  mailerText:
    "I'd like to receive marketing & promotion communications from Georgia Pacific.",
  termsPrivacy: "By creating an account you agree to Georgia Pacific's ",
  termsPrivacyB2C: 'By creating an account you agree to our ',
  termsPrivacyLink: 'Terms and Conditions',
  privacyPolicy: ' & Privacy Policy',
  submit: 'Create Account',
  haveAccount: 'Already have an account?',
  gpExpressLinkText: 'Please use below link for',
  gpExpressLink: 'GP Express login',
  login: 'Login',
  companyDetailsDataModalHeading: 'Enter company details',
  // Internal company details module
  companyName: 'Company Name',
  companyDetailsDecription: 'Company Details',
  addressPart1: 'Address 1',
  addressPart2: 'Address 2 (optional)',
  city: 'City',
  zipcode: 'Zipcode',
  cmpPhnNo: 'Company Phone Number',
  countryLabel: 'Country',
  mobileNo: 'Mobile no',
  other: 'Other',
  skip: 'Skip',
  save: 'Save',
  dropdownInput: 'Select Option',
  emptyStateError: 'Please select a State',
  addressVerificationHeading: 'Address Verification',
  currentAddressHeading: 'Current Address',
  verifiedAddressHeading: 'Verified Address',
  useCurrentAddress: 'Use Current Address',
  useVerifiedAddress: 'Use Verified Address',
  companyNameError: 'Please enter Company Name',
  companyNameMaxError: 'Company Name cannot be more than 40 characters',
  companyPhnNoError: 'Please enter the Company Phone Number',
  phoneMinError: 'Please enter valid Phone Number',
  addressLine1Error: 'Please enter Address line 1',
  addressLineRegexError:
    'At this time, orders cannot be shipped to PO Boxes',
  cityError: 'Please enter City',
  stateError: 'Please select a State',
  zipCodeError: 'Please enter in a valid Zip/Postal Code',
  zipCodeFormatError: 'Please enter in a valid Zip/Postal Code',
  //
  businessSubHeading: 'Your Information',
  // ErrorCodes
  195: 'An account already exists for that Email Address',
  // Flyout success msg
  registerSuccessMsg: 'You have registered and logged in successfully',
  // Validation messages
  emailRequiredError: 'Please enter in a valid Email Address',
  emailInvalidError: 'Please enter in a valid Email Address',
  passwordRequiredError: 'Please enter in a valid Password',
  passwordInvalidError: 'Please enter in a valid Password',
  passwordMinError: 'Your password must be atleast 8 characters long',
  firstNameRequiredError: 'Please enter in a valid First Name',
  firstNameRegexError: 'Please enter in a valid First Name',
  firstNameMaxError: 'First name should not be more than 35 characters',
  lastNameRequiredError: 'Please enter in a valid Last Name',
  lastNameRegexError: 'Please enter in a valid Last Name',
  lastNameMaxError: 'Last Name should not be more than 35 characters',
  country: 'Please select a Country',
  gender: 'Gender (Optional)',
  birthDay: 'Birthday (Optional)',
  monthRequiredError: 'Please select the Month',
  dateRequiredError: 'Please select the Date',
  iconEyeShowTitle: 'Show Password',
  iconEyeHideTitle: 'Hide Password',
  iconAlertTriangleTitle: 'Important Notice',
  confirmEmail: 'Confirm Email Address',
  confirmEmailMismatchError: 'Confirm email does not match the email',
  confirmEmailRequiredError: 'Confirm email is required',
  passwordCheck: passwordChecklistMessage,
};
