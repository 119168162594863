import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxCreateList from '../vx-create-list/vx-create-list.vue';
import vxDeleteList from '../vx-delete-list/vx-delete-list.vue';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import globals from '../../common/globals';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import { eventBus, dataTablesEventBus } from '../../../modules/event-bus';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';
import { order, cookies } from '../../common/mixins/vx-enums';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxTable from '../../common/vx-table/vx-table.vue';

export default {
  name: 'vx-shopping-lists',
  components: {
    vxDropdownPrimary,
    vxCreateList,
    vxDeleteList,
    vxModal,
    vxSpinner,
    vxTable,
  },
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
    },
    /**
     * Indicator for Favorites enabled
     */
    isFavorites: {
      type: Boolean,
    },
  },
  mixins: [mobileMixin, flyoutBannerMixin, cookiesMixin],
  data() {
    return {
      globals,
      cookies,
      selectedItems: [],
      selectedLists: [],
      selectedListNames: [],
      allListNames: [],
      listName: '',
      hideRemoveLists: false,
      enableRemoveLink: false,
      filterHidden: true,
      tableTitles: [
        this.i18n.shoppingLists.listName,
        this.i18n.shoppingLists.lastUpdated,
        this.i18n.shoppingLists.noOfProducts,
        this.i18n.shoppingLists.subtotal,
      ],
      tableOrders: [
        this.i18n.shoppingLists.asc,
        this.i18n.shoppingLists.des,
        this.i18n.shoppingLists.latest,
        this.i18n.shoppingLists.old,
        this.i18n.shoppingLists.high,
        this.i18n.shoppingLists.low,
        this.i18n.shoppingLists.highPrice,
        this.i18n.shoppingLists.lowPrice,
      ],
      MobileSortOptions: [],
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      noLists: false,
      order,
      dataLoaded: true,
      respTable: {},
      soldTo: '',
      tableConfig: {},
      tableData: [],
    };
  },
  mounted() {
    this.createOptions();
    this.onInit();
  },
  updated() {},
  methods: {
    /**
     * This function handles the sorting of all shopping lists service
     */
    triggerSort(e) {
      const partialSortTitles = [];
      this.tableTitles.forEach((child) => {
        partialSortTitles.push(child.split(' ')[0]);
      });
      let selectedSort = e.label.split(' ')[0];
      selectedSort = selectedSort.replace(':', '');
      const sortValue = partialSortTitles.indexOf(selectedSort);
      dataTablesEventBus.$emit('mobile-sort', { index: sortValue, order: e.value });
      this.filterHidden = !this.filterHidden;
      this.$refs.refineListModal.close();
    },
    /**
     * This function is called when the create list link is clicked
     */
    handleCreateList() {
      this.$refs.createListModal.open();
    },
    /**
     * This function is called when the delete list link is clicked
     */
    handleDeleteList() {
      this.$refs.deleteListModal.open();
    },
    /**
     * This function handles the response of get all shopping lists service
     */
    handleShoppingListsResponse(response) {
      if (this.respTable.data) {
        this.respTable.destroy();
      }
      this.$refs.spinner.hideSpinner();
      this.dataLoaded = true;
      this.initializeTableConfig();
      if (response.data.wishlists) {
        this.hideRemoveLists = true;
        this.tableData = response.data.wishlists;
        this.allListNames = [];
        this.allListNames = response.data.wishlists.map((child) => {
          if (
            child
            && child.wishlistEntries
            && child.wishlistEntries.length === 0
          ) {
            this.noLists = true;
          }
          return child.name;
        });
      } else if (!Object.prototype.hasOwnProperty.call(response.data, 'wishlists')) {
        this.tableData = [];
        this.allListNames = [];
      }
    },
    /**
     * This function handles the error of get all shopping lists service
     */
    handleShoppingListsError() {
      this.$refs.spinner.hideSpinner();
    },
    onInit() {
      this.$refs.spinner.showSpinner();
      const requestConfig = {};
      this.manageProfileShoppingListService.getShoppingLists(
        requestConfig,
        this.handleShoppingListsResponse,
        this.handleShoppingListsError,
      );
    },
    /**
     * This function handles the success emitted from the create list modal component
     */
    deleteModalSuccess(success) {
      this.selectedListNames = [];
      this.selectedLists = [];
      this.selectedItems = [];
      this.allListNames = [];
      this.dataLoaded = false;
      this.onInit();
      this.$refs.deleteListModal.close();
      this.updateDataTableSelectAllCtrl();
      this.showFlyout('success', success, true);
    },
    /**
     * This function handles the error emitted from the delete list modal component
     */
    deleteModalError(error) {
      this.onInit();
      this.$refs.deleteListModal.close();
      this.showFlyout('error', error, true);
    },
    /**
     * This function handles the success emitted from the create list modal component
     */
    createModalSuccess(success) {
      this.dataLoaded = false;
      this.onInit();
      this.$refs.createListModal.close();
      this.showFlyout('success', success, true);
    },
    showlistDetailPage() {
      eventBus.$emit('show-list', this.listName);
    },
    toggleFilter() {
      this.filterHidden = !this.filterHidden;
      this.$refs.refineListModal.open();
    },
    closeRefineListModal() {
      this.filterHidden = !this.filterHidden;
    },
    createOptions() {
      const self = this;
      this.tableOrders.forEach((val, index) => {
        const obj = {};
        obj.label = val;
        obj.index = index;
        if (
          val === self.i18n.shoppingLists.asc
          || val === self.i18n.shoppingLists.old
          || val === self.i18n.shoppingLists.low
          || val === self.i18n.shoppingLists.lowPrice
        ) {
          obj.value = self.order.ascending;
        } else {
          obj.value = self.order.descending;
        }
        self.MobileSortOptions.push(obj);
      });
    },
    populateDefaultMessage() {
      this.$refs.sortDropdown.setDropdownLabel(this.i18n.shoppingLists.sortBy);
    },
    handleSelectedData(selectedDataList) {
      const self = this;
      this.selectedItems = selectedDataList;
      this.updateDataTableSelectAllCtrl();
      this.selectedListNames = [];
      this.selectedLists = [];
      selectedDataList.forEach((dataRow) => {
        self.selectedListNames.push(dataRow.name);
        self.selectedLists.push(dataRow.wishlistUid);
      });
    },
    initializeTableConfig() {
      const self = this;
      this.tableConfig = {
        rowCount: 5,
        emptyTable: 'No saved lists found',
        enableSelectAll: true,
        selectAllCallback: this.handleSelectedData,
        columnConfiguration: [
          {
            customHeaderName: this.i18n.shoppingLists.listName,
            customTemplate(data) {
              return `<a href="${globals.getNavBaseUrl()}${
                globals.serviceUrls.listDetailView
              }/?listName=${data.wishlistUid}">${data.name}</a>`;
            },
          },
          {
            customTemplate(data) {
              if (data.modifiedTime) {
                const modifiedDateValue = data.modifiedTime.split('-');
                const modifiedDate = modifiedDateValue[2].split('T');
                const dateValue = `${modifiedDateValue[1]}/${modifiedDate[0]}/${
                  modifiedDateValue[0]
                }`;
                return dateValue;
              }
              return '';
            },
            customHeaderName: this.i18n.shoppingLists.lastUpdated,
          },
          {
            customHeaderName: this.i18n.shoppingLists.noOfProducts,
            customTemplate: (data) => (data.noOfProducts ? self.checkQuantity(data.noOfProducts) : 0),
          }, {
            customHeaderName: this.i18n.shoppingLists.subtotal,
            customTemplate: (data) => {
              if (data.subtotal) {
                const { subtotal } = data;
                return `${subtotal}`;
              }
              const subtotal = '$00.00';
              return `${subtotal}`;
            },
            sortAsNumber: true,
            visibilityCondition: () => (true),
          },
        ],
      };
    },
    checkQuantity(data) {
      if (data) {
        return data;
      }
      return 0;
    },
    updateDataTableSelectAllCtrl() {
      if (this.selectedItems.length === 0) {
        this.enableRemoveLink = false;
      } else {
        this.enableRemoveLink = true;
      }
    },
  },
};
