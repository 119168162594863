import vxApplicationAuth from '../components/common/vx-application-auth/vx-application-auth.vue';
import vxHeaderSection from '../components/common/vx-header-section/vx-header-section.vue';
import vxFlyoutBanner from '../components/common/vx-flyout-banner/vx-flyout-banner.vue';
import vxFooterSection from '../components/common/vx-footer-section/vx-footer-section.vue';
import vxVideoPlayer from '../components/common/vx-video-player/vx-video-player.vue';
import VxSectionTitle from '../components/common/vx-title/vx-title.vue';
import vxRichText from '../components/common/vx-rich-text/vx-rich-text.vue';
import vxJuicerFeed from '../components/common/vx-juicer-feed/vx-juicer-feed.vue';
import vxBreadcrumb from '../components/common/vx-breadcrumb/vx-breadcrumb.vue';
import vxCustomerDetails from '../components/common/vx-customer-details/vx-customer-details.vue';
import vxAssistiveText from '../components/common/vx-assistive-text/vx-assistive-text.vue';
import vxBundles from '../components/common/vx-bundles/vx-bundles.vue';
import emailExtends from '../components/common/vx-extends';
import vxReviewLease from '../components/common/vx-review-lease/vx-review-lease.vue';

import vxMinimizedHeader from '../components/common/vx-minimized-header/vx-minimized-header.vue';
import vxAddToCart from '../components/common/vx-add-to-cart/vx-add-to-cart.vue';
import vxFavoriteBtn from '../components/common/vx-favorite-btn/vx-favorite-btn.vue';
import vxNotifyButton from '../components/common/vx-notify-button/vx-notify-button.vue';
import vxSampleShoppingCart from '../components/common/vx-sample-shopping-cart/vx-sample-shopping-cart.vue';
import vxSampleCartCheckout from '../components/common/vx-sample-cart-checkout/vx-sample-cart-checkout.vue';
import vxPdfGenerator from '../components/common/vx-pdf-generator/vx-pdf-generator.vue';
import vxFlyoutBannerSecondary from '../components/common/vx-flyout-banner-secondary/vx-flyout-banner-secondary.vue';
import vxMarketingModal from '../components/common/vx-marketing-modal/vx-marketing-modal.vue';
import vxMarketingForm from '../components/common/vx-marketing-form/vx-marketing-form.vue';
import vxMarketingAddToCart from '../components/common/vx-marketing-add-to-cart/vx-marketing-add-to-cart.vue';
import initMixin from '../components/common/mixins/init-mixin';
import trackJSMixin from '../components/common/mixins/trackjs-mixin';
import headerHeightAdjustment from '../components/common/mixins/header-height-adjustment-mixin';
import pardotMixin from '../components/common/mixins/pardot-mixin';
import recaptchaMixin from '../components/common/mixins/recaptcha-mixin';
import checkoutTrackingMixin from '../components/common/mixins/checkout-tracking-mixin';
import vxEmbeddedChat from '../components/common/vx-embedded-chat/vx-embedded-chat.vue';

const commonModule = {
  components: {
    vxApplicationAuth,
    vxHeaderSection,
    vxFlyoutBanner,
    vxFooterSection,
    vxVideoPlayer,
    VxSectionTitle,
    vxRichText,
    vxJuicerFeed,
    vxBreadcrumb,
    vxCustomerDetails,
    vxAssistiveText,
    vxBundles,
    vxReviewLease,
    vxMinimizedHeader,
    vxAddToCart,
    vxFavoriteBtn,
    vxNotifyButton,
    vxSampleShoppingCart,
    vxSampleCartCheckout,
    vxPdfGenerator,
    vxFlyoutBannerSecondary,
    emailExtends,
    vxMarketingModal,
    vxMarketingForm,
    vxMarketingAddToCart,
    vxEmbeddedChat,
  },

  mixins: [
    initMixin,
    trackJSMixin,
    headerHeightAdjustment,
    pardotMixin,
    recaptchaMixin,
    checkoutTrackingMixin,
  ],

};

export default commonModule;
