import vxUpsellCarousel from '../components/pdp/vx-upsell-carousel/vx-upsell-carousel.vue';
import vxThumbnailViewer from '../components/pdp/vx-thumbnail-viewer/vx-thumbnail-viewer.vue';
import vxPdpProductInfo from '../components/pdp/vx-pdp-product-info/vx-pdp-product-info.vue';
import vxTabContainer from '../components/pdp/vx-tab-container/vx-tab-container.vue';

const pdpModule = {
  components: {
    vxUpsellCarousel,
    vxThumbnailViewer,
    vxPdpProductInfo,
    vxTabContainer,
  },
};

export default pdpModule;
