var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "header",
      { staticClass: "header-section navbar-fixed-top non-printable-section" },
      [
        _c("vx-spinner", {
          ref: "spinner",
          attrs: {
            image: _vm.globals.assetsPath + "images/spinner.gif",
            "full-screen": ""
          }
        }),
        _vm._v(" "),
        _vm.isTablet() &&
        _vm.headerOptions.promoTxt &&
        _vm.isPromoEnabled &&
        !_vm.headerOptions.isCheckout
          ? _c(
              "div",
              {
                staticClass:
                  "py-xs-2 px-xs-3 px-md-0 promo-section m-xs-0 text-center promo-tablet"
              },
              [
                _vm.headerOptions.promoTxt && _vm.headerOptions.promoUrl
                  ? _c(
                      "a",
                      {
                        attrs: {
                          href: _vm.headerOptions.isExternalLink
                            ? _vm.headerOptions.promoUrl
                            : _vm.globals.getUrlWithContextPath(
                                _vm.headerOptions.promoUrl
                              ),
                          target: _vm.headerOptions.isExternalLink
                            ? "_blank"
                            : ""
                        }
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm.$options.filters.maxCharacters(
                              _vm.headerOptions.promoTxt
                            )
                          )
                        )
                      ]
                    )
                  : _vm.headerOptions.promoTxt
                  ? _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$options.filters.maxCharacters(
                            _vm.headerOptions.promoTxt
                          )
                        )
                      )
                    ])
                  : _vm._e()
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.headerOptions.isCheckout
          ? _c(
              "nav",
              {
                staticClass:
                  "non-printable-section navbar-top d-flex align-items-center justify-content-between",
                class: { "vanity-fair-mobile-nav-color": _vm.showMobileNav },
                attrs: { role: "navigation" }
              },
              [
                !_vm.isTablet() &&
                _vm.headerOptions.promoTxt &&
                _vm.isPromoEnabled
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "py-xs-2 promo-section m-xs-0 ml-md-5 pl-md-2"
                      },
                      [
                        _vm.headerOptions.promoTxt && _vm.headerOptions.promoUrl
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: _vm.headerOptions.isExternalLink
                                    ? _vm.headerOptions.promoUrl
                                    : _vm.globals.getUrlWithContextPath(
                                        _vm.headerOptions.promoUrl
                                      ),
                                  target: _vm.headerOptions.isExternalLink
                                    ? "_blank"
                                    : ""
                                }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.$options.filters.maxCharacters(
                                      _vm.headerOptions.promoTxt
                                    )
                                  )
                                )
                              ]
                            )
                          : _vm.headerOptions.promoTxt
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$options.filters.maxCharacters(
                                    _vm.headerOptions.promoTxt
                                  )
                                )
                              )
                            ])
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "icon-menu ml-xs-3 py-xs-3",
                    attrs: {
                      title: _vm.i18n.iconMenuTitle,
                      tabindex: "0",
                      role: "button",
                      "aria-label": _vm.i18n.iconMenuTitle,
                      href: "javascript:void(0)",
                      "aria-expanded": _vm.showMobileNav ? "true" : "false"
                    },
                    on: {
                      click: function($event) {
                        _vm.showMobileNav = !_vm.showMobileNav
                        _vm.updateAria(_vm.showMobileNav)
                        _vm.setHeaderHeight()
                      }
                    }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Main Navigation")
                    ])
                  ]
                ),
                _vm._v(" "),
                _vm.isHomePage
                  ? [
                      _vm.isTablet()
                        ? _c(
                            "button",
                            {
                              staticClass: "logo",
                              attrs: {
                                itemscope: "",
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0",
                                role: "link",
                                "aria-hidden":
                                  _vm.showMobileNav && _vm.isTablet(),
                                "aria-label": _vm.globals.getSiteName()
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? _c("img", {
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandMobileLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                                : _c("img", {
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src: _vm.unitHeaderLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    ]
                  : [
                      _vm.isTablet()
                        ? _c(
                            "button",
                            {
                              staticClass: "logo",
                              attrs: {
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0",
                                role: "link",
                                "aria-hidden":
                                  _vm.showMobileNav && _vm.isTablet(),
                                "aria-label": _vm.globals.getSiteName()
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? _c("img", {
                                    attrs: {
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      src:
                                        _vm.globals.assetsPath +
                                        _vm.headerOptions.brandMobileLogo,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                                : _c("img", {
                                    attrs: {
                                      src: _vm.unitHeaderLogo,
                                      itemprop:
                                        _vm.globals.logoContentSchema.itemProp,
                                      alt: _vm.globals.getSiteName()
                                    }
                                  })
                            ]
                          )
                        : _vm._e()
                    ],
                _vm._v(" "),
                _vm.globals.siteConfig.isReferAFriend
                  ? _c("span", { attrs: { id: "extole_zone_global_header" } })
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "ul",
                  {
                    staticClass: "py-xs-2 login-menu m-xs-0",
                    attrs: { role: "presentation" }
                  },
                  [
                    _vm.findMenu &&
                    (_vm.globals.siteConfig.isFindADistributorEnabled ||
                      _vm.globals.siteConfig.isFindAStoreEnabled)
                      ? _c("li", [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: _vm.findMenu.external
                                  ? _vm.findMenu.optionLink
                                  : _vm.globals.contextPath +
                                    _vm.findMenu.optionLink
                              }
                            },
                            [_vm._v(_vm._s(_vm.findMenu.option))]
                          )
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _vm.globals.getIsLoggedIn()
                  ? _c(
                      "ul",
                      {
                        staticClass: "py-xs-2 login-menu m-xs-0",
                        attrs: { role: "presentation" }
                      },
                      [
                        _vm.b2bUnitProfilesList && _vm.globals.isEStores()
                          ? _c(
                              "li",
                              [
                                _c("vx-dropdown-primary", {
                                  ref: "b2bUnitsDropdown",
                                  attrs: {
                                    dropdownValues: _vm.b2bUnitProfilesList,
                                    title: _vm.i18n.userProfile
                                  },
                                  on: {
                                    "selected-item": function($event) {
                                      _vm.handleSelectedUnit($event)
                                    },
                                    primaryDropdownMounted: function($event) {
                                      _vm.handleDefaultUnit()
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm._l(_vm.loginMenu, function(item, index) {
                          return _c("li", [
                            index === 0 && !_vm.globals.isEStores()
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.external
                                        ? item.optionLink
                                        : _vm.globals.contextPath +
                                          item.optionLink
                                    }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.i18n.hiText) +
                                        _vm._s(_vm.userName)
                                    )
                                  ]
                                )
                              : index === 0 && _vm.globals.isEStores()
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.external
                                        ? item.optionLink
                                        : _vm.globals.contextPath +
                                          item.optionLink
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.loginSectionTitle))]
                                )
                              : _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.external
                                        ? item.optionLink
                                        : _vm.globals.contextPath +
                                          item.optionLink
                                    }
                                  },
                                  [_vm._v(_vm._s(item.option))]
                                )
                          ])
                        })
                      ],
                      2
                    )
                  : _c(
                      "ul",
                      {
                        staticClass: "py-xs-2 login-menu m-xs-0",
                        attrs: { role: "presentation" }
                      },
                      [
                        _vm.headerOptions.showLoginRegister
                          ? _vm._l(_vm.loginMenu, function(item) {
                              return _c("li", [
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: item.external
                                        ? item.optionLink
                                        : _vm.globals.contextPath +
                                          item.optionLink
                                    }
                                  },
                                  [_vm._v(_vm._s(item.option))]
                                )
                              ])
                            })
                          : _vm._l(_vm.loginMenu, function(item) {
                              return !(
                                ~item.optionLink.indexOf("login") ||
                                ~item.optionLink.indexOf("register")
                              )
                                ? _c("li", [
                                    _c(
                                      "a",
                                      {
                                        attrs: {
                                          href: item.external
                                            ? item.optionLink
                                            : _vm.globals.contextPath +
                                              item.optionLink
                                        }
                                      },
                                      [_vm._v(_vm._s(item.option))]
                                    )
                                  ])
                                : _vm._e()
                            })
                      ],
                      2
                    ),
                _vm._v(" "),
                _c("div", { staticClass: "profile-mobile-dropdown" }, [
                  _vm.b2bUnitProfilesList &&
                  _vm.globals.isEStores() &&
                  _vm.isTablet()
                    ? _c(
                        "div",
                        [
                          _c("vx-dropdown-primary", {
                            ref: "b2bUnitsDropdown",
                            attrs: { dropdownValues: _vm.b2bUnitProfilesList },
                            on: {
                              "selected-item": function($event) {
                                _vm.handleSelectedUnit($event)
                              },
                              primaryDropdownMounted: function($event) {
                                _vm.handleDefaultUnit()
                              }
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "a",
                            {
                              staticClass: "icon-user-wrapper",
                              attrs: {
                                href:
                                  _vm.globals.contextPath +
                                  _vm.loginMenu[0].optionLink,
                                "aria-label": _vm.i18n.iconUserTitle,
                                "aria-hidden":
                                  _vm.showMobileNav && _vm.isTablet()
                              }
                            },
                            [
                              _c("span", {
                                staticClass: "icon-user mr-xs-3 py-xs-3",
                                attrs: { title: _vm.i18n.iconUserTitle }
                              })
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "nav",
          {
            staticClass:
              "navbar-bottom d-flex align-items-center justify-content-between",
            class: { "checkout-header": _vm.headerOptions.isCheckout },
            attrs: { role: "navigation" }
          },
          [
            _c(
              "div",
              { staticClass: "header-left-section d-flex align-items-center" },
              [
                _vm.isHomePage
                  ? [
                      !_vm.tabletView || _vm.headerOptions.isCheckout
                        ? _c(
                            "div",
                            {
                              staticClass: "logo",
                              attrs: {
                                itemscope: "",
                                itemtype:
                                  _vm.globals.logoContentSchema.itemType,
                                tabindex: "0"
                              },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? [
                                    _c("img", {
                                      attrs: {
                                        src:
                                          _vm.globals.assetsPath +
                                          _vm.headerOptions.brandMobileLogo,
                                        itemprop:
                                          _vm.globals.logoContentSchema
                                            .itemProp,
                                        alt: _vm.globals.getSiteName()
                                      }
                                    })
                                  ]
                                : [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _vm.unitHeaderLogo
                                      ? _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src: _vm.unitHeaderLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            itemprop:
                                              _vm.globals.logoContentSchema
                                                .itemProp,
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  : [
                      !_vm.tabletView || _vm.headerOptions.isCheckout
                        ? _c(
                            "div",
                            {
                              staticClass: "logo",
                              attrs: { tabindex: "0" },
                              on: {
                                click: function($event) {
                                  _vm.globals.navigateToUrl("home")
                                }
                              }
                            },
                            [
                              _vm.globals.siteConfig.isBrandLogoMobile &&
                              _vm.isTablet()
                                ? [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandMobileLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                                : [
                                    _vm.globals.siteConfig.showCheckoutLogo &&
                                    _vm.headerOptions.isCheckout
                                      ? _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.checkoutLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _vm.unitHeaderLogo
                                      ? _c("img", {
                                          attrs: {
                                            src: _vm.unitHeaderLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                      : _c("img", {
                                          attrs: {
                                            src:
                                              _vm.globals.assetsPath +
                                              _vm.headerOptions.brandLogo,
                                            alt: _vm.globals.getSiteName()
                                          }
                                        })
                                  ]
                            ],
                            2
                          )
                        : _vm._e()
                    ],
                _vm._v(" "),
                !_vm.headerOptions.isCheckout
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "non-printable-section navbar-bottom-left d-flex pl-xs-0 pl-md-4",
                        class: { "mobile-view": _vm.showMobileNav },
                        attrs: {
                          "aria-expanded": _vm.showMobileNav ? "true" : "false"
                        },
                        on: { click: _vm.dismissMobileMenu }
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "navigationMenu",
                            staticClass:
                              "navigation-menu d-flex align-items-center pt-xs-3 pt-md-0",
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                              }
                            }
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "navigation-inner-wrapper" },
                              [
                                _vm.showMobileNav
                                  ? _c(
                                      "div",
                                      { staticClass: "pb-xs-2 pb-md-0" },
                                      [_vm._m(0)]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "pb-xs-2 pb-md-0" }, [
                                  _c(
                                    "ul",
                                    {
                                      staticClass:
                                        "m-xs-0 primary-menu align-self-center d-flex pl-xs-0"
                                    },
                                    _vm._l(_vm.navMenu, function(item, index) {
                                      return _c(
                                        "li",
                                        {
                                          staticClass:
                                            "primary-menu-item js-primary-menu-item p-md-3"
                                        },
                                        [
                                          item.primary
                                            ? [
                                                _vm.showMobileNav &&
                                                _vm.isSecondaryAvailable(item)
                                                  ? _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.selectedPrimary ===
                                                              "",
                                                            expression:
                                                              "selectedPrimary === ''"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "p-xs-3 p-md-0",
                                                        attrs: { href: "#" },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.selectPrimary(
                                                              item.primary
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.primary
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                                  : _c(
                                                      "a",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "show",
                                                            rawName: "v-show",
                                                            value:
                                                              _vm.selectedPrimary ===
                                                              "",
                                                            expression:
                                                              "selectedPrimary === ''"
                                                          }
                                                        ],
                                                        staticClass:
                                                          "p-xs-3 p-md-0",
                                                        attrs: {
                                                          href: item.external
                                                            ? item.primaryLink
                                                            : _vm.globals
                                                                .contextPath +
                                                              item.primaryLink
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              item.primary
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    )
                                              ]
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          !_vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu pt-xs-5 px-md-6 pb-md-5",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category"
                                                        },
                                                        [
                                                          _vm._l(
                                                            _vm.returnItemsImages(
                                                              secondaryItem.menuOptions
                                                            ),
                                                            function(image) {
                                                              return _c(
                                                                "a",
                                                                {
                                                                  attrs: {
                                                                    href:
                                                                      image.link,
                                                                    target: image.isExternalLink
                                                                      ? "_blank"
                                                                      : "_self"
                                                                  }
                                                                },
                                                                [
                                                                  _c("img", {
                                                                    staticClass:
                                                                      "featured-image pl-xs-3",
                                                                    attrs: {
                                                                      src:
                                                                        image.imageSrcD,
                                                                      alt:
                                                                        image.imageSrcAltTextD
                                                                    }
                                                                  })
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          _vm._v(" "),
                                                          secondaryItem.title
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title js-secondary-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role:
                                                                  "presentation"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self",
                                                                                tabindex:
                                                                                  "0"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.isSecondaryAvailable(item) &&
                                          _vm.selectedPrimary ===
                                            item.primary &&
                                            _vm.isTablet()
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "secondary-menu mobile pt-xs-3 px-md-4 pt-md-4 pb-md-2",
                                                  class: {
                                                    "secondary-active":
                                                      _vm.selectedPrimary ===
                                                      item.primary
                                                  }
                                                },
                                                [
                                                  _c("div", {
                                                    staticClass: "arrow-up"
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.showMobileNav
                                                    ? _c("a", {
                                                        staticClass:
                                                          "icon-chevron-left ml-xs-3 px-xs-0 js-secondary-action-el",
                                                        attrs: {
                                                          tabindex: "0",
                                                          role: "button",
                                                          "aria-label": "Back",
                                                          href:
                                                            "javascript:void(0)"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            $event.stopPropagation()
                                                            _vm.focusParent(
                                                              _vm.selectedPrimary,
                                                              _vm.navMenu
                                                            )
                                                          }
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm._l(
                                                    item.secondaryList,
                                                    function(secondaryItem) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "secondary-category"
                                                        },
                                                        [
                                                          secondaryItem.title
                                                            ? [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "pl-xs-3 sec-title",
                                                                  domProps: {
                                                                    innerHTML: _vm._s(
                                                                      secondaryItem.title
                                                                    )
                                                                  }
                                                                })
                                                              ]
                                                            : _vm._e(),
                                                          _vm._v(" "),
                                                          _c(
                                                            "ul",
                                                            {
                                                              staticClass:
                                                                "d-flex flex-wrap pl-xs-0 flyout-sec",
                                                              attrs: {
                                                                role:
                                                                  "presentation"
                                                              }
                                                            },
                                                            _vm._l(
                                                              secondaryItem.menuOptions,
                                                              function(item) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "js-secondary-child p-md-3",
                                                                    class: {
                                                                      "view-all":
                                                                        item.markAsViewAll
                                                                    }
                                                                  },
                                                                  [
                                                                    item.option
                                                                      ? [
                                                                          _c(
                                                                            "a",
                                                                            {
                                                                              staticClass:
                                                                                "p-xs-3 p-md-0 custom-word-wrap-all",
                                                                              attrs: {
                                                                                href: item.external
                                                                                  ? item.optionLink
                                                                                  : _vm
                                                                                      .globals
                                                                                      .contextPath +
                                                                                    item.optionLink,
                                                                                target: item.external
                                                                                  ? "_blank"
                                                                                  : "_self",
                                                                                tabindex:
                                                                                  "0"
                                                                              },
                                                                              domProps: {
                                                                                innerHTML: _vm._s(
                                                                                  item.option
                                                                                )
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e()
                                                                  ],
                                                                  2
                                                                )
                                                              }
                                                            )
                                                          )
                                                        ],
                                                        2
                                                      )
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    })
                                  )
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.selectedPrimary === "",
                                        expression: "selectedPrimary === ''"
                                      }
                                    ]
                                  },
                                  [
                                    _vm.showMobileNav &&
                                    _vm.globals.getIsLoggedIn()
                                      ? _vm._t("left-nav", [
                                          _vm._v(
                                            "\n                  Left Nav\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.showMobileNav
                                  ? _c(
                                      "div",
                                      { staticClass: "pb-xs-2 pb-md-0" },
                                      [
                                        !_vm.globals.getIsLoggedIn() &&
                                        _vm.globals.siteConfig
                                          .isMobileMyProfileEnabled
                                          ? _c(
                                              "h6",
                                              {
                                                staticClass:
                                                  "my-account-text pl-xs-3 pt-xs-2",
                                                attrs: {
                                                  "aria-hidden":
                                                    _vm.selectedPrimary !== ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.i18n.loginSectionTitle
                                                    ) +
                                                    "\n                "
                                                )
                                              ]
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "ul",
                                          {
                                            staticClass: "pl-xs-0",
                                            class: {
                                              "login-mobile-view":
                                                _vm.showMobileNav
                                            },
                                            attrs: {
                                              "aria-hidden":
                                                _vm.selectedPrimary !== ""
                                            }
                                          },
                                          [
                                            _vm.headerOptions.showLoginRegister
                                              ? _vm._l(_vm.loginMenu, function(
                                                  item,
                                                  index
                                                ) {
                                                  return _c(
                                                    "li",
                                                    {
                                                      staticClass:
                                                        "p-md-3 js-primary-menu-item"
                                                    },
                                                    [
                                                      _c(
                                                        "a",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.selectedPrimary ===
                                                                "",
                                                              expression:
                                                                "selectedPrimary === ''"
                                                            }
                                                          ],
                                                          staticClass:
                                                            "p-xs-3 p-md-0 custom-word-wrap-all",
                                                          attrs: {
                                                            href: item.external
                                                              ? item.optionLink
                                                              : _vm.globals
                                                                  .contextPath +
                                                                item.optionLink,
                                                            target: item.external
                                                              ? "_blank"
                                                              : "_self"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                        " +
                                                              _vm._s(
                                                                item.option
                                                              ) +
                                                              "\n                      "
                                                          )
                                                        ]
                                                      )
                                                    ]
                                                  )
                                                })
                                              : _vm._l(_vm.loginMenu, function(
                                                  item
                                                ) {
                                                  return !(
                                                    ~item.optionLink.indexOf(
                                                      "login"
                                                    ) ||
                                                    ~item.optionLink.indexOf(
                                                      "register"
                                                    )
                                                  )
                                                    ? _c(
                                                        "li",
                                                        {
                                                          staticClass:
                                                            "p-md-3 js-primary-menu-item"
                                                        },
                                                        [
                                                          _c("a", {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value:
                                                                  _vm.selectedPrimary ===
                                                                  "",
                                                                expression:
                                                                  "selectedPrimary === ''"
                                                              }
                                                            ],
                                                            staticClass:
                                                              "p-xs-3 p-md-0 custom-word-wrap-all",
                                                            attrs: {
                                                              href: item.external
                                                                ? item.optionLink
                                                                : _vm.globals
                                                                    .contextPath +
                                                                  item.optionLink,
                                                              target: item.external
                                                                ? "_blank"
                                                                : "_self"
                                                            },
                                                            domProps: {
                                                              innerHTML: _vm._s(
                                                                item.option
                                                              )
                                                            }
                                                          })
                                                        ]
                                                      )
                                                    : _vm._e()
                                                })
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _vm.findMenu &&
                                        (_vm.globals.siteConfig
                                          .isFindADistributorEnabled ||
                                          _vm.globals.siteConfig
                                            .isFindAStoreEnabled)
                                          ? _c(
                                              "ul",
                                              {
                                                staticClass: "pl-xs-0",
                                                class: {
                                                  "login-mobile-view":
                                                    _vm.showMobileNav
                                                },
                                                attrs: {
                                                  role: "presentation",
                                                  "aria-hidden":
                                                    _vm.selectedPrimary !== ""
                                                }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "p-md-3 js-primary-menu-item"
                                                  },
                                                  [
                                                    _c("a", {
                                                      staticClass:
                                                        "p-xs-3 p-md-0 custom-word-wrap-all",
                                                      attrs: {
                                                        href: _vm.findMenu
                                                          .external
                                                          ? _vm.findMenu
                                                              .optionLink
                                                          : _vm.globals
                                                              .contextPath +
                                                            _vm.findMenu
                                                              .optionLink,
                                                        target: _vm.findMenu
                                                          .external
                                                          ? "_blank"
                                                          : "_self"
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.findMenu.option
                                                        )
                                                      }
                                                    })
                                                  ]
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c("div", { staticClass: "pb-xs-2 pb-md-0" })
                              ]
                            )
                          ]
                        )
                      ]
                    )
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            !_vm.headerOptions.isCheckout
              ? _c(
                  "div",
                  {
                    staticClass:
                      "non-printable-section d-flex navbar-bottom-right justify-content-between align-items-center",
                    attrs: {
                      "aria-hidden": _vm.showMobileNav && _vm.isTablet()
                    }
                  },
                  [
                    _vm.headerOptions.includeSearchBox
                      ? _c(
                          "div",
                          {
                            class: {
                              "align-search": !_vm.headerOptions.includeMiniCart
                            }
                          },
                          [
                            _vm._t(
                              "search",
                              [
                                _vm._v("\n            Default body\n          ")
                              ],
                              { toggleSearchIcon: _vm.toggleSearchIcon }
                            )
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _vm.headerData.headerOptions.showMyList &&
                      _vm.globals.isB2B()
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "primary-menu-items  p-md-3 d-flex align-items-center justify-content-end pl-xs-2"
                            },
                            [
                              _c(
                                "a",
                                {
                                  staticClass: "ml-md-5",
                                  attrs: {
                                    title: _vm.i18n.myLists,
                                    tabindex: "0"
                                  },
                                  on: {
                                    click: function($event) {
                                      _vm.navigateTomyLists()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { attrs: { "aria-hidden": "true" } },
                                    [_vm._v(_vm._s(_vm.i18n.myLists))]
                                  )
                                ]
                              )
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.headerData.headerOptions.showMyList &&
                      _vm.globals.isB2C()
                        ? _c("div", [
                            _vm.isFavoritesEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary-menu-items  p-md-3 d-flex align-items-center justif18ny-content-end pl-xs-3"
                                  },
                                  [
                                    _vm.globals.siteConfig.showFavIcon
                                      ? [
                                          _c("a", {
                                            staticClass: "icon-heart ml-md-5",
                                            attrs: {
                                              href: _vm.globals.getNavigationUrl(
                                                "myFav"
                                              ),
                                              "aria-label": _vm.i18n.myFav,
                                              tabindex: "0"
                                            }
                                          })
                                        ]
                                      : [
                                          _c(
                                            "a",
                                            {
                                              staticClass: "ml-md-5",
                                              attrs: {
                                                href: _vm.globals.getNavigationUrl(
                                                  "myFav"
                                                ),
                                                title: _vm.i18n.myFav,
                                                tabindex: "0"
                                              }
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(_vm.i18n.myFav))
                                              ])
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isFavoritesEnabled
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "primary-menu-items  p-md-3 d-flex align-items-center justify-content-end pl-xs-3 pr-xs-3"
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "ml-md-5",
                                        attrs: {
                                          title: _vm.i18n.myLists,
                                          tabindex: "0"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.navigateTomyLists()
                                          }
                                        }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(_vm.i18n.myLists))
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e()
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-end"
                        },
                        [
                          _vm.globals.isB2B() &&
                          _vm.headerOptions.showQuickOrder
                            ? _c(
                                "a",
                                {
                                  staticClass: "quick-order ml-md-5",
                                  attrs: {
                                    href: _vm.globals.getNavigationUrl(
                                      "quickOrder"
                                    ),
                                    "aria-label": _vm.i18n.iconQuickOrderTitle
                                  }
                                },
                                [
                                  _c("span", {
                                    staticClass: "icon-quick-order",
                                    attrs: {
                                      title: _vm.i18n.iconQuickOrderTitle
                                    }
                                  })
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.headerOptions.includeMiniCart
                            ? _c(
                                "div",
                                {
                                  class: _vm.globals.isB2B()
                                    ? "cart ml-xs-1 ml-sm-5 ml-md-5"
                                    : "cart ml-xs-4 ml-sm-5 ml-md-5"
                                },
                                [
                                  _c("a", {
                                    staticClass: "icon-cart",
                                    attrs: {
                                      href: _vm.globals.getNavigationUrl(
                                        "cart"
                                      ),
                                      "aria-label":
                                        _vm.i18n.cartWith +
                                        _vm.cartItems +
                                        _vm.i18n.items
                                    }
                                  }),
                                  _vm._v(" "),
                                  _vm.showMiniCart
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "header-mini-cart hidden-xs hidden-sm visible-md visible-lg",
                                          on: {
                                            mouseover: function($event) {
                                              _vm.isMiniCartHovered = true
                                            },
                                            mouseleave: function($event) {
                                              _vm.isMiniCartHovered = false
                                            }
                                          }
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "arrow-up"
                                          }),
                                          _vm._v(" "),
                                          _vm._t("cart", [
                                            _vm._v(
                                              "\n                  Default body\n                "
                                            )
                                          ])
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.cartItems
                                    ? _c(
                                        "span",
                                        {
                                          staticClass: "badge",
                                          on: {
                                            click: function($event) {
                                              _vm.globals.navigateToUrl("cart")
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.cartItems))]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "assistive-text",
                                      attrs: {
                                        "aria-live": "assertive",
                                        "aria-atomic": "true"
                                      }
                                    },
                                    [
                                      _vm.cartItems
                                        ? _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.i18n.cartItemsText) +
                                                " " +
                                                _vm._s(_vm.cartItems) +
                                                "\n                "
                                            )
                                          ])
                                        : _vm._e()
                                    ]
                                  )
                                ]
                              )
                            : _vm._e()
                        ]
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.headerOptions.isCheckout
              ? _c(
                  "div",
                  {
                    staticClass:
                      "checkout-options d-flex justify-content-end pr-md-4"
                  },
                  _vm._l(_vm.checkoutMenu.menuOptions, function(item) {
                    return _c("a", {
                      staticClass: "px-xs-3 py-xs-3",
                      attrs: {
                        href: item.external
                          ? item.optionLink
                          : _vm.globals.contextPath + item.optionLink
                      },
                      domProps: { innerHTML: _vm._s(item.option) }
                    })
                  })
                )
              : _vm._e()
          ]
        ),
        _vm._v(" "),
        _vm.i18n.clarCartModal
          ? _c(
              "vx-modal",
              {
                ref: "profileSwitchModal",
                attrs: {
                  size: "medium",
                  heading: _vm.i18n.clarCartModal.warningText
                }
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "profileSwitchModal",
                    attrs: { slot: "component" },
                    slot: "component"
                  },
                  [
                    _c("p", [
                      _vm._v(_vm._s(_vm.i18n.clarCartModal.cartClearMessage))
                    ]),
                    _vm._v(" "),
                    _c("div", {}, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn button-color text-trans modal-btn-primary-medium modal-btn-xs-sticky",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              _vm.clearCart($event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.clarCartModal.continueBtnText))]
                      )
                    ])
                  ]
                )
              ]
            )
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "printable-section printable-image" }, [
      _vm.globals.siteConfig.isBrandLogoMobile && _vm.isTablet()
        ? _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandMobileLogo
            }
          })
        : _vm.unitHeaderLogo
        ? _c("img", {
            attrs: { alt: _vm.globals.getSiteName(), src: _vm.unitHeaderLogo }
          })
        : _c("img", {
            attrs: {
              alt: _vm.globals.getSiteName(),
              src: _vm.globals.assetsPath + _vm.headerOptions.brandLogo
            }
          })
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "ul",
      { staticClass: "m-xs-0 primary-menu align-self-center d-flex pl-xs-0" },
      [
        _c(
          "li",
          { staticClass: "primary-menu-item js-primary-menu-item p-md-3" },
          [
            _c("a", { staticClass: "p-xs-3 p-md-0", attrs: { href: "/" } }, [
              _vm._v("\n                      Home\n                    ")
            ])
          ]
        )
      ]
    )
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-93a42586", { render: render, staticRenderFns: staticRenderFns })
  }
}