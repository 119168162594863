var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-create-account-estores" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("h1", { staticClass: "register-heading mt-xs-6 mb-xs-2" }, [
        _vm._v(_vm._s(_vm.i18n.ecommerceProfileHeader))
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "register-informational mb-xs-4" }, [
        _vm._v(
          _vm._s(_vm.i18n.ecommerceProfileInformational) +
            "\n    " +
            _vm._s(_vm.i18n.ecommerceProfileInformationalPhone) +
            _vm._s(_vm.i18n.ecommerceProfileInformational2) +
            "\n    "
        ),
        _c(
          "a",
          {
            attrs: {
              href: "mailto:" + _vm.i18n.ecommerceProfileInformationalLink
            }
          },
          [_vm._v(_vm._s(_vm.i18n.ecommerceProfileInformational3))]
        ),
        _vm._v(_vm._s(_vm.i18n.ecommerceProfileInformational4) + "\n  ")
      ]),
      _vm._v(" "),
      _c("form", [
        _c("div", { staticClass: "company-info mb-xs-4" }, [
          _vm._v(_vm._s(_vm.i18n.companyInformationText))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: {
                for: "profileName",
                "aria-hidden": _vm.isDeviceAndroid()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.profileName))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.profileName,
                expression: "user.profileName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true, regex: /^[0-9-A-z]*$/ },
                expression: "{ required: true, regex:/^[0-9-A-z]*$/}"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("profileName") },
            attrs: {
              type: "text",
              maxlength: "40",
              name: "profileName",
              "aria-label": _vm.i18n.profileName,
              "aria-describedby": "register_profile_error",
              disabled: _vm.prePopulateProfileName
            },
            domProps: { value: _vm.user.profileName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "profileName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("profileName"),
                  expression: "errors.has('profileName')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("profileName") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_profile_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("profileName")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: {
                for: "companyName",
                "aria-hidden": _vm.isDeviceAndroid()
              }
            },
            [_vm._v(_vm._s(_vm.i18n.companyName))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.companyName,
                expression: "user.companyName"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("companyName") },
            attrs: {
              type: "text",
              maxlength: "40",
              name: "companyName",
              "aria-label": _vm.i18n.companyName,
              "aria-describedby": "register_company_error"
            },
            domProps: { value: _vm.user.companyName },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "companyName", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("companyName"),
                  expression: "errors.has('companyName')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("companyName") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_company_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("companyName")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "work-info mt-xs-2 mb-xs-4" }, [
          _vm._v(_vm._s(_vm.i18n.workInformationText))
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 mb-xs-4 form-group col-sm-6 px-xs-0 pl-sm-0 pr-sm-3"
            },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "firstName",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.firstName))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.firstName,
                    expression: "user.firstName"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("firstName") },
                attrs: {
                  type: "text",
                  name: "firstName",
                  maxlength: "35",
                  "aria-label": _vm.i18n.firstName,
                  "aria-describedby": "register_firstname_error"
                },
                domProps: { value: _vm.user.firstName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "firstName", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("firstName"),
                      expression: "errors.has('firstName')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("firstName") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "register_firstname_error"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("firstName")))]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 mb-xs-4 form-group col-sm-6 pr-sm-0 px-xs-0 pl-sm-3"
            },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "lastName",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.lastName))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.lastName,
                    expression: "user.lastName"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("lastName") },
                attrs: {
                  type: "text",
                  name: "lastName",
                  maxlength: "35",
                  "aria-label": _vm.i18n.lastName,
                  "aria-describedby": "register_lastname_error"
                },
                domProps: { value: _vm.user.lastName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "lastName", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("lastName"),
                      expression: "errors.has('lastName')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("lastName") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "register_lastname_error"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("lastName")))]
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 mb-xs-4 form-group col-sm-6 px-xs-0 pl-sm-0 pr-sm-3"
            },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "emailAddress",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.emailAddress))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.emailAddress,
                    expression: "user.emailAddress"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "email|required",
                    expression: "'email|required'"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("emailAddress") },
                attrs: {
                  type: "text",
                  name: "emailAddress",
                  maxlength: "80",
                  "aria-label": _vm.i18n.emailAddress,
                  "aria-describedby": "register_emailadd_error"
                },
                domProps: { value: _vm.user.emailAddress },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "emailAddress", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("emailAddress"),
                      expression: "errors.has('emailAddress')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("emailAddress") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "register_emailadd_error"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("emailAddress")))]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "col-xs-12 mb-xs-4 form-group col-sm-6 px-xs-0 pl-sm-0 pl-sm-3"
            },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "confirmEmail",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.confirmEmail))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.confirmEmail,
                    expression: "user.confirmEmail"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { required: true },
                    expression: "{ required: true }"
                  }
                ],
                staticClass: "form-control",
                class: {
                  "input-error":
                    _vm.errors.has("confirmEmail") ||
                    (_vm.isEmailMismatch && _vm.user.confirmEmail.length)
                },
                attrs: {
                  type: "text",
                  name: "confirmEmail",
                  maxlength: "80",
                  "aria-label": _vm.i18n.confirmEmail,
                  "aria-describedby": "register_emailadd_error"
                },
                domProps: { value: _vm.user.confirmEmail },
                on: {
                  paste: _vm.handlePasteAction,
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "confirmEmail", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("confirmEmail")
                ? _c(
                    "div",
                    {
                      staticClass: "mt-xs-2",
                      attrs: { "aria-hidden": !_vm.errors.has("confirmEmail") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "register_emailadd_error"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("confirmEmail")))]
                      )
                    ]
                  )
                : _c("div", { staticClass: "mt-xs-2" }, [
                    _vm.isEmailMismatch && _vm.user.confirmEmail.length
                      ? _c("span", { staticClass: "error-msg" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.i18n.confirmEmailMismatchError) +
                              "\n          "
                          )
                        ])
                      : _vm._e()
                  ])
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
          _c(
            "div",
            { staticClass: "mb-xs-4 form-group col-sm-10 col-xs-8 pl-xs-0" },
            [
              _c(
                "label",
                {
                  attrs: { for: "phone", "aria-hidden": _vm.isDeviceAndroid() }
                },
                [_vm._v(_vm._s(_vm.i18n.phoneNumber))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "mask",
                    rawName: "v-mask",
                    value: "+1 ###-###-####",
                    expression: "'+1 ###-###-####'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.phoneNumber,
                    expression: "user.phoneNumber"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required:true|min:10",
                    expression: "'required:true|min:10'"
                  }
                ],
                staticClass: "phone-number form-control",
                class: { "input-error": _vm.errors.has("phoneNumber") },
                attrs: {
                  masked: _vm.masked,
                  type: "tel",
                  name: "phoneNumber",
                  "aria-label": _vm.i18n.phoneNumber,
                  "aria-describedby": "register_phone_error"
                },
                domProps: { value: _vm.user.phoneNumber },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "phoneNumber", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("phoneNumber"),
                      expression: "errors.has('phoneNumber')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("phoneNumber") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "register_phone_error"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("phoneNumber")))]
                  )
                ]
              )
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-xs-4 form-group col-sm-2 col-xs-4 pr-xs-0" },
            [
              _c(
                "label",
                { attrs: { for: "ext", "aria-hidden": _vm.isDeviceAndroid() } },
                [_vm._v(_vm._s(_vm.i18n.ext))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { regex: /^[0-9]*$/ },
                    expression: "{ regex:/^[0-9]*$/}"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.user.ext,
                    expression: "user.ext"
                  }
                ],
                staticClass: "form-control ext-phone-number",
                class: { "input-error": _vm.errors.has("ext") },
                attrs: {
                  type: "text",
                  name: "ext",
                  maxlength: "60",
                  "aria-label": _vm.i18n.ext,
                  "aria-describedby": "register_ext_error"
                },
                domProps: { value: _vm.user.ext },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.user, "ext", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("ext"),
                      expression: "errors.has('ext')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-hidden": !_vm.errors.has("ext") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { id: "register_ext_error" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("ext")))]
                  )
                ]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "jobTitle", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.jobTitle))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.jobTitle,
                expression: "user.jobTitle"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("jobTitle") },
            attrs: {
              type: "text",
              maxlength: "80",
              name: "jobTitle",
              "aria-label": _vm.i18n.jobTitle,
              "aria-describedby": "register_jobtitle_error"
            },
            domProps: { value: _vm.user.jobTitle },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "jobTitle", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("jobTitle"),
                  expression: "errors.has('jobTitle')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("jobTitle") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_jobtitle_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("jobTitle")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "address1", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.address1))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.address1,
                expression: "user.address1"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("address1") },
            attrs: {
              id: "autocompleteEstores",
              type: "text",
              name: "address1",
              maxlength: "60",
              "aria-label": _vm.i18n.address1,
              "aria-describedby": "register_address1_error"
            },
            domProps: { value: _vm.user.address1 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "address1", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("address1"),
                  expression: "errors.has('address1')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("address1") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_address1_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("address1")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            {
              attrs: { for: "address2", "aria-hidden": _vm.isDeviceAndroid() }
            },
            [_vm._v(_vm._s(_vm.i18n.address2))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.address2,
                expression: "user.address2"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("address2") },
            attrs: {
              type: "text",
              name: "address2",
              maxlength: "40",
              "aria-label": _vm.i18n.address2,
              "aria-describedby": "register_address2_error"
            },
            domProps: { value: _vm.user.address2 },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "address2", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("address2"),
                  expression: "errors.has('address2')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("address2") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: { id: "register_address2_error" }
                },
                [_vm._v(_vm._s(_vm.errors.first("address2")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-4 form-group" }, [
          _c(
            "label",
            { attrs: { for: "city", "aria-hidden": _vm.isDeviceAndroid() } },
            [_vm._v(_vm._s(_vm.i18n.city))]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.user.city,
                expression: "user.city"
              },
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            staticClass: "form-control",
            class: { "input-error": _vm.errors.has("city") },
            attrs: {
              type: "text",
              name: "city",
              maxlength: "40",
              "aria-label": _vm.i18n.city,
              "aria-describedby": "register_city_error"
            },
            domProps: { value: _vm.user.city },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.user, "city", $event.target.value)
              }
            }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("city"),
                  expression: "errors.has('city')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("city") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_city_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("city")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mb-xs-4 form-group dropdown col-sm-6 col-xs-12 px-xs-0 pl-sm-0 pr-sm-3"
          },
          [
            _c(
              "label",
              { attrs: { for: "country", "aria-hidden": !_vm.isTablet() } },
              [_vm._v(_vm._s(_vm.i18n.state))]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("vx-dropdown-primary", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true },
                  expression: "{ required: true }"
                }
              ],
              ref: "stateDropdown",
              attrs: {
                "describedby-aria": _vm.errors.has("state")
                  ? "estores_state_error_msg"
                  : null,
                "required-aria": "true",
                dropdownValues: _vm.regionList.options,
                "aria-label": _vm.i18n.stateText,
                name: "state",
                dropdownError: _vm.errors.has("state")
              },
              on: {
                "selected-option": function($event) {
                  _vm.user.state = $event
                }
              },
              model: {
                value: _vm.user.state,
                callback: function($$v) {
                  _vm.$set(_vm.user, "state", $$v)
                },
                expression: "user.state"
              }
            }),
            _vm._v(" "),
            _vm.errors.has("state")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "estores_state_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("state")))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "mb-xs-4 form-group col-xs-12 col-sm-6 px-xs-0 pr-sm-0 pl-sm-3"
          },
          [
            _c(
              "label",
              {
                attrs: { for: "zipCode", "aria-hidden": _vm.isDeviceAndroid() }
              },
              [_vm._v(_vm._s(_vm.i18n.zipCode))]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.user.zipCode,
                  expression: "user.zipCode"
                },
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true },
                  expression: "{ required: true }"
                }
              ],
              staticClass: "form-control",
              class: { "input-error": _vm.errors.has("zipCode") },
              attrs: {
                type: "text",
                name: "zipCode",
                "aria-label": _vm.i18n.zipCode,
                maxlength: "60",
                "aria-describedby": "register_zipcode_error"
              },
              domProps: { value: _vm.user.zipCode },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.user, "zipCode", $event.target.value)
                }
              }
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("zipCode"),
                    expression: "errors.has('zipCode')"
                  }
                ],
                staticClass: "mt-xs-2",
                attrs: { "aria-hidden": !_vm.errors.has("zipCode") }
              },
              [
                _c(
                  "span",
                  {
                    staticClass: "error-msg",
                    attrs: {
                      "aria-live": "polite",
                      role: "alert",
                      id: "register_zipcode_error"
                    }
                  },
                  [_vm._v(_vm._s(_vm.errors.first("zipCode")))]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-xs-12 mb-xs-4 px-xs-0 form-group dropdown" },
          [
            _c(
              "label",
              { attrs: { for: "country", "aria-hidden": !_vm.isTablet() } },
              [_vm._v(_vm._s(_vm.i18n.country))]
            ),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("vx-dropdown-primary", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: { required: true },
                  expression: "{ required: true }"
                }
              ],
              ref: "countryDropdown",
              attrs: {
                "describedby-aria": _vm.errors.has("country")
                  ? "estores_country_error_msg"
                  : null,
                "required-aria": "true",
                dropdownValues: _vm.countryList.options,
                "aria-label": _vm.i18n.country,
                name: "country",
                dropdownError: _vm.errors.has("country")
              },
              on: {
                "selected-option": function($event) {
                  _vm.callRegionService($event)
                }
              },
              model: {
                value: _vm.user.country,
                callback: function($$v) {
                  _vm.$set(_vm.user, "country", $$v)
                },
                expression: "user.country"
              }
            }),
            _vm._v(" "),
            _vm.errors.has("country")
              ? _c("div", { staticClass: "mt-xs-2" }, [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "polite",
                        role: "alert",
                        id: "estores_country_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("country")))]
                  )
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "mb-xs-3 form-group" }, [
          _c(
            "label",
            { attrs: { for: "city", "aria-hidden": _vm.isDeviceAndroid() } },
            [_vm._v(_vm._s(_vm.i18n.termsCondition))]
          ),
          _vm._v(" "),
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.i18n.termsConditionText,
                expression: "i18n.termsConditionText"
              }
            ],
            staticClass: "form-control terms-condition-text",
            class: { "input-error": _vm.errors.has("termsCondition") },
            attrs: {
              type: "text",
              readonly: "",
              rows: "7",
              name: "termsCondition",
              "aria-label": _vm.i18n.termsCondition,
              "aria-describedby": "register_termsCondition_error"
            },
            domProps: { value: _vm.i18n.termsConditionText },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.i18n, "termsConditionText", $event.target.value)
              }
            }
          })
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group" }, [
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: { required: true },
                expression: "{ required: true }"
              }
            ],
            staticClass: "color-secondary-light-grey",
            attrs: {
              type: "checkbox",
              id: "termsConditionCheck",
              name: "termsCondition",
              "aria-describedby": "register_termsCondition_error"
            },
            domProps: { checked: _vm.user.termsConditionChecked },
            on: {
              click: function($event) {
                _vm.user.termsConditionChecked = !_vm.user.termsConditionChecked
              },
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.user.termsConditionChecked = !_vm.user.termsConditionChecked
              }
            }
          }),
          _vm._v(" "),
          _c("label", { attrs: { for: "termsConditionCheck" } }, [
            _vm._v(_vm._s(_vm.i18n.termsConditionCheckText))
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.errors.has("termsCondition"),
                  expression: "errors.has('termsCondition')"
                }
              ],
              staticClass: "mt-xs-2",
              attrs: { "aria-hidden": !_vm.errors.has("termsCondition") }
            },
            [
              _c(
                "span",
                {
                  staticClass: "error-msg",
                  attrs: {
                    "aria-live": "polite",
                    role: "alert",
                    id: "register_termsCondition_error"
                  }
                },
                [_vm._v(_vm._s(_vm.errors.first("termsCondition")))]
              )
            ]
          )
        ]),
        _vm._v(" "),
        _vm.globals.siteConfig.editCommPrefEnabled
          ? _c("div", { staticClass: "my-xs-0 checkbox" }, [
              _vm.globals.isB2B()
                ? _c("div", { staticClass: "form-group" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.user.b2bMailerCheck,
                          expression: "user.b2bMailerCheck"
                        }
                      ],
                      attrs: { id: "mailerText", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.user.b2bMailerCheck)
                          ? _vm._i(_vm.user.b2bMailerCheck, null) > -1
                          : _vm.user.b2bMailerCheck
                      },
                      on: {
                        focus: function($event) {
                          _vm.createAccountServerError = ""
                        },
                        change: function($event) {
                          var $$a = _vm.user.b2bMailerCheck,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.user,
                                  "b2bMailerCheck",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.user,
                                  "b2bMailerCheck",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.user, "b2bMailerCheck", $$c)
                          }
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("label", { attrs: { for: "mailerText" } }, [
                      _vm._v(_vm._s(_vm.i18n.mailerText))
                    ])
                  ])
                : _vm._e()
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass:
              "estoresRegSubmitBtn mt-sm-3 mt-xs-2 mb-xs-4 submit btn btn-block button-color text-trans",
            attrs: {
              type: "submit",
              disabled: _vm.errors.any() || _vm.isEmailMismatch
            },
            on: {
              click: _vm.submitForm,
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                return _vm.submitForm($event)
              }
            }
          },
          [_vm._v(_vm._s(_vm.i18n.submit))]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-1ae413e9", { render: render, staticRenderFns: staticRenderFns })
  }
}