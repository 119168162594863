var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    {
      staticClass: "access-customer-service-live-chat mt-sm-5 mt-xs-4",
      class: _vm.liveChatTheme
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex livechat-section mx-sm-3",
          class: _vm.livechat - _vm.left
        },
        [
          _c("div", { staticClass: "left-section mr-sm-5 mb-sm-5" }, [
            _c(
              "div",
              {
                staticClass:
                  "livechat-heading pb-sm-4 pb-xs-3 d-flex align-items-center"
              },
              [
                _c("span", {
                  staticClass: "icon-message-square",
                  attrs: { title: _vm.i18n.liveChat }
                }),
                _vm._v(" "),
                _vm.i18n.liveChat
                  ? _c("h2", { staticClass: "heading pl-xs-2 my-xs-0" }, [
                      _vm._v(_vm._s(_vm.i18n.liveChat))
                    ])
                  : _vm._e()
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "chat-section row px-xs-3 py-xs-4 mx-sm-0" },
              [
                _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                  _vm.imageTextData.headingText
                    ? _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(_vm.imageTextData.headingText)
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.imageTextData.informationText
                    ? _c("p", {
                        staticClass: "my-xs-0",
                        domProps: {
                          innerHTML: _vm._s(_vm.imageTextData.informationText)
                        }
                      })
                    : _vm._e()
                ])
              ]
            )
          ]),
          _vm._v(" "),
          _vm.showContactUs && _vm.globals.siteConfig.isCallUsEnabled
            ? _c("div", { staticClass: "right-section mb-sm-5" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "call-text-heading pb-sm-4 pb-xs-3 d-flex align-items-center"
                  },
                  [
                    _c("span", {
                      staticClass: "icon-phone",
                      attrs: { title: _vm.i18n.iconPhoneTitle }
                    }),
                    _vm._v(" "),
                    _vm.i18n.callOrTextUsHeading
                      ? _c("h2", { staticClass: "heading pl-xs-2 my-xs-0" }, [
                          _vm._v(_vm._s(_vm.i18n.callOrTextUsHeading))
                        ])
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row call-text-section px-xs-3 py-sm-5 py-xs-4 mb-xs-4 mx-sm-0"
                  },
                  [
                    _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                      _vm.i18n.callOrTextUsBody
                        ? _c(
                            "p",
                            { staticClass: "call-text-body pb-xs-4 mb-xs-0" },
                            [_vm._v(_vm._s(_vm.i18n.callOrTextUsBody))]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "call-text-number pb-xs-4 d-flex" },
                        [
                          _c("p", { staticClass: "mb-xs-4 mb-md-0" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.i18n.callText) +
                                "\n                "
                            ),
                            _vm.i18n.callNumber
                              ? _c(
                                  "a",
                                  {
                                    attrs: {
                                      href: "tel:+" + _vm.i18n.callNumber
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.i18n.callNumber))]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.i18n.callExt
                              ? _c(
                                  "a",
                                  { attrs: { href: "javascript:void(0)" } },
                                  [_vm._v(_vm._s(_vm.i18n.callExt))]
                                )
                              : _vm._e()
                          ]),
                          _vm._v(" "),
                          _vm.i18n.textNumber && _vm.i18n.text
                            ? _c(
                                "p",
                                { staticClass: "mb-xs-0 pl-xs-0 pl-md-3" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.i18n.text) +
                                      "\n                "
                                  ),
                                  _vm.i18n.textNumber
                                    ? _c(
                                        "a",
                                        {
                                          attrs: {
                                            href: "sms://+" + _vm.textNumber
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.i18n.textNumber))]
                                      )
                                    : _vm._e()
                                ]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "call-text-timings pb-xs-4" }, [
                        _vm.i18n.callOrTextUsTimings1
                          ? _c(
                              "p",
                              {
                                staticClass: "mb-xs-0",
                                attrs: { "aria-hidden": "true" }
                              },
                              [_vm._v(_vm._s(_vm.i18n.callOrTextUsTimings1))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.callOrTextUsTimings1Accessiblity
                          ? _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.i18n.callOrTextUsTimings1Accessiblity
                                )
                              )
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.callOrTextUsTimings2
                          ? _c(
                              "p",
                              {
                                staticClass: "mb-xs-0",
                                attrs: { "aria-hidden": "true" }
                              },
                              [_vm._v(_vm._s(_vm.i18n.callOrTextUsTimings2))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.callOrTextUsTimings2Accessibility
                          ? _c("span", { staticClass: "sr-only" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.i18n.callOrTextUsTimings2Accessibility
                                )
                              )
                            ])
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "email-us pb-xs-4" }, [
                        _vm.i18n.emailText
                          ? _c("span", [_vm._v(_vm._s(_vm.i18n.emailText))])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.emailUs
                          ? _c(
                              "a",
                              {
                                staticClass: "mb-xs-0",
                                attrs: { href: "mailto:" + _vm.i18n.emailUs }
                              },
                              [_vm._v(_vm._s(_vm.i18n.emailUs))]
                            )
                          : _vm._e()
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "write-us" }, [
                        _vm.i18n.writeText
                          ? _c("p", { staticClass: "mb-xs-2" }, [
                              _vm._v(_vm._s(_vm.i18n.writeText))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.addressLine1
                          ? _c("p", { staticClass: "mb-xs-0" }, [
                              _vm._v(_vm._s(_vm.i18n.addressLine1))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.addressLine2
                          ? _c("p", { staticClass: "mb-xs-0" }, [
                              _vm._v(_vm._s(_vm.i18n.addressLine2))
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.i18n.addressLine3
                          ? _c("p", { staticClass: "mb-xs-0" }, [
                              _vm._v(_vm._s(_vm.i18n.addressLine3))
                            ])
                          : _vm._e()
                      ])
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.showContactUs
            ? [
                _vm.globals.siteConfig.isEmailUsEnabled
                  ? _c("div", { staticClass: "left-section mr-sm-5 mb-sm-5" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "send-email-heading pb-sm-4 pb-xs-3 d-flex align-items-center"
                        },
                        [
                          _c("span", {
                            staticClass: "icon-mail",
                            attrs: { title: _vm.i18n.sendEmail }
                          }),
                          _vm._v(" "),
                          _vm.i18n.sendEmail
                            ? _c(
                                "h2",
                                { staticClass: "heading pl-xs-2 my-xs-0" },
                                [_vm._v(_vm._s(_vm.i18n.sendEmail))]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "send-email-section row px-xs-3 py-xs-5 mx-sm-0"
                        },
                        [
                          _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                            _vm.i18n.sendEmailText
                              ? _c("p", { staticClass: "mb-xs-2" }, [
                                  _vm._v(_vm._s(_vm.i18n.sendEmailText))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.i18n.emailUsText && _vm.i18n.emailAddress
                              ? _c("p", { staticClass: "my-xs-0" }, [
                                  _vm._v(
                                    _vm._s(_vm.i18n.emailUsText) +
                                      "\n                "
                                  ),
                                  _vm.i18n.emailAddress
                                    ? _c(
                                        "a",
                                        {
                                          staticClass: "mb-xs-0",
                                          attrs: {
                                            href:
                                              "mailto:" + _vm.i18n.emailAddress
                                          }
                                        },
                                        [_vm._v(_vm._s(_vm.i18n.emailAddress))]
                                      )
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.globals.siteConfig.isWriteUsEnabled
                  ? _c("div", { staticClass: "right-section mb-sm-5" }, [
                      _c(
                        "div",
                        {
                          staticClass:
                            "mail-us-heading pb-sm-4 pb-xs-3 d-flex align-items-center"
                        },
                        [
                          _c("span", {
                            staticClass: "icon-mail-us",
                            attrs: { title: _vm.i18n.iconMailTitle1 }
                          }),
                          _vm._v(" "),
                          _vm.i18n.mailUs
                            ? _c(
                                "h2",
                                { staticClass: "heading pl-xs-2 my-xs-0" },
                                [_vm._v(_vm._s(_vm.i18n.mailUs))]
                              )
                            : _vm._e()
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "row mail-us-section px-xs-3 py-sm-5 py-xs-4 mx-sm-0"
                        },
                        [
                          _c("div", { staticClass: "col-xs-12 px-xs-0" }, [
                            _vm.i18n.mailUsText1
                              ? _c("p", { staticClass: "mb-xs-2" }, [
                                  _vm._v(_vm._s(_vm.i18n.mailUsText1))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.i18n.mailUsText2
                              ? _c("p", { staticClass: "mb-xs-2" }, [
                                  _vm._v(_vm._s(_vm.i18n.mailUsText2))
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.i18n.mailAddress
                              ? _c("p", { staticClass: "mb-xs-0" }, [
                                  _vm._v(_vm._s(_vm.i18n.mailAddress))
                                ])
                              : _vm._e()
                          ])
                        ]
                      )
                    ])
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-8d7f3fe2", { render: render, staticRenderFns: staticRenderFns })
  }
}