import globals from '../../common/globals';
import liveChatMixin from '../../common/mixins/live-chat-mixin';
import ManageProfileShoppingListService from '../../common/services/manage-profile-shopping-lists-service';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import {
  supportTicketOrder,
  order,
} from '../../common/mixins/vx-enums';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import mobileMixin from '../../common/mixins/mobile-mixin';
import vxTable from '../../common/vx-table/vx-table.vue';
import { dataTablesEventBus } from '../../../modules/event-bus';

export default {
  name: 'vx-support-ticket',
  mixins: [liveChatMixin, mobileMixin],
  components: {
    vxSpinner,
    vxDropdownPrimary,
    vxTable,
  },
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      globals,
      supportTicketOrder,
      liveAgentConfig: globals.thirdPartyApps.liveAgent,
      tableTitles: [
        this.i18n.tickets,
        this.i18n.topicOfInquiry,
        this.i18n.createdBy,
        this.i18n.dateOpened,
        this.i18n.status,
        this.i18n.serviceAgent,
      ],
      supportTicketTable: {},
      displayedResultsLength: 10,
      tableData: [],
      showButtons: false,
      manageProfileShoppingListService: new ManageProfileShoppingListService(),
      sortedData: [],
      nextSortingOrder: 'DESC',
      mobileSortOptions: [],
      tableOrders: [
        this.i18n.ticketNumberAsc,
        this.i18n.ticketNumberDsc,
        this.i18n.topicOfInquiryAsc,
        this.i18n.topicOfInquiryDsc,
        this.i18n.createdByAsc,
        this.i18n.createdByDsc,
        this.i18n.dateOpenedLatest,
        this.i18n.dateOpenedOldest,
        this.i18n.statusAsc,
        this.i18n.statusDsc,
      ],
      order,
      tableConfig: {},
    };
  },
  created() {},
  mounted() {
    this.initSupportTicketData();
    this.createOptions();
  },
  methods: {
    /**
     * This function generates the column definitions
     */
    generateColumnDefs() {
      const columnConfigArray = [];
      return columnConfigArray;
    },
    /* This function navigates to contact us page to create a ticket
     */
    handleCreateTicket() {
      this.globals.navigateToUrl('contactUs');
    },
    /**
     * This function gets support ticket data from service
     */
    initSupportTicketData() {
      const requestConfig = {};
      if (globals.isB2C) {
        this.manageProfileShoppingListService.getSupportTicketsB2C(
          requestConfig,
          this.handleSupportTicketsResponse,
          this.handleSupportTicketsError,
        );
      } else if (globals.isB2B) {
        this.manageProfileShoppingListService.getSupportTicketsB2B(
          requestConfig,
          this.handleSupportTicketsResponse,
          this.handleSupportTicketsError,
        );
      }
      this.$refs.spinner.showSpinner();
    },
    /**
     * This function handles response of get support ticket
     */
    handleSupportTicketsResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.tableData = response.data.tickets;
      this.initializeTableConfig();
    },
    /**
     * This function handles error of get support ticket
     */
    handleSupportTicketsError() {
      this.$refs.spinner.hideSpinner();
    },

    /**
     * This function handles error of get sorted support tickets
     */
    handleSortSupportTicketDataError() {},
    /**
     * This function creates sorting options for datatable in mobile view
     */
    createOptions() {
      const self = this;
      this.tableOrders.forEach((val, index) => {
        const obj = {};
        obj.label = val;
        obj.index = index;
        if (
          val === self.i18n.ticketNumberAsc
          || val === self.i18n.topicOfInquiryAsc
          || val === self.i18n.createdByAsc
          || val === self.i18n.dateOpenedOldest
          || val === self.i18n.statusAsc
        ) {
          obj.value = self.order.ascending;
        } else {
          obj.value = self.order.descending;
        }
        self.mobileSortOptions.push(obj);
      });
    },
    /**
     * This function does the sorting for vx-table in mobile view
     */

    triggerSort(e) {
      const partialSortTitles = [];
      this.tableTitles.forEach((child) => {
        partialSortTitles.push(child.split(' ')[0]);
      }); let selectedSort = e.label.split(' ')[0];
      selectedSort = selectedSort.replace(':', '');
      const sortValue = partialSortTitles.indexOf(selectedSort);
      dataTablesEventBus.$emit('mobile-sort', { index: sortValue, order: e.value });
      this.filterHidden = !this.filterHidden;
    },

    /**
     * This function populates the dropdown with the default message
     */
    populateDefaultMessage() {
      this.$refs.sortDropdown.setDropdownLabel(this.i18n.sortBy);
    },
    initializeTableConfig() {
      const self = this;
      this.tableConfig = {
        rowCount: 10,
        emptyTable: this.i18n.noDataAvailable,
        columnConfiguration: [
          {
            customHeaderName: self.i18n.tickets,
            customTemplate(data) {
              const colData = data.sfdcCaseNumber || '';
              return `<span class="colData">${colData}</span>`;
            },
          },
          {
            customHeaderName: self.i18n.topicOfInquiry,
            customTemplate(data) {
              const colData = data.topicOfInquiry.value || '';
              return `<span class="colData">${colData}</span>`;
            },
          },
          {
            customHeaderName: self.i18n.createdBy,
            customTemplate(data) {
              const colData = `${data.firstName} ${data.lastName}` || '';
              return `<span class="colData">${colData}</span>`;
            },
          },
          {
            customHeaderName: self.i18n.dateOpened,
            customTemplate(data) {
              if (data.creationDate) {
                const modifiedDateValue = data.creationDate.split('-');
                const modifiedDate = modifiedDateValue[2].split('T');
                const dateValue = `${modifiedDateValue[1]}/${modifiedDate[0]}/${modifiedDateValue[0]}`;
                const colData = dateValue || '';
                return `<span class="colData">${colData}</span>`;
              }
              return undefined;
            },
          },
          {
            customHeaderName: self.i18n.status,
            customTemplate(data) {
              const colData = data.sfdcStatus || '';
              return `<span class="colData">${colData}</span>`;
            },
          },
          {
            customHeaderName: self.i18n.serviceAgent,
            customTemplate(data) {
              const colData = data.sfdcServiceAgent || '';
              return `<span class="colData">${colData}</span>`;
            },
          },
          {
            customHeaderTemplate: () => (`<span class="sr-only">${self.i18n.accordionToggleA11y}</span>`),
            accordionControl: true,
          },
        ],
        childRow: {
          accordionChild: true,
          template(data) {
            return `<div class="accordion-container">
      <div class="order">
      <div class="heading order-container">Order #</div>
      <div class="heading-value">${data.orderNumber || ''}</div>
      </div>
      </div>`;
          },
        },
      };
    },
  },
};
