/**
 * Component to Handle order approval list page functionality
 */
import mockOrderApproval from './mockOrderApproval';
import mobileMixin from '../../common/mixins/mobile-mixin';
import globals from '../../common/globals';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import ManageTransactionService from '../../common/services/manage-transaction-service';
import vxModal from '../../common/vx-modal/vx-modal.vue';
import vxRefineApprovalList from '../vx-refine-approval-list/vx-refine-approval-list.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import vxTable from '../../common/vx-table/vx-table.vue';
import vxTableUtilsMixin from '../../common/mixins/vxTable-mixin';

export default {
  name: 'vx-order-approvals',
  components: {
    vxDropdownPrimary,
    vxModal,
    vxRefineApprovalList,
    vxSpinner,
    vxTable,
  },
  mixins: [mobileMixin, vxTableUtilsMixin],
  props: {
    /**
     * Labels, button and caption texts
     */
    i18n: {
      type: Object,
    },
  },
  data() {
    return {
      mockOrderApproval,
      globals,
      manageTransactionService: new ManageTransactionService(),
      orderApprovalData: [],
      tableConfig: {},
      dateRange: {
        label: this.i18n.date6MonthFilter,
        value: 6,
      },
      status: 'CREATED',
      isAdmin: false,
      totalOrders: '',
      sortOptions: [],
      selectedSort: '',
      tableTitles: [
        this.i18n.order,
        this.i18n.orderPlaced,
        this.i18n.status,
        this.i18n.orderPlacedBy,
        this.i18n.shippedTo,
        this.i18n.noOfProducts,
        this.i18n.total,
      ],
      currentYear: '',
      filterData: {
        data: [],
        selectedItem: [],
      },
      statusApplied: false,
    };
  },
  computed: {},
  mounted() {
    this.$refs.spinner.showSpinner();
    if (this.globals.isB2B()) {
      this.getOrderApprovalsB2B();
    }
    // To get current date and to push in transaction date dropdown
    const newDate = new Date();
    this.currentYear = newDate.getFullYear();
  },
  methods: {
    /**
         * Function to generate sort options as per site.
         */
    generateSortOptions() {
      if (this.globals.isB2C()) {
        this.sortOptions = this.i18n.b2cSortOptions.options;
      } else if (this.globals.isB2B()) {
        this.sortOptions = this.i18n.b2bApprovalSortOptions.options;
      }
    },
    /**
     * Function which call order approval service.
     */
    getOrderApprovalsB2B() {
      this.manageTransactionService.getOrderApprovalsB2B(
        {},
        this.handleOrderApprovalSuccess,
        this.handleOrderApprovalError,
        this.dateRange.value,
      );
    },
    /**
     * Function to handle success response.
     */
    handleOrderApprovalSuccess(response) {
      this.$refs.spinner.hideSpinner();
      const { data } = response;
      if (this.$refs.refineApprovalListModel) {
        this.$refs.refineApprovalListModel.close();
      }
      if (data && data.orders) {
        this.totalOrders = `(${data.pagination.totalResults})`;
        this.orderApprovalData = data.orders;
        if (this.orderApprovalData && this.orderApprovalData.length !== 0) {
          this.isAdmin = this.orderApprovalData[0].shippingAddress.isAdmin;
        }
        this.initializeTableConfig();
        if (this.isMobile() && this.selectedSort) {
          this.triggerSort();
        }
      }
    },
    /**
     * Function to handle error response.
     */
    handleOrderApprovalError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Function to handle success response of OrderStatus service call.
     */
    handleOrderStatusSuccess(response) {
      this.statusApplied = true;
      this.$refs.refineApprovalListModel.close();
      const { data } = response;
      if (data && data.orders) {
        this.totalOrders = `(${data.pagination.totalResults})`;
        this.orderApprovalData = data.orders;
        if (this.orderApprovalData && this.orderApprovalData.length !== 0) {
          this.isAdmin = this.orderApprovalData[0].shippingAddress.isAdmin;
        }
        this.getDataTable();
        if (this.isMobile() && this.selectedSort) {
          this.triggerSort();
        }
      }
    },
    /**
     * Function to handle error response of OrderStatus service call.
     */
    handleOrderStatusError(error) {
      this.statusApplied = false;
      this.$refs.statusFilter.displayStatusError(error);
    },

    /**
     * Function to get order of columns by name.
     */
    getOrderByname(address) {
      return `${address.firstName} ${address.lastName}`;
    },
    /**
     * Function to create address string.
     */
    formAddressString(address) {
      let userAddress = '';
      let companyName = '';
      const name = `${address.firstName} ${address.lastName}`;
      if (address && address.companyName) {
        companyName = address.companyName;
      }
      if (address) {
        userAddress = [
          name,
          companyName,
          address.line1,
          address.line2,
          address.town,
          address.region.isocodeShort,
          address.postalCode,
          address.country.isocode,
        ]
          .filter(Boolean)
          .join(', ');
      }
      return userAddress;
    },
    /**
     * Function to open filter modal.
     */
    openFilterModel() {
      this.$refs.refineApprovalListModel.open();
    },
    /**
     * Function to set default sortby option.
     */
    populateDefaultDate() {
      if (!this.selectedSort) {
        this.$refs.sortDropdown.setDropdownLabel('Sort By');
      } else {
        this.$refs.sortDropdown.setDropdownValue(this.selectedSort);
      }
    },
    /**
     * Function to handle click of any button in filter modal.
     */
    filterApplicable(data) {
      this.filterData = data;
      this.dateRange = data.dateRange;
      if (this.filterData.action === 'apply') {
        if (this.filterData.data.length) {
          this.manageTransactionService.getOrderApprovalsStatus(
            {},
            this.handleOrderStatusSuccess,
            this.handleOrderStatusError,
            this.filterData.data,
          );
        } else {
          this.getOrderApprovalsB2B();
        }
      }
    },
    /**
     * Function to remove filters.
     */
    removeFilters(index) {
      this.filterData.data.splice(index, 1);
      this.filterData.selectedItem.splice(index, 1);
      if (this.filterData.data.length) {
        this.filterApplicable(this.filterData);
      } else {
        this.getOrderApprovalsB2B();
      }
    },
    /**
     * Function called for sortby in mobile view.
     */
    storeSort(e) {
      this.selectedSort = e.value;
    },

    initializeTableConfig() {
      const self = this;
      this.tableConfig = {
        rowCount: 10,
        emptyTable: this.i18n.noDataAvailable,
        columnConfiguration: [
          {
            customHeaderName: this.i18n.order,
            customTemplate(data) {
              let orderNumberField = '';
              if (globals.isB2B()) {
                orderNumberField = `<a href="${globals.getB2BBaseURL()}${globals.navigations.orderApprovalDetails}${data.code}">${data.code}</a>`;
              }
              return orderNumberField;
            },
          },
          {
            customHeaderName: this.i18n.orderPlaced,
            customTemplate(data) {
              let dateValue = '';
              if (data.placed) {
                const modifiedDateValue = data.placed.split('-');
                const modifiedDate = modifiedDateValue[2].split('T');
                dateValue = `${modifiedDateValue[1]}/${modifiedDate[0]}/${modifiedDateValue[0]}`;
              }
              return dateValue;
            },
          },
          {
            customHeaderName: this.i18n.status,
            field: 'statusDisplay',
            visibilityCondition: () => (!this.globals.isB2C() && this.isAdmin),
          },
          {
            customHeaderName: this.i18n.orderPlacedBy,
            customTemplate(data) {
              return self.getOrderByname(data.shippingAddress.addresses[0]);
            },
            visibilityCondition: () => (!this.globals.isB2B()),
          },
          {
            customHeaderName: this.i18n.shippedTo,
            customTemplate(data) {
              const addressList = data.shippingAddress.addresses.map(
                (address) => {
                  const addressFull = self.formAddressString(address);
                  return `<p>${addressFull}</p>`;
                },
              );
              return addressList.join('');
            },
          },
          {
            customHeaderName: this.i18n.noOfProducts,
            field: 'totalNumberOfProducts',
          },
          {
            customHeaderName: this.i18n.total,
            field: 'total.formattedValue',
          },
        ],
      };
    },
  },
};
