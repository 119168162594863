var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-order-approvals" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "refineApprovalListModel",
          attrs: { size: "medium", heading: _vm.i18n.refineModalHeading }
        },
        [
          _c(
            "vx-refine-approval-list",
            {
              ref: "statusFilter",
              attrs: {
                slot: "component",
                i18n: _vm.i18n,
                "filtered-options": _vm.filterData.data,
                "selected-options-from-approvals": _vm.filterData.data,
                "selected-items-from-approvals": _vm.filterData.selectedItem
              },
              on: { filterData: _vm.filterApplicable },
              slot: "component"
            },
            [
              _vm.isMobile()
                ? _c(
                    "div",
                    {
                      staticClass: "mb-xs-3",
                      attrs: { slot: "sortDropdown" },
                      slot: "sortDropdown"
                    },
                    [
                      _c("vx-dropdown-primary", {
                        ref: "sortDropdown",
                        attrs: { dropdownValues: _vm.sortOptions },
                        on: {
                          "selected-option": function($event) {
                            _vm.storeSort($event)
                          }
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-flex justify-content-between order-approvals-heading mt-xs-6"
        },
        [
          _c("h3", { staticClass: "mt-xs-0" }, [
            _vm._v(_vm._s(_vm.i18n.approvalsHeader))
          ]),
          _vm._v(" "),
          _c("span", {
            staticClass: "order-approvals-filter icon-filter",
            attrs: {
              "aria-label": _vm.i18n.refineModalHeading,
              role: "button",
              tabindex: "0",
              title: _vm.i18n.iconFilterTitle
            },
            on: {
              click: function($event) {
                _vm.openFilterModel($event)
              },
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k(
                    $event.keyCode,
                    "enter-space",
                    undefined,
                    $event.key,
                    undefined
                  )
                ) {
                  return null
                }
                _vm.openFilterModel($event)
              }
            }
          })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "pb-xs-4 d-flex applied-filter-section" },
        _vm._l(_vm.filterData.selectedItem, function(currentFilter, index) {
          return _vm.statusApplied && _vm.filterData.selectedItem.length
            ? _c("div", { staticClass: "applied-filter d-flex" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "mr-xs-3 d-flex filter-list tag align-items-center"
                  },
                  [
                    _c("span", { staticClass: "tag-content" }, [
                      _vm._v(_vm._s(currentFilter.label))
                    ]),
                    _vm._v(" "),
                    _c("span", {
                      staticClass: "icon-x",
                      attrs: {
                        "aria-label": _vm.i18n.iconXTitle,
                        role: "button",
                        tabindex: "0",
                        title: _vm.i18n.iconXTitle
                      },
                      on: {
                        click: function($event) {
                          _vm.removeFilters(index, $event)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.removeFilters(index, $event)
                        }
                      }
                    })
                  ]
                )
              ])
            : _vm._e()
        })
      ),
      _vm._v(" "),
      _c("vx-table", {
        attrs: {
          tableConfig: _vm.tableConfig,
          tableData: _vm.orderApprovalData
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-588b5ded", { render: render, staticRenderFns: staticRenderFns })
  }
}