var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vx-dispenser-replacement" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "dispenser-form-container" }, [
        _c("div", {
          staticClass: "dispenser-form-heading col-xs-12 pb-xs-3",
          domProps: { innerHTML: _vm._s(_vm.dispenserTicketData.heading) }
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "dispenser-form-description col-xs-12 pb-xs-5",
          domProps: { innerHTML: _vm._s(_vm.dispenserTicketData.description) }
        }),
        _vm._v(" "),
        _c("form", { ref: "ticketForm" }, [
          _c("div", { staticClass: "dispenser-ticket-section" }, [
            _c("div", { staticClass: "col-xs-12" }, [
              _c(
                "div",
                { staticClass: "form-group col-xs-6" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "keyQuantities",
                        "aria-hidden": !_vm.isTablet()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.keyQuantities))]
                  ),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "keyQuantitiesDropdown",
                    attrs: {
                      "describedby-aria": "key_quantities_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.dispenserKeyQuantityList,
                      "aria-label": _vm.i18n.keyQuantities,
                      name: "keyQuantities",
                      dropdownError: _vm.errors.has("keyQuantities")
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.form.keyQuantities = $event
                      }
                    },
                    model: {
                      value: _vm.form.keyQuantities,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "keyQuantities", $$v)
                      },
                      expression: "form.keyQuantities"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("keyQuantities"),
                          expression: "errors.has('keyQuantities')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "key_quantities_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("keyQuantities")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-xs-6" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "dispenserType",
                        "aria-hidden": !_vm.isTablet()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.dispenserType))]
                  ),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "topicDropdown",
                    attrs: {
                      "describedby-aria": "dispenser_type_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.dispenserTypeValuesList,
                      "aria-label": _vm.i18n.dispenserType,
                      name: "dispenserType",
                      dropdownError: _vm.errors.has("dispenserType")
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.form.dispenserType = $event
                      }
                    },
                    model: {
                      value: _vm.form.dispenserType,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "dispenserType", $$v)
                      },
                      expression: "form.dispenserType"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("dispenserType"),
                          expression: "errors.has('dispenserType')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "dispenser_type_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("dispenserType")))]
                      )
                    ]
                  )
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "firstName",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.firstName))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.firstName,
                      expression: "form.firstName"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^[A-Za-z\s\’'-]+$/,
                        max: 40
                      },
                      expression:
                        "{ required: true,\n                  regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 40 }"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("firstName") },
                  attrs: {
                    type: "text",
                    maxlength: "40",
                    id: "firstName",
                    name: "firstName",
                    "aria-label": _vm.i18n.firstName,
                    "aria-describedby": "firstname_error_msg"
                  },
                  domProps: { value: _vm.form.firstName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "firstName", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("firstName"),
                        expression: "errors.has('firstName')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-hidden": !_vm.errors.has("firstName") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "firstname_error_msg" }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("firstName")))]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "lastName",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.lastName))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.lastName,
                      expression: "form.lastName"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: {
                        required: true,
                        regex: /^[A-Za-z\s\’'-]+$/,
                        max: 80
                      },
                      expression:
                        "{ required: true,\n                  regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 80 }"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("lastName") },
                  attrs: {
                    type: "text",
                    maxlength: "80",
                    id: "lastName",
                    name: "lastName",
                    "aria-label": _vm.i18n.lastName,
                    "aria-describedby": "lastname_error_msg"
                  },
                  domProps: { value: _vm.form.lastName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "lastName", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("lastName"),
                        expression: "errors.has('lastName')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-hidden": !_vm.errors.has("lastName") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "lastname_error_msg" }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("lastName")))]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "companyName",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.companyName))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: { required: true, max: 80 },
                      expression: "{required: true, max:80}"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.companyName,
                      expression: "form.companyName"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("companyName") },
                  attrs: {
                    type: "text",
                    maxlength: "80",
                    name: "companyName",
                    "aria-label": _vm.i18n.companyName
                  },
                  domProps: { value: _vm.form.companyName },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "companyName", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("companyName")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("companyName")))
                      ])
                    ])
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-xs-6" },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "country",
                          "aria-hidden":
                            !_vm.isTablet() && _vm.isDeviceAndroid()
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.country) + "\n              ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "countryDropdown",
                    attrs: {
                      "required-aria": "true",
                      dropdownValues: _vm.i18n.countryList,
                      "aria-label": _vm.i18n.country,
                      name: "country"
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.setCountry($event)
                      }
                    },
                    model: {
                      value: _vm.form.country,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "country", $$v)
                      },
                      expression: "form.country"
                    }
                  })
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "address",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.address) + "\n            ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.streetAddress,
                      expression: "form.streetAddress"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:60",
                      expression: "'required|max:60'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("address") },
                  attrs: {
                    type: "text",
                    id: "address",
                    maxlength: "60",
                    name: "address",
                    "aria-label": _vm.i18n.streetAddress,
                    "aria-describedby": "address_error_msg"
                  },
                  domProps: { value: _vm.form.streetAddress },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "streetAddress", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("address"),
                        expression: "errors.has('address')"
                      }
                    ],
                    attrs: { "aria-hidden": !_vm.errors.has("address") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "address_error_msg" }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("address")))]
                    )
                  ]
                )
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: { for: "city", "aria-hidden": _vm.isDeviceAndroid() }
                  },
                  [_vm._v(_vm._s(_vm.i18n.city) + "\n            ")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.city,
                      expression: "form.city"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|max:80",
                      expression: "'required|max:80'"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("city") },
                  attrs: {
                    type: "text",
                    id: "inputCity",
                    name: "city",
                    "aria-label": _vm.i18n.city,
                    maxlength: "80",
                    "aria-describedby": "address_city_error_msg"
                  },
                  domProps: { value: _vm.form.city },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "city", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("city"),
                        expression: "errors.has('city')"
                      }
                    ],
                    staticClass: " mt-xs-2",
                    attrs: { "aria-label": !_vm.errors.has("city") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "address_city_error_msg" }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("city")))]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c(
                "div",
                { staticClass: "form-group col-xs-6" },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "state",
                          "aria-hidden":
                            !_vm.isTablet() && _vm.isDeviceAndroid()
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.state) + "\n              ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "regionDropdown",
                    attrs: {
                      "describedby-aria": "dispenser_state_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.regionList,
                      name: "state",
                      "aria-label": _vm.i18n.state,
                      dropdownError: _vm.errors.has("state")
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.form.region = $event.label
                      }
                    },
                    model: {
                      value: _vm.form.region,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("state"),
                          expression: "errors.has('state')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "dispenser_state_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("state")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "zipcode",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.zipcode))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.postalCode,
                      expression: "form.postalCode"
                    },
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: _vm.zipCodeValidation(),
                      expression: "zipCodeValidation()"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("zipcode") },
                  attrs: {
                    type: "text",
                    id: "inputZip",
                    maxlength: "20",
                    name: "zipcode",
                    "aria-label": _vm.i18n.zipcode,
                    "aria-describedby": "address_zip_error_msg"
                  },
                  domProps: { value: _vm.form.postalCode },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "postalCode", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("zipcode"),
                        expression: "errors.has('zipcode')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-label": !_vm.errors.has("zipcode") }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "error-msg",
                        attrs: { id: "address_zip_error_msg" }
                      },
                      [_vm._v(_vm._s(_vm.errors.first("zipcode")))]
                    )
                  ]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c("div", { staticClass: "form-group col-xs-6" }, [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "email",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [_vm._v(_vm._s(_vm.i18n.email))]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required|email|max:80",
                      expression: "'required|email|max:80'"
                    },
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.form.email,
                      expression: "form.email"
                    }
                  ],
                  staticClass: "form-control",
                  class: { "input-error": _vm.errors.has("email") },
                  attrs: {
                    type: "text",
                    maxlength: "80",
                    name: "email",
                    "aria-label": _vm.i18n.email,
                    id: "email"
                  },
                  domProps: { value: _vm.form.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(_vm.form, "email", $event.target.value)
                    }
                  }
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.errors.has("email"),
                        expression: "errors.has('email')"
                      }
                    ],
                    staticClass: "mt-xs-2",
                    attrs: { "aria-label": !_vm.errors.has("email") }
                  },
                  [
                    _c("span", { staticClass: "error-msg" }, [
                      _vm._v(_vm._s(_vm.errors.first("email")))
                    ])
                  ]
                )
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-xs-6" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "telephone",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.phone) + "\n            ")]
                  ),
                  _vm._v(" "),
                  _c("the-mask", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "min:15",
                        expression: "'min:15'"
                      }
                    ],
                    staticClass: "phone-number form-control",
                    attrs: {
                      masked: _vm.masked,
                      mask: "+1 ###-###-####",
                      type: "tel",
                      name: "telephone",
                      "aria-label": _vm.i18n.phone
                    },
                    model: {
                      value: _vm.form.phone,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "phone", $$v)
                      },
                      expression: "form.phone"
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("telephone")
                    ? _c("div", { staticClass: "mt-xs-2" }, [
                        _c("span", { staticClass: "error-msg" }, [
                          _vm._v(_vm._s(_vm.errors.first("telephone")))
                        ])
                      ])
                    : _vm._e()
                ],
                1
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12" }, [
              _c(
                "button",
                {
                  staticClass:
                    "submit btn btn-primary mt-xs-4 mt-sm-5 mb-xs-5 mb-md-4",
                  attrs: { type: "submit", disabled: _vm.errors.any() },
                  on: { click: _vm.handleSubmit }
                },
                [_vm._v(_vm._s(_vm.i18n.submit))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 pt-xs-3 disclaimer" }, [
              _vm._v(
                "\n          " +
                  _vm._s(_vm.dispenserTicketData.disclaimer) +
                  "\n        "
              )
            ])
          ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2f05bdaf", { render: render, staticRenderFns: staticRenderFns })
  }
}