import globals from '../../common/globals';
import kauthMixin from '../../common/mixins/kauth-mixin';

export default {
  name: 'vx-koch-auth',
  mixins: [kauthMixin],
  components: {},
  props: {
    kochUrl: String,
  },
  data() {
    return {
      globals,
    };
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  },
};
