import addPaymentForm from '../common/add-payment-form-i18n';

const checkout = {
  checkout: {
    checkoutHeading: 'Secure Checkout',
    shippingAddressHeading: 'Shipping Address',
    leaseAgreementHeading: 'Lease Agreement',
    scheduleInstallationHeading: 'Schedule your Installation',
    shippingMethodHeading: 'Shipping Method',
    giftOptionsHeading: 'Gift Options',
    PaymentHeading: 'Payment & Billing Address',
    overviewHeading: 'Overview',
    legalHeading: '',
    legalText1: '',
    legalText2: '',
    fillInformationLabel:
      'Please fill information in all the steps before proceeding to place an order.',
    placeOrderLabel: 'Place Order',
    // Error Codes
    lowQuantityErrorCode: '113',
    lowQuantityErrorCodeSubscription: '199',
    lowQuantityErrorMessage:
      '5:There is not enough inventory available for your order. Please try to reduce quantity or remove item from your cart',
    paymentErrorCode: '109',
    paymentErrorCodeMessage:
      'We are unable to process your payment at this time. Please verify that your payment details are correct and try again. If the problem persists, contact us for further assistance.',
    subscribeOrderLabel: 'Subscribe',
    captchaErrorMsg: 'Please verify that you are not a robot.',
    captchaHelpText: 'This site is protected by reCAPTCHA and the Google',
  },
  guestCheckout: {
    heading: 'Guest Check Out',
    description:
      'An account is not required to complete purchases. You will have the ability to create an account after checking out.',
    buttonText: 'Checkout as a Guest',
    supportHelp:
      'Call our support specialists if you have any questions or concerns.',
    supportPhone: '1-866-435-5647',
    emailBtnLabel: 'Email Address',
    // Validation messages
    emailRequiredError: 'Please enter your Email Address',
    emailInvalidError: 'Please enter a valid Email Address',
  },
  shippingAddress: {
    discontinued: 'Discontinued',
    replacedBy: 'and Replaced By Similar Product',
    addressLabel: 'Address',
    itemID: 'ITEM ID',
    addAddress: 'Add Address',
    shippingAddressHeading: 'Shipping Address',
    shippingAddressSubHeading:
      '',
    multiLocationHeading: 'Ship to multiple locations',
    useShippingAddress: 'USE THIS ADDRESS',
    useMultipleShippingAddress: 'save addresses & continue',
    singleLocationHeading: 'Ship to one location',
    change: 'Change',
    multipleShippingAddressSubHeading: 'Choose where to ship your items.',
    multipleShippingAddressSubText:
      '',
    add: 'Add',
    restrictionHeading: 'Restricted Items',
    restrictionSubHeading:
      '',
    flyoutRestrictionErrorPart1: "This order contains items that can't ship to",
    flyoutRestrictionErrorPart2:
      ', Please remove items or change your shipping address',
    multiRestrictionHeading: 'Shipping Restriction',
    restrictionErrorPart1: 'The product cannot be shipped to',
    restrictionErrorPart2: 'because of',
    restrictionErrorPart3: 'and must be removed from your cart. Please contact',
    restrictionErrorPart4: 'GPXpress®',
    restrictionErrorPart5: 'for assistance.',
    quantity: 'Quantity',
    b2bNoAddress:
      "It looks like you have no saved addresses. To continue the checkout process you'll need to request a new address for approval",
    b2bNoAddressLink: 'here.',
    enterAddress: 'Please enter address',
    quantityError:
      'The quantity split across addresses does not equal the quantity added to the cart. Please check',
    shippedMultiple: 'You’re shipping to multiple adderesses',
    dropdownLabel: 'Select option',
    shippingRestrictionText:
      'Please remove items or change your shipping address',
    saveAddress: 'Save',
    iconCertificationTitle: 'Green Certified',
    iconShippingTitle: 'Free Shipping Available',
    iconOnlineTitle: 'Only Available Online',
    iconSubscriptionTitle: 'Subscribe',
    iconSeasonalTitle: 'Seasonal Product',
    iconTrashTitle: 'Delete',
    checkPhone: 'Please confirm that your selected address includes a phone number',
    checkCompanyName: 'Please confirm that your selected address includes a company name',
    subHeading: 'Please fill in your address details',
    duplicateAddressError: 'Same address exists on multiple lines, please correct.',
    quantityError: 'The quantity split across addresses does not equal the quantity added to the cart. Please correct.',
    fullySplitError: 'Cart quantity has been fully split.  Please reduce quantity on an existing address row.'

  },
  address: {
    firstName: 'First Name',
    lastName: 'Last Name',
    phoneNumber: 'Phone Number',
    newUserPhoneNumber: 'Phone Number',
    country: 'Country',
    companyName: 'Company Name',
    newUsercompanyName: 'Company Name',
    addressHeader: 'Ship to an Address',
    shippingAddressModal: 'Ship to an Address',
    b2caddressLine1: 'Address 1',
    newUserAddressLine1: 'Shipping Address 1',
    b2caddressLine2: 'Address 2 (optional)',
    newUserAddressLine2: 'Shipping Address 2 (optional)',
    b2baddressLine1: 'Address 1',
    b2baddressLine2: 'Address 2 (optional)',
    bussinessUnit: 'Business Unit',
    city: 'City',
    state: 'State',
    newUserstate: 'State',
    zipcode: 'Zip/Postal Code',
    palletShipments: 'This location accepts pallet shipments',
    shippingAddressSubText:
      '',
    save: 'SAVE',
    useShippingAddress: 'USE THIS ADDRESS',
    addressVerificationHeading: 'Address Verification',
    currentAddressHeading: 'Current Address',
    verifiedAddressHeading: 'Verified Address',
    useCurrentAddress: 'Use Current Address',
    useVerifiedAddress: 'Use Verified Address',
    emailRequiredError: 'Please enter your Email Address',
    emailInvalidError: 'Please enter a valid Email Address',
    firstNameRequiredError: 'Please enter in a valid First Name',
    firstNameRegexError: 'Please enter in a valid First Name',
    firstNameMaxError: 'First Name should not be more than 35 characters',
    lastNameRequiredError: 'Please enter in a valid Last Name',
    lastNameRegexError: 'Please enter in a valid Last Name',
    lastNameMaxError: 'Last Name should not be more than 35 characters',
    phoneError: 'Please enter in a valid Phone Number',
    countryError: 'Please select a Country',
    companyNameError: 'Please enter in a valid Company Name',
    addressLine1Error:
      'Please provide a valid first line of the shipping address',
    addressLineRegexError:
    'At this time, orders cannot be shipped to PO Boxes',
    cityError: 'Please enter in a valid City',
    stateError: 'Please select a State',
    zipcodeError: 'Please enter in a valid Zip/Postal Code',
    zipCodeRegexError: 'Please enter in a valid Zip/Postal Code',
    phoneRequiredError: 'Please enter in a valid Phone Number',
    phoneMinError: 'Please enter in a valid Phone Number',
    businessUnitError: 'Please select a Buisness Unit',
    companyNameRegexError: 'Please enter in a valid Company Name',
  },
  shippingMethod: {
    heading: 'Shipping Method',
    select: 'Select Shipping Method',
    delivery: 'Add Delivery Instructions (optional)',
    heading1: 'Shipping Address',
    deliveryCharacterCount: 'Characters Left',
    singleSaveButton: 'Use Shipping Method',
    multipleSaveButton: 'save & continue',
    changeButton: 'Change',
    products: 'Products',
    address: 'Shipping Address',
    estimatedDelivery: 'Estimated Delivery',
    deliveryMessage: '*Products will be delivered on',
    deliveryInstruction: 'Delivery Instructions',
    quantity: 'Quantity',
    product: 'Products',
    itemId: 'ITEM ID',
    promo: 'Promo',
    productIconsTitle: {
      freeShipping: 'Free Shipping Eligible',
      onlineOnly: 'Only Available Online',
      subscribable: 'Subscription Eligible',
      seasonal: 'Seasonal Product',
      bundleAvailable: 'Bundle Eligible',
      certification: 'Certifications Available',
      customisation: 'Customizable Product',
      installation: 'Installation Service Available',
      sample: 'Sample Eligible',
    },
  },
  paymentBilling: {
    heading: 'Payment & Billing Address',
    cardRadioButton: 'Credit/Debit Card',
    paypal: 'PayPal',
    logo: 'logo',
    credit: 'credit',
    savePaymentButton: 'use this credit/debit card',
    addCardButton: 'Add Payment',
    changeButton: 'Change',
    savedCardDetails: 'Ending in',
    billingAddress: 'Billing Address',
    subHeading: 'Payment Method',
    cardTypeMaster: 'master card',
    cardTypeVisa: 'visa card',
    cardTypeDiscover: 'discover card',
    cardTypeAmex: 'amex card',
    cardTypeDiners: 'diners card',
    applePayFailureError: 'Error in Apple Payment',
    googlePayFailureError: 'Error in Google Payment',
    creditCardAddressError: 'We are unable to process your payment at this time. Please verify that your payment details are correct and try again. If the problem persists, contact us for further assistance.',
    paymentSheetFailureError:
      'There was a problem in connecting to Apple Pay. Please try again later',
    googlePaymentSheetFailureError:
      'There was a problem in connecting to Google Pay. Please try again later',
  },
  giftingOptions: {
    heading: 'Gift Options',
    changeButton: 'Change',
    shipping: 'Shipping to',
    giftWrapCheckbox: 'Add Gift Wrapping',
    giftMessage: 'Gift Message',
    giftMessageCharacterCount: 'Characters Left',
    saveButton: 'save & continue',
    giftingMessage: 'Gifting Message',
    giftWrapAdded: 'Wrapping added',
  },
  scheduleInstallation: {
    heading: 'Schedule your Installation',
    subHeading:
      'The following address(s) require you to schedule an installation date/time.',
    installBtn: 'USE THIS DATE AND TIME',
    scheduleBtn: 'Schedule this installation',
    installDetails: 'Select your preferred date for the installation.',
    extraInstallDetails:
      'Any Extra Information that might help our installation team? (optional)',
    preferredTime: 'Select your preferred time of day for the installation.',
    contactPerson:
      'Please provide a person for us to contact about your installation.',
    nameLabel: 'Name',
    phoneLabel: 'Phone Number',
    unscheduledLabel: 'Not Scheduled',
    change: 'Change',
    multipleAddresses: 'Multiple address',
    addressHeading: 'Address',
    pallet: '(Able to receive pallet)',
    installationDetails: 'Installation Details',
    of: 'of',
    items: 'Items',
    customerNameRequiredError: 'Please provide your Name',
    customerNameAlphaError: 'Please enter in a valid Name',
    customerNameMaxError: 'Name should not be more than 40 characters.',
    phoneRequiredError: 'Please enter in a Phone Number',
    phoneMinError: 'The Phone Number entered is invalid, please try again.',
  },
  leaseAgreement: {
    heading: 'Lease Agreement',
    subHeading:
      'The following products require you to accept a Lease Agreement prior to purchase.',
    leaseBtnLabel: 'Review Lease Agreement',
    country: 'Country of Lease',
    productNameHeading: 'Item Name',
    skuHeading: 'SKU',
    quantityHeading: 'Items',
    change: 'Change',
    declineMessage:
      'If you are not accepting the lease agreement, please remove the item(s) needing lease agreements from your cart to checkout.',
    saveButton: 'save & continue',
  },
  reviewLeaseAgreement: {
    heading: 'Lease Agreement',
    acceptBtn: 'Accept',
    declineBtn: 'Decline',
    acceptedBtn: 'Accepted',
  },
  overview: {
    heading: 'Overview',
    quantity: 'Quantity',
    delivery: 'Delivery Method',
    itemId: 'ITEM ID',
    promo: 'Promo',
    restrictionError: 'Shipping Restriction',
    giftingOptions: 'Gifting Options',
    giftMessage: 'Message',
    giftWrapped: 'Wrapped',
    discontinued: 'Discontinued',
    replacedBy: 'and Replaced By Similar Product',
    outOfStock: 'Not available online',
    lowInventoryMessage: 'Low Inventory',
    iconCertificationTitle: 'Green Certified',
    iconShippingTitle: 'Free Shipping Available',
    iconOnlineTitle: 'Only Available Online',
    iconSubscriptionTitle: 'Subscribe',
    iconSeasonalTitle: 'Seasonal Product',
    iconTrashTitle: 'Delete',
    subscribeHeading: 'Review your subscription order',
    subscribeFrequencyHeading: 'Subscription: ',
    subscribeDiscount1: 'With ',
    subscribeDiscount2: '% discount',
    subscribeLearn: 'Learn More',
    subscribeIconTitle: 'Info',
    subscribePopoverText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Donec ullamcorper nulla non metus auctor fringilla.',
    productIconsTitle: {
      freeShipping: 'Free Shipping Eligible',
      onlineOnly: 'Only Available Online',
      subscribable: 'Subscription Eligible',
      seasonal: 'Seasonal Product',
      bundleAvailable: 'Bundle Eligible',
      certification: 'Certifications Available',
      customisation: 'Customizable Product',
      installation: 'Installation Service Available',
      sample: 'Sample Eligible',
    },
  },
  orderSummary: {
    orderSummaryHeading: 'Order Summary',
    subscriptionSummaryHeading: 'Subscription Summary',
    cartProductTotal: 'Cart Subtotal',
    totalDiscounts: 'Total Savings',
    priceDiscounts: 'Price Discounts',
    promotionalDiscounts: 'Promotional Discounts',
    totalPrice: 'Total',
    orderHelpingText: 'Shipping and tax will be calculated at checkout.',
    promoDetailsHeading: 'Promotions Details',
    promotionsClose: 'Close',
    applyProduct: 'Apply',
    itemLabel: 'Items',
    itemLabelSingle: 'Item',
    // Error Messages
    108: 'Promo code entered is invalid or out of date.',
    shipping: 'Shipping',
    defaultShippingCost: '$0.00',
    savings: 'Total Savings',
    tax: 'Tax',
    gift: 'This order contains a gift.',
    learnMore: 'Learn more',
    installableModalHeading: 'Installation Services',
    installationServicesInfo:
      'Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna. Nullam id dolor id nibh ultricies vehicula ut id elit. Maecenas sed diam eget risus varius blandit sit amet non magna. Sed posuere consectetur est at lobortis. Fusce dapibus, tellus ac cursus commodo, tortor mauris condimentum nibh, ut fermentum massa justo sit amet risus. Nullam id dolor id nibh ultricies vehicula elit.',
    install: "You've requested installation services.",
    installHelpContactText1: 'Please contact GPXpress® at',
    installHelpContactNumber: '123-456-7890',
    installHelpContactText2: 'for questions regarding your installation.',
    orderHelpContactText:
      'For questions about your order, please contact us at',
    orderHelpContactNumber: '800-750-4226',
    termsConditionInfo:
      "By placing the order, I am confirming that I've read and agree with the",
    termsConditionLink: 'Terms & Conditions',
    enterPromo: 'Enter promo code',
    termsConditionHeading: 'Terms and Conditions',
    iconCloseTitle: 'Close',
    subscribeTermsConditionInfo:
      "By subscribing, I am confirming that I've read and agree with the",
    subscribeHelpText: 'You are subscribed for the product',
    subscribeLearn: 'Learn More',
    noCharge: 'No Charge',
    appliedPromotions: 'Applied Promos',
    promotionsNotApplicable: 'Not Applicable Promos'
  },
  addPaymentForm,
  orderConfirmation: {
    thankYou: 'Thank you for your Order!',
    orderEmail:
      "Your order is complete. We'll send you an email confirmation shortly.",
    giveAwayCoupon: "You've earned the following coupon!",
    subscptionOrderEmail:
      "Your subscription order is complete. We'll send you an email confirmation shortly",
    subscptionOrder: 'Subscription Order',
    order: 'Order',
    subscrOrder: 'Subscription Order',
    orderPlaced: 'Order Placed',
    orderStatus: 'Order Status',
    shippedTo: 'Shipped To',
    billedTo: 'Billed To',
    notAvailable: 'NA',
    installation: 'Installation',
    deliveryMethod: 'Delivery Method',
    deliveryInstructions: ' Delivery Instructions',
    leaseAgreement: 'Lease Agreement',
    download: 'Download',
    itemOrdered: 'Item Ordered',
    itemsOrdered: 'Items Ordered',
    itemId: 'ITEM ID',
    quantity: 'Quantity',
    promo: 'Promo',
    giftable: 'This item is a gift',
    giftMessageHeading: 'Gift Message',
    installable: 'Installation Requested',
    buyAgainButton: 'Buy Again',
    discontinued: 'Discontinued',
    replacedBy: 'and Replaced By Similar Product',
    cancelOrder: 'Cancel Order',
    keepOrder: 'No, Keep Order',
    cancelOrderErrorMsg: 'Unable to cancel order, Please try again later',
    cancelOrderSuccessMsg:
      'Your order is currently being processed for cancellation',
    cancelOrderText:
      'Do you have concerns about your order? If you have specific questions, please contact Customer Service at <PHONE NUMBER>',
    acceptsPalletShipmentMessage: 'Able to receive pallet',
    iconPrinterTitle: 'Print',
    estName: 'Eastern Time (ET)',
    subscribeDiscount1: 'With ',
    subscribeDiscount2: '% discount',
    subscribeFrequencyHeading: 'Subscription: ',
    subscribeIconTitle: 'Info',
    subscribeLearn: 'Learn More',
    subscribePopoverText:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec id elit non mi porta gravida at eget metus. Donec ullamcorper nulla non metus auctor fringilla.',
    noProductName: 'no product name',
    notPurchasable: 'Not Purchasable',
  },
  guestCreateAccount: {
    text:
      'Save checkout information for your next purchase and create an account.',
    button: 'Get Started',
  },
  guestMarketCommunication: {
    header: 'Market Communication',
    description1: 'Sign up for marketing communications from ',
    description2:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec ullamcorper nulla non metus auctor fringilla.',
  },
  splitAddress: {
    splitQuantity: 'Split Quantity',
    shipToAnotherAddress: 'Ship to Another Address',
    dropdownError: 'Please enter an address',
    quantity: 'Quantity',
    address: 'Address',
    emptyDropdownLabel: 'No saved addresses',
    filledDropdownLabel: 'Select option',
    iconTrashTitle: 'Delete',
    checkPhone: 'Please confirm that your selected address includes a phone number',
    checkCompanyName: 'Please confirm that your selected address includes a company name',
    duplicateAddressError: 'Same address exists on multiple lines, please correct.',
    quantityError: 'The quantity split across addresses does not equal the quantity added to the cart. Please correct.',
    fullySplitError: 'Cart quantity has been fully split.  Please reduce quantity on an existing address row.'
  },
  paypal: {
    launchError:
      'There was a problem in connecting to Paypal. Please try after sometime',
    paymentError:
      'There was a problem in connecting to Paypal. Please try after sometime',
  },
  reorderButton: {
    reorderSingleButtonText: 'REORDER',
    reorderMultipleButtonText: 'REORDER PRODUCTS',
    maxPurchaseableQuantityErrorCode: '197',
    lowStockErrorCode: '198',
    success: 'success',
    addedMaximumAllowableItemsMessage:
      'Unable to add one or more products to your cart. The quantity would exceed the maximum allowed.',
    addedMaximumInStockItemsMessage:
      'Unable to add one or more products to your cart. The quantity would exceed the available inventory.',
    oneOrMoreItemsHaveReplacementsMessage:
      'One or more of the items you are reordering have been replaced with a substitute item.',
    couldNotAddItemsFailure:
      'Your chosen product(s) are either discontinued or out of stock at this time. Please try again in few days.',
    generalFailure:
      'Product(s) not added to cart. Please try again in some time.',
    addToCartSuccess: 'Product(s) successfully added to the cart.',
    tooltip: 'Available Product(s) and quantity will be added to your cart',
  },
};

export default checkout;
