/**
 * Footer component will be used to display footer on all the pages.
 */
import inViewport from 'vue-in-viewport-mixin';
import globals from '../globals';
import mobileMixin from '../mixins/mobile-mixin';

export default {
  name: 'vx-footer-section',
  components: {},
  props: {
    footerData: {
      type: Object,
      required: true,
    },
    copyrightText: {
      type: String,
    },
    isCheckoutFooter: {
      type: Boolean,
      required: true,
    },
    isHomePage: {
      type: Boolean,
      required: false,
    },
  },
  mixins: [mobileMixin, inViewport],
  data() {
    return {
      globals,
      socialLinks: {
        facebook: '',
        pinterest: '',
        linkedin: '',
        youtube: '',
        twitter: '',
        instagram: '',
        snapchat: '',
        socialText: '',
      },
      footerColumnLength: 4,
      footerDataLength: this.footerData.columns.length,
      eaIconName: '',
      lazyLoadDone: false,
    };
  },
  computed: {
    filteredFooterData() {
      let filteredFooterData = [];
      if (this.isCheckoutFooter) {
        filteredFooterData = this.footerData.columns;
      } else {
        filteredFooterData = this.footerData.columns.slice(
          0,
          this.footerDataLength - 1,
        );
      }
      return filteredFooterData;
    },
  },
  watch: {

    'inViewport.now': function(visible) {
      const stickyFormBottom = document.querySelector('.sticky-form.navbar-fixed-bottom');
      if (stickyFormBottom) {
        if (visible) {
          stickyFormBottom.style.position = 'relative';
        } else {
          stickyFormBottom.style.position = 'sticky';
        }
      }
    },
  },

  created() {
    this.getAdaIconName();
  },
  mounted() {
    const footerHeight = document.getElementsByClassName('footer-section')[0]
      .offsetHeight;
    this.$root.$emit('footer-height', footerHeight);
    this.setAdaIcon();
  },
  methods: {
    /**
      * In mobile mode - Handling accordion expand and collapse.
      * Setting aria labels for ADA purpose.
      * @param {*} event
      */
    toggle(event) {
      if (!this.isCheckoutFooter) {
        event.currentTarget.parentElement.classList.toggle('active');
        if (this.isTablet()) {
          if (
            event.currentTarget.children[0].classList.contains(
              'icon-chevron-down',
            )
          ) {
            event.currentTarget.children[0].classList.remove(
              'icon-chevron-down',
            );
            event.currentTarget.children[0].classList.add('icon-chevron-up');
            event.target.setAttribute('aria-expanded', true);
            event.target.setAttribute('aria-label', 'expanded');
          } else {
            event.currentTarget.children[0].classList.remove('icon-chevron-up');
            event.currentTarget.children[0].classList.add('icon-chevron-down');
            event.target.setAttribute('aria-expanded', false);
            event.target.setAttribute('aria-label', 'collapsed');
          }
        }
      }
    },
    getAdaIconName() {
      switch (this.globals.siteId) {
        case 'sparkle': {
          this.eaIconName = 'eaIcon-blue.png';
          break;
        }
        case 'innovia': {
          this.eaIconName = 'eaIcon-black.png';
          break;
        }
        case 'estores': {
          this.eaIconName = 'eaIcon-b2b.png';
          break;
        }
        default: {
          this.eaIconName = 'eaIcon-white.png';
          break;
        }
      }
    },
    setAdaIcon() {
      const lastElInfoColumn = document.querySelectorAll('.footer-section .footer__column')[0].lastElementChild;
      const eaIconEl = document.createElement('div');
      eaIconEl.className = 'px-xs-3 pl-sm-0 pb-xs-3 py-sm-2 footer__column__item';
      const eaLinkEl = document.createElement('a');
      eaLinkEl.href = 'https://www.essentialaccessibility.com/georgia-pacific-consumer-products?utm_source=georgiapacifichomepage&utm_medium=iconlarge&utm_term=eachannelpage&utm_content=header&utm_campaign=georgiapacificconsumerproducts';
      eaLinkEl.target = '_blank';
      const eaImgEl = document.createElement('img');
      eaImgEl.src = `${this.globals.assetsPath}images/${this.eaIconName}`;
      eaImgEl.style.height = '31px';
      eaImgEl.style.width = '80px';
      lastElInfoColumn.parentNode.insertBefore(eaIconEl, lastElInfoColumn.nextSibling);
      eaIconEl.appendChild(eaLinkEl);
      eaLinkEl.appendChild(eaImgEl);
      eaImgEl.alt = 'eA - Assistive Technology';
    },

  },

};
