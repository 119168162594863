import commonViewSiteContent from '../common/view-site-content-i18n';

const viewSiteContent = {
  storeLocator: {
    ...commonViewSiteContent.storeLocator,
    categoryLabel: 'All Estore Products',
  },
  findDistributor: {
    ...commonViewSiteContent.findDistributor
  },

  bannerCarousel: {
    carouselControlPrevious: 'Previous',
    carouselControlNext: 'Next',
    iconChevronLeftTitle: 'Left',
    iconChevronRightTitle: 'Right',
  },
  featureProduct: {
    iconPlayTitle: 'Play',
  },
  heroBanner: {
    iconPlayTitle: 'Play',
  },
  imageTile: {
    iconPlayTitle: 'Play',
  },
  imageTitle: {
    iconPlayTitle: 'Play',
  },
  productSolution: {
    iconPlayTitle: 'Play',
  },
};

export default viewSiteContent;
