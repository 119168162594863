var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", { staticClass: "footer-section" }, [
    _c(
      "div",
      {
        staticClass: "footer px-sm-5 pt-sm-5 mx-xs-0 row",
        class: { checkoutFooterBack: _vm.isCheckoutFooter }
      },
      [
        _c(
          "div",
          { staticClass: "d-flex flex-wrap px-sm-0 px-xs-0" },
          [
            _vm._l(_vm.filteredFooterData, function(item, index) {
              return _c(
                "div",
                {
                  staticClass: "non-printable-section footer__column",
                  class: { "checkout active": _vm.isCheckoutFooter }
                },
                [
                  _vm.isMobile()
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "my-xs-4 mx-xs-3 ml-sm-0 mt-sm-0 mb-sm-3 footer__column__heading",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.toggle($event)
                            }
                          }
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(item.title) + "\n          "
                          ),
                          _c("button", {
                            staticClass: "icon-chevron-down",
                            class: [
                              !_vm.isCheckoutFooter ? "visible-xs" : "hidden"
                            ],
                            attrs: { "aria-label": "collapsed" }
                          })
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isMobile()
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "my-xs-4 mx-xs-3 ml-sm-0 mt-sm-0 mb-sm-3 footer__column__heading"
                        },
                        [_vm._v(_vm._s(item.title) + "\n        ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._l(item.links, function(subItem, subIndex) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "px-xs-3 pl-sm-0 pb-xs-3 py-sm-2 footer__column__item"
                      },
                      [
                        subItem.linkText
                          ? _c(
                              "a",
                              {
                                attrs: {
                                  href: [
                                    subItem.external
                                      ? subItem.linkTo
                                      : _vm.globals.contextPath + subItem.linkTo
                                  ],
                                  target: [
                                    subItem.newWindow ? "_blank" : "_self"
                                  ]
                                }
                              },
                              [_vm._v(_vm._s(subItem.linkText))]
                            )
                          : _vm._e()
                      ]
                    )
                  })
                ],
                2
              )
            }),
            _vm._v(" "),
            _vm.isCheckoutFooter
              ? _c("div", {
                  staticClass:
                    "mx-xs-3 mx-sm-0 mb-sm-0 copy-right mt-xs-5 mt-sm-0",
                  domProps: { innerHTML: _vm._s(_vm.copyrightText) }
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm.isCheckoutFooter
              ? _c("div", { staticClass: "social-block footer__column" }, [
                  _vm.footerData.columns[_vm.footerDataLength - 1].title
                    ? _c(
                        "h2",
                        {
                          staticClass:
                            "mx-xs-3 mx-sm-0 my-xs-0 pt-xs-4 pb-xs-3 py-sm-0 footer__column__heading"
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.footerData.columns[_vm.footerDataLength - 1]
                                  .title
                              ) +
                              "\n        "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mx-xs-3 mx-sm-0 mt-sm-4 icons" },
                    [
                      _vm._l(
                        _vm.footerData.columns[_vm.footerDataLength - 1].links,
                        function(socialIcons) {
                          return [
                            _vm.isHomePage
                              ? [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "social-icons pr-xs-3",
                                      attrs: {
                                        itemscope: "",
                                        itemtype:
                                          _vm.globals.logoContentSchema
                                            .itemType,
                                        href: socialIcons.linkTo,
                                        target: "_blank",
                                        itemprop: "sameAs",
                                        rel: "me"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "my-xs-2",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/" +
                                            socialIcons.linkText.toLowerCase() +
                                            ".png",
                                          alt: socialIcons.linkText
                                        }
                                      })
                                    ]
                                  )
                                ]
                              : [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "social-icons pr-xs-3",
                                      attrs: {
                                        href: socialIcons.linkTo,
                                        target: "_blank"
                                      }
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "my-xs-2",
                                        attrs: {
                                          src:
                                            _vm.globals.assetsPath +
                                            "images/" +
                                            socialIcons.linkText.toLowerCase() +
                                            ".png",
                                          alt: socialIcons.linkText
                                        }
                                      })
                                    ]
                                  )
                                ]
                          ]
                        }
                      )
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mx-xs-3 mx-sm-0 my-xs-4 mb-sm-0 mt-sm-5 copy-right"
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.copyrightText) +
                          "\n        "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _vm.globals.getSiteId() !== "estores"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "mx-xs-3 mx-sm-0 my-xs-4 mb-sm-0 mt-sm-3 copy-right"
                        },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "ot-sdk-show-settings",
                              attrs: {
                                role: "link",
                                "aria-label":
                                  "Do Not Sell or Share My Personal Information",
                                id: "ot-sdk-btn"
                              }
                            },
                            [_vm._v("Cookies Settings")]
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.globals.siteConfig.isReCaptchaEnabled
                    ? _c(
                        "p",
                        { staticClass: "mt-xs-3 ml-xs-4 recaptchaFooterText" },
                        [
                          _vm._v(
                            "\n          This site is protected by reCAPTCHA and the Google\n          "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://policies.google.com/privacy",
                                target: "new"
                              }
                            },
                            [_vm._v("Privacy Policy")]
                          ),
                          _vm._v("\n          and\n          "),
                          _c(
                            "a",
                            {
                              attrs: {
                                href: "https://policies.google.com/terms",
                                target: "new"
                              }
                            },
                            [_vm._v("Terms of Service")]
                          ),
                          _vm._v("\n          apply.\n        ")
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e()
          ],
          2
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-cee1bc4e", { render: render, staticRenderFns: staticRenderFns })
  }
}