var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "search-browse-panel" }, [
    !_vm.hideComparePanel
      ? _c(
          "div",
          {
            staticClass: "compare-panel",
            attrs: { "aria-label": _vm.i18n.compareProductsA11y, tabindex: "0" }
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex compare-products justify-content-between"
              },
              [
                _c("h5", { staticClass: "panel-message" }, [
                  _vm._v(
                    _vm._s(_vm.i18n.panelMessage1) +
                      " " +
                      _vm._s(_vm.maxProductsCompare) +
                      " " +
                      _vm._s(_vm.i18n.panelMessage2)
                  )
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "compare-product-panel" },
              _vm._l(_vm.products, function(product) {
                return _c("div", { staticClass: "panel-tile" }, [
                  _c("div", { staticClass: "remove-product-option" }, [
                    _c("span", {
                      staticClass: "icon-x-circle",
                      attrs: {
                        role: "button",
                        tabindex: "0",
                        "aria-label": _vm.i18n.label.remove,
                        title: _vm.i18n.iconXCircleTitle
                      },
                      on: {
                        click: function($event) {
                          _vm.removePanel(product)
                        },
                        keyup: function($event) {
                          if (
                            !("button" in $event) &&
                            _vm._k(
                              $event.keyCode,
                              "enter-space",
                              undefined,
                              $event.key,
                              undefined
                            )
                          ) {
                            return null
                          }
                          _vm.removePanel(product)
                        }
                      }
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "remove-text" }, [
                      _vm._v(_vm._s(_vm.i18n.label.remove))
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-xs-12 tile-image d-flex justify-content-center"
                    },
                    [
                      product.images &&
                      product.images[0] &&
                      product.images[0].url
                        ? _c("img", {
                            staticClass: "compare-page-image",
                            attrs: {
                              tabindex: "0",
                              alt: decodeURI(product.name),
                              src: product.images[0].url
                            }
                          })
                        : _c("img", {
                            attrs: {
                              tabindex: "0",
                              src:
                                _vm.globals.assetsPath + "images/no_image.svg",
                              alt: decodeURI(product.name)
                            }
                          })
                    ]
                  )
                ])
              })
            ),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-end" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-tertiary",
                  attrs: { type: "button", disabled: _vm.disableButtonFalse },
                  on: {
                    click: function($event) {
                      _vm.viewDetails()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.primaryButtonText))]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "remove-icon-mobile" }, [
              _c("span", {
                staticClass: "icon-x",
                attrs: {
                  "aria-label": "Close panel",
                  tabindex: "0",
                  role: "button",
                  title: _vm.i18n.iconXTitle
                },
                on: {
                  click: _vm.closePanel,
                  keyup: function($event) {
                    if (
                      !("button" in $event) &&
                      _vm._k(
                        $event.keyCode,
                        "enter-space",
                        undefined,
                        $event.key,
                        undefined
                      )
                    ) {
                      return null
                    }
                    return _vm.closePanel($event)
                  }
                }
              })
            ])
          ]
        )
      : _c("div", { staticClass: "d-flex justify-content-end panel-mobile" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-tertiary compare-button",
              attrs: { type: "button" },
              on: { click: _vm.closePanel }
            },
            [_vm._v("Compare\n      " + _vm._s(_vm.products.length))]
          )
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-2165e913", { render: render, staticRenderFns: staticRenderFns })
  }
}