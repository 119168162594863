var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vx-order-history",
      class: { "pb-md-5": !_vm.orderHistoryData.length }
    },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "sortFilterModel",
          attrs: { size: "medium", heading: _vm.i18n.filterModalHeading }
        },
        [
          _c(
            "vx-order-history-filter",
            {
              attrs: { slot: "component", i18n: _vm.i18n },
              on: { filterData: _vm.filterApplicable },
              slot: "component"
            },
            [
              _c(
                "div",
                { attrs: { slot: "sortDropdown" }, slot: "sortDropdown" },
                [
                  _c("vx-dropdown-primary", {
                    ref: "sortDropdown",
                    attrs: { dropdownValues: _vm.sortOptions },
                    on: {
                      "selected-option": function($event) {
                        _vm.storeSort($event)
                      }
                    }
                  })
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "dateDropdown" }, slot: "dateDropdown" },
                [
                  _c("vx-dropdown-primary", {
                    ref: "orderHistoryData",
                    attrs: {
                      "aria-label": _vm.i18n.transactionYearAriaLabel,
                      dropdownValues: _vm.orderHistoryDropdownData.options
                    },
                    on: {
                      primaryDropdownMounted: _vm.populateDefaultDate,
                      "selected-item": function($event) {
                        _vm.dateFilterSelected($event)
                      }
                    }
                  })
                ],
                1
              )
            ]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "order-history-heading" }, [
        _c("h1", { staticClass: "my-xs-0 h3" }, [
          _vm._v(_vm._s(_vm.i18n.orderHistoryHeading))
        ]),
        _vm._v(" "),
        _vm.isMobile()
          ? _c("span", {
              staticClass: "order-transaction-filter icon-filter",
              attrs: {
                role: "button",
                tabindex: "0",
                "aria-label": _vm.i18n.iconFilterTitle,
                title: _vm.i18n.iconFilterTitle
              },
              on: {
                click: function($event) {
                  _vm.openFilterModel($event)
                },
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  _vm.openFilterModel($event)
                }
              }
            })
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.i18n.orderHistorySubheading
          ? _c(
              "div",
              {
                staticClass:
                  "pt-xs-3 pb-xs-4 pr-xs-5 sub-heading word-wrap-all col-sm-8"
              },
              [_vm._v(_vm._s(_vm.i18n.orderHistorySubheading))]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isMobile()
          ? _c(
              "div",
              { staticClass: "transaction-data-dropdown col-sm-4" },
              [
                _c("vx-dropdown-primary", {
                  ref: "orderHistoryData",
                  attrs: {
                    "aria-label": _vm.i18n.transactionYearAriaLabel,
                    dropdownValues: _vm.orderHistoryDropdownData.options
                  },
                  on: {
                    "selected-item": function($event) {
                      _vm.dateFilterSelected($event)
                    }
                  }
                })
              ],
              1
            )
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "pt-sm-0 pt-xs-3 pb-xs-4 no-of-orders" }, [
        _c("span", [_vm._v(_vm._s(_vm.i18n.noOfOrders))]),
        _vm._v(" "),
        _c("span", [_vm._v(_vm._s(_vm.totalOrders))])
      ]),
      _vm._v(" "),
      _vm.globals.isB2C()
        ? [
            _c(
              "div",
              { class: { "hide-table": !_vm.orderHistoryData.length } },
              [
                _c("vx-table", {
                  attrs: {
                    tableConfig: _vm.tableConfig,
                    tableData: _vm.orderHistoryData
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            !_vm.orderHistoryData.length
              ? _c("div", { staticClass: "empty-table" }, [
                  _c("div", [
                    _c("p", { staticClass: "mb-xs-0" }, [
                      _vm._v(_vm._s(_vm.i18n.emptyTableMessage))
                    ])
                  ])
                ])
              : _vm._e()
          ]
        : [
            _c("vx-table", {
              attrs: {
                tableConfig: _vm.tableConfig,
                tableData: _vm.orderHistoryData
              }
            })
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-d8efc0a6", { render: render, staticRenderFns: staticRenderFns })
  }
}