var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "access-customer-service-service-ticket" },
    [
      _c("vx-spinner", {
        ref: "spinner",
        attrs: {
          image: _vm.globals.assetsPath + "images/spinner.gif",
          "full-screen": ""
        }
      }),
      _vm._v(" "),
      _c(
        "vx-modal",
        {
          ref: "fileAttachModal",
          attrs: {
            size: "extra-small",
            heading: _vm.i18n.fileAttachment.heading
          }
        },
        [
          _c("vx-contactus-upload", {
            attrs: {
              slot: "component",
              i18n: _vm.i18n.fileAttachment,
              attachments: _vm.attachments
            },
            on: {
              upload: function($event) {
                _vm.fileUploaded($event)
              }
            },
            slot: "component"
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("form", { ref: "ticketForm" }, [
        _c("div", { staticClass: "serviceticket-section pt-sm-5" }, [
          _c("div", { staticClass: "d-flex align-items-center heading-info" }, [
            _c("span", {
              staticClass: "icon-mail",
              attrs: {
                title: _vm.i18n.iconMailTitle,
                "aria-label": _vm.i18n.iconMailTitle
              }
            }),
            _vm._v(" "),
            _c("h2", { staticClass: "heading pl-xs-2 my-xs-0" }, [
              _vm._v(_vm._s(_vm.i18n.heading))
            ])
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "form-group mt-xs-0" },
            [
              _c(
                "label",
                {
                  attrs: {
                    for: "topicOfInquiry",
                    "aria-hidden": !_vm.isTablet()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.topicOfInquiry))]
              ),
              _vm._v(" "),
              _c("vx-dropdown-primary", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                ref: "topicDropdown",
                attrs: {
                  "describedby-aria": "enquiry_error_msg",
                  "required-aria": "true",
                  dropdownValues: _vm.topicOfInquiryList,
                  "aria-label": _vm.i18n.topicOfInquiry,
                  name: "topicOfInquiry",
                  id: "topicOfInquiry",
                  dropdownError: _vm.errors.has("topicOfInquiry")
                },
                on: {
                  "selected-option": function($event) {
                    _vm.topicOfInquiry = $event
                  }
                },
                model: {
                  value: _vm.topicOfInquiry,
                  callback: function($$v) {
                    _vm.topicOfInquiry = $$v
                  },
                  expression: "topicOfInquiry"
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("topicOfInquiry"),
                      expression: "errors.has('topicOfInquiry')"
                    }
                  ],
                  staticClass: "mt-xs-2"
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: {
                        "aria-live": "assertive",
                        id: "enquiry_error_msg"
                      }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("topicOfInquiry")))]
                  )
                ]
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("fieldset", [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "firstName",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.firstName))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.firstName,
                    expression: "form.firstName"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: /^[A-Za-z\s\’'-]+$/,
                      max: 40
                    },
                    expression:
                      "{ required: true,\n          regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 40 }"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("firstName") },
                attrs: {
                  type: "text",
                  maxlength: "40",
                  id: "firstName",
                  name: "firstName",
                  "aria-label": _vm.i18n.firstName,
                  "aria-describedby": _vm.errors.has("firstName")
                    ? "firstname_error_msg"
                    : null
                },
                domProps: { value: _vm.form.firstName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "firstName", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("firstName")
                ? _c(
                    "div",
                    {
                      staticClass: "mt-xs-2",
                      attrs: { "aria-hidden": !_vm.errors.has("firstName") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "firstname_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("firstName")))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "lastName",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.lastName))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.lastName,
                    expression: "form.lastName"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: {
                      required: true,
                      regex: /^[A-Za-z\s\’'-]+$/,
                      max: 80
                    },
                    expression:
                      "{ required: true,\n          regex: /^[A-Za-z\\s\\’'-]+$/ ,max: 80 }"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("lastName") },
                attrs: {
                  type: "text",
                  maxlength: "80",
                  id: "lastName",
                  name: "lastName",
                  "aria-label": _vm.i18n.lastName,
                  "aria-describedby": _vm.errors.has("lastName")
                    ? "lastname_error_msg"
                    : null
                },
                domProps: { value: _vm.form.lastName },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "lastName", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("lastName")
                ? _c(
                    "div",
                    {
                      staticClass: "mt-xs-2",
                      attrs: { "aria-hidden": !_vm.errors.has("lastName") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "lastname_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("lastName")))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: { for: "email", "aria-hidden": _vm.isDeviceAndroid() }
                },
                [_vm._v(_vm._s(_vm.i18n.email))]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email|max:80",
                    expression: "'required|email|max:80'"
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.email,
                    expression: "form.email"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("email") },
                attrs: {
                  type: "text",
                  maxlength: "80",
                  name: "email",
                  "aria-label": _vm.i18n.email,
                  id: "email"
                },
                domProps: { value: _vm.form.email },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "email", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("email")
                ? _c(
                    "div",
                    {
                      staticClass: "mt-xs-2",
                      attrs: { "aria-label": !_vm.errors.has("email") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: { "aria-live": "polite", role: "alert" }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("email")))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c(
                  "label",
                  {
                    attrs: {
                      for: "telephone",
                      "aria-hidden": _vm.isDeviceAndroid()
                    }
                  },
                  [
                    _vm._v(_vm._s(_vm.i18n.phone) + "\n          "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.i18n.optional))
                    ])
                  ]
                ),
                _vm._v(" "),
                _c("the-mask", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "min:15",
                      expression: "'min:15'"
                    }
                  ],
                  staticClass: "phone-number form-control",
                  attrs: {
                    masked: _vm.masked,
                    "aria-label": _vm.i18n.phone + " " + _vm.i18n.optional,
                    mask: "+1 ###-###-####",
                    type: "tel",
                    name: "telephone",
                    id: "telephone"
                  },
                  model: {
                    value: _vm.form.phone,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "phone", $$v)
                    },
                    expression: "form.phone"
                  }
                }),
                _vm._v(" "),
                _vm.errors.has("telephone")
                  ? _c("div", { staticClass: "mt-xs-2" }, [
                      _c("span", { staticClass: "error-msg" }, [
                        _vm._v(_vm._s(_vm.errors.first("telephone")))
                      ])
                    ])
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _vm.globals.isB2B()
              ? _c("div", { staticClass: "form-group" }, [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "jobTitle",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [
                      _vm._v(_vm._s(_vm.i18n.jobTitle) + "\n          "),
                      _c("small", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.i18n.optional))
                      ])
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.jobTitle,
                        expression: "form.jobTitle"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:80",
                        expression: "'max:80'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      "aria-label": _vm.i18n.jobTitle + " " + _vm.i18n.optional,
                      id: "jobTitle",
                      maxlength: "80"
                    },
                    domProps: { value: _vm.form.jobTitle },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "jobTitle", $event.target.value)
                      }
                    }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.globals.isB2B()
              ? _c("div", { staticClass: "form-group" }, [
                  _c("label", { attrs: { for: "company" } }, [
                    _vm._v(_vm._s(_vm.i18n.company) + "\n          "),
                    _c("small", { staticClass: "text-muted" }, [
                      _vm._v(_vm._s(_vm.i18n.optional))
                    ])
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.companyName,
                        expression: "form.companyName"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "max:80",
                        expression: "'max:80'"
                      }
                    ],
                    staticClass: "form-control",
                    attrs: {
                      type: "text",
                      "aria-label": _vm.i18n.company + " " + _vm.i18n.optional,
                      id: "company",
                      maxlength: "80"
                    },
                    domProps: { value: _vm.form.companyName },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "companyName", $event.target.value)
                      }
                    }
                  })
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "address",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.streetAddress) + "\n        ")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.streetAddress,
                    expression: "form.streetAddress"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:60",
                    expression: "'required|max:60'"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("address") },
                attrs: {
                  type: "text",
                  id: "address",
                  maxlength: "60",
                  name: "address",
                  "aria-label": _vm.i18n.streetAddress,
                  "aria-describedby": _vm.errors.has("address")
                    ? "address_error_msg"
                    : null
                },
                domProps: { value: _vm.form.streetAddress },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "streetAddress", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("address")
                ? _c(
                    "div",
                    { attrs: { "aria-hidden": !_vm.errors.has("address") } },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "address_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("address")))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "form-group" },
              [
                _c("div", [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "country",
                        "aria-hidden": !_vm.isTablet() && _vm.isDeviceAndroid()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.country))]
                  )
                ]),
                _vm._v(" "),
                _c("vx-dropdown-primary", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  ref: "countryDropdown",
                  attrs: {
                    "required-aria": "true",
                    id: "country",
                    dropdownValues: _vm.i18n.countryList,
                    "aria-label": _vm.i18n.country,
                    name: "country"
                  },
                  on: {
                    "selected-option": function($event) {
                      _vm.setCountry($event)
                    }
                  },
                  model: {
                    value: _vm.form.country,
                    callback: function($$v) {
                      _vm.$set(_vm.form, "country", $$v)
                    },
                    expression: "form.country"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "inputCity",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.city) + "\n        ")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.city,
                    expression: "form.city"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|max:80",
                    expression: "'required|max:80'"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("city") },
                attrs: {
                  type: "text",
                  id: "inputCity",
                  name: "city",
                  "aria-label": _vm.i18n.city,
                  maxlength: "80",
                  "aria-describedby": _vm.errors.has("city")
                    ? "address_city_error_msg"
                    : null
                },
                domProps: { value: _vm.form.city },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "city", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _vm.errors.has("city")
                ? _c(
                    "div",
                    {
                      staticClass: " mt-xs-2",
                      attrs: { "aria-label": !_vm.errors.has("city") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "polite",
                            role: "alert",
                            id: "address_city_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("city")))]
                      )
                    ]
                  )
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-xs-12 px-xs-0 mb-xs-4" }, [
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-0 pr-sm-3 px-xs-0" },
                [
                  _c("div", [
                    _c(
                      "label",
                      {
                        attrs: {
                          for: "state",
                          "aria-hidden":
                            !_vm.isTablet() && _vm.isDeviceAndroid()
                        }
                      },
                      [_vm._v(_vm._s(_vm.i18n.state) + "\n            ")]
                    )
                  ]),
                  _vm._v(" "),
                  _c("vx-dropdown-primary", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    ref: "regionDropdown",
                    attrs: {
                      "describedby-aria": "address_state_error_msg",
                      "required-aria": "true",
                      dropdownValues: _vm.regionList,
                      name: "state",
                      id: "state",
                      "aria-label": _vm.i18n.state,
                      dropdownError: _vm.errors.has("state")
                    },
                    on: {
                      "selected-option": function($event) {
                        _vm.form.region = $event.label
                      }
                    },
                    model: {
                      value: _vm.form.region,
                      callback: function($$v) {
                        _vm.$set(_vm.form, "region", $$v)
                      },
                      expression: "form.region"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.errors.has("state"),
                          expression: "errors.has('state')"
                        }
                      ],
                      staticClass: "mt-xs-2"
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: {
                            "aria-live": "assertive",
                            id: "address_state_error_msg"
                          }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("state")))]
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "form-group col-sm-6 pl-sm-3 pr-sm-0 px-xs-0" },
                [
                  _c(
                    "label",
                    {
                      attrs: {
                        for: "inputZip",
                        "aria-hidden": _vm.isDeviceAndroid()
                      }
                    },
                    [_vm._v(_vm._s(_vm.i18n.postalCode))]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.postalCode,
                        expression: "form.postalCode"
                      },
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: _vm.zipCodeValidation(),
                        expression: "zipCodeValidation()"
                      }
                    ],
                    staticClass: "form-control",
                    class: { "input-error": _vm.errors.has("zipcode") },
                    attrs: {
                      type: "text",
                      id: "inputZip",
                      maxlength: "20",
                      name: "zipcode",
                      "aria-label": _vm.i18n.postalCode,
                      "aria-describedby": _vm.errors.has("zipcode")
                        ? "address_zip_error_msg"
                        : null
                    },
                    domProps: { value: _vm.form.postalCode },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(_vm.form, "postalCode", $event.target.value)
                      }
                    }
                  }),
                  _vm._v(" "),
                  _vm.errors.has("zipcode")
                    ? _c(
                        "div",
                        {
                          staticClass: "mt-xs-2",
                          attrs: { "aria-label": !_vm.errors.has("zipcode") }
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass: "error-msg",
                              attrs: {
                                "aria-live": "polite",
                                role: "alert",
                                id: "address_zip_error_msg"
                              }
                            },
                            [_vm._v(_vm._s(_vm.errors.first("zipcode")))]
                          )
                        ]
                      )
                    : _vm._e()
                ]
              )
            ])
          ]),
          _vm._v(" "),
          _c("fieldset", [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c("label", { attrs: { for: "orderId" } }, [
                _vm._v(_vm._s(_vm.i18n.orderId) + "\n          "),
                _c("small", { staticClass: "text-muted" }, [
                  _vm._v(_vm._s(_vm.i18n.optional))
                ])
              ]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.orderNumber,
                    expression: "form.orderNumber"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: { regex: /^[a-z\d\-\s]+$/, max: 20 },
                    expression: "{regex: /^[a-z\\d\\-\\s]+$/,max: 20 }"
                  }
                ],
                staticClass: "form-control",
                class: { "input-error": _vm.errors.has("orderId") },
                attrs: {
                  type: "text",
                  id: "orderId",
                  maxlength: "20",
                  name: "orderId"
                },
                domProps: { value: _vm.form.orderNumber },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "orderNumber", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.errors.has("orderId"),
                      expression: "errors.has('orderId')"
                    }
                  ],
                  staticClass: "mt-xs-2",
                  attrs: { "aria-label": !_vm.errors.has("orderId") }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "error-msg",
                      attrs: { id: "orderId_error_msg" }
                    },
                    [_vm._v(_vm._s(_vm.errors.first("orderId")))]
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "form-group" }, [
              _c(
                "label",
                {
                  attrs: {
                    for: "comments",
                    "aria-hidden": _vm.isDeviceAndroid()
                  }
                },
                [_vm._v(_vm._s(_vm.i18n.questionsOrComments) + "\n        ")]
              ),
              _vm._v(" "),
              _c("textarea", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.ticketComments,
                    expression: "form.ticketComments"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "form-control rounded-0",
                class: { "input-error": _vm.errors.has("comments") },
                attrs: {
                  maxlength: "1024",
                  type: "text",
                  id: "comments",
                  name: "comments",
                  rows: "3",
                  "aria-label": _vm.i18n.questionsOrComments
                },
                domProps: { value: _vm.form.ticketComments },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.form, "ticketComments", $event.target.value)
                  }
                }
              }),
              _vm._v(" "),
              _c("p", { staticClass: "info-text mb-xs-0 mt-xs-2" }, [
                _c("span", {
                  domProps: {
                    textContent: _vm._s(1024 - _vm.form.ticketComments.length)
                  }
                }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(_vm.i18n.commentsCharacterCount))])
              ]),
              _vm._v(" "),
              _vm.errors.has("comments")
                ? _c(
                    "div",
                    {
                      staticClass: "mt-xs-2",
                      attrs: { "aria-label": !_vm.errors.has("comments") }
                    },
                    [
                      _c(
                        "span",
                        {
                          staticClass: "error-msg",
                          attrs: { "aria-live": "polite", role: "alert" }
                        },
                        [_vm._v(_vm._s(_vm.errors.first("comments")))]
                      )
                    ]
                  )
                : _vm._e()
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-xs-4 pt-md-2" }, [
            _c(
              "a",
              {
                class: { disabled: _vm.disableUpload },
                attrs: {
                  disabled: _vm.disableUpload,
                  href: "#",
                  role: "button",
                  "aria-label": _vm.i18n.chooseFile
                },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.attachFiles($event)
                  }
                }
              },
              [_vm._v(_vm._s(_vm.i18n.chooseFile) + "\n        ")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mt-xs-2 pt-md-2" }, [
            _vm.fileSizeExceed
              ? _c("span", { staticClass: "error-msg" }, [
                  _vm._v(_vm._s(_vm.i18n.fileSizeError))
                ])
              : _vm._e()
          ]),
          _vm._v(" "),
          _vm.attachments.length !== 0
            ? _c(
                "div",
                { staticClass: "uploaded-files mt-xs-4" },
                _vm._l(_vm.attachments, function(item, key) {
                  return _c(
                    "div",
                    { staticClass: "d-flex align-items-center file" },
                    [
                      _c("span", { staticClass: "icon-attachment" }),
                      _c("span", { staticClass: "file-name" }, [
                        _vm._v(_vm._s(_vm.getFileName(item.name)))
                      ]),
                      _vm._v(" "),
                      _c(
                        "a",
                        {
                          staticClass: "pl-xs-4",
                          attrs: { "data-value": item.name },
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.removeAttachment(key, $event)
                            }
                          }
                        },
                        [_vm._v(_vm._s(_vm.i18n.remove))]
                      )
                    ]
                  )
                })
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass:
                "submit btn button-color text-trans mt-xs-4 mt-sm-5 mb-xs-5 mb-md-4",
              attrs: {
                type: "submit",
                disabled: _vm.errors.any() || _vm.fileSizeExceed
              },
              on: {
                click: _vm.handleSubmit,
                keyup: function($event) {
                  if (
                    !("button" in $event) &&
                    _vm._k(
                      $event.keyCode,
                      "enter-space",
                      undefined,
                      $event.key,
                      undefined
                    )
                  ) {
                    return null
                  }
                  return _vm.handleSubmit($event)
                }
              }
            },
            [_vm._v(_vm._s(_vm.i18n.submit))]
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Contact Information")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [_vm._v("Contact Address")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("legend", { staticClass: "legend_ai" }, [
      _c("span", { staticClass: "sr-only" }, [
        _vm._v("Order Number and Comments")
      ])
    ])
  }
]
render._withStripped = true
export { render, staticRenderFns }
if (module.hot) {
  module.hot.accept()
  if (module.hot.data) {
    require("vue-hot-reload-api")      .rerender("data-v-3e911aaf", { render: render, staticRenderFns: staticRenderFns })
  }
}