/**
 * Add to Compare Panel component is the panel which shows up bottom of the search results page
 * This component comes up when products are selected for comparison from search results page
 */

import FocusTrap from 'vue-focus-lock';
import {
  eventBus,
} from '../../../modules/event-bus';
import cookiesMixin from '../../common/mixins/cookies-mixin';
import globals from '../../common/globals';

export default {
  props: {
    /**
     * Copy text coming from properties file
     */
    i18n: {
      type: Object,
    },
    /**
     * product data selected for comparison
     */
    comparedProducts: {
      type: Array,
    },
    /**
     * indicates whether to disable button
     */
    disableButtonFalse: {
      type: Boolean,
      default: false,
    },
    /**
     * maximum products to be compared
     */
    maxProductsCompare: {
      type: Boolean,
      default: false,
    },
  },
  name: 'vx-add-to-compare-panel',
  mixins: [cookiesMixin],
  components: {
    FocusTrap,
  },
  data() {
    return {
      buttonDisabled: false,
      globals,
      hideComparePanel: false,
      products: '',
    };
  },
  methods: {
    /**
     * This function is triggered on click of view details link which navigates to product comparison page
     */
    viewDetails() {
      const self = this;
      let compareProductsString = '';
      const prodArr = self.products.map((item) => item.code);
      compareProductsString = self.generateProductsString(prodArr);
      this.globals.navigateToUrlWithParams(
        'compareProducts',
        compareProductsString,
        'productCodes',
      );
    },
    /**
     * This function is used to remove product from compare panel
     */
    removePanel(event) {
      this.removeCompareProductFromCookie('CompareCookie', event);
      this.products.splice(this.products.indexOf(event), 1);
      eventBus.$emit('productLength', this.products.length);
      eventBus.$emit('removeFromPanel', event.code);
      this.$emit('panelDelete', event.code);
    },
    /**
     * This function is used to remove product from compare cookie
     */
    removeCompareProductFromCookie(cookieName, cookieVal) {
      if (this.readCookie(cookieName)) {
        const cookieData = JSON.parse(this.readCookie(cookieName));
        cookieData.forEach((item, index) => {
          if (item.code === cookieVal.code) {
            cookieData.splice(index, 1);
          }
        });
        this.createCookie(cookieName, JSON.stringify(cookieData));
      }
    },
    /**
     * This function is used to generate query string
     * @param  {Array} prodArr array of product codes
     */
    generateProductsString(prodArr) {
      return prodArr.join(':');
    },
    /**
     * This function is used to hide show compare panel
     */
    closePanel() {
      this.hideComparePanel = !this.hideComparePanel;
    },
  },
  created() {
    const self = this;
    document.body.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        self.closePanel();
      }
    });
  },
  mounted() {
    this.products = this.comparedProducts;
  },
  watch: {
    // watch to update data passed by Prop
    comparedProducts(data) {
      this.$nextTick(() => {
        this.products = data;
      });
    },
  },
};
