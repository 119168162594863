import axios from 'axios';
import { Validator } from 'vee-validate';
import detectDeviceMixin from '../../common/mixins/detect-device-mixin';
import mobileMixin from '../../common/mixins/mobile-mixin';
import CommonService from '../../common/services/common-service';
import vxDropdownPrimary from '../../common/vx-dropdown-primary/vx-dropdown-primary.vue';
import vxSpinner from '../../common/vx-spinner/vx-spinner.vue';
import globals from '../../common/globals';
import RegistrationLoginService from '../../common/services/registration-login-service';
import flyoutBannerMixin from '../../common/vx-flyout-banner/vx-flyout-banner-mixin';
import { defaultCountry, flyoutStatus } from '../../common/mixins/vx-enums';
import optInMixin from '../../common/mixins/optin-mixin';
import PardotService from '../../common/services/pardot-service';
import googleMapsMixin from '../../common/mixins/google-maps-mixin';

export default {
  name: 'vx-create-account-estores',
  mixins: [detectDeviceMixin, mobileMixin, flyoutBannerMixin, optInMixin, googleMapsMixin],
  components: {
    vxDropdownPrimary,
    vxSpinner,
  },
  props: {
    i18n: Object,
  },
  data() {
    return {
      globals,
      commonService: new CommonService(),
      registrationLoginService: new RegistrationLoginService(),
      PardotService: new PardotService(),
      user: {
        profileName: '',
        companyName: '',
        firstName: '',
        lastName: '',
        emailAddress: '',
        phoneNumber: '',
        ext: '',
        jobTitle: '',
        country: '',
        state: '',
        zipCode: '',
        address1: '',
        address2: '',
        city: '',
        termsConditionChecked: false,
        b2bMailerCheck: false,
        confirmEmail: '',
      },
      countryList: {
        label: 'Country',
        options: [],
      },
      regionList: {
        label: 'State',
        options: [],
      },
      defaultCountry,
      autocomplete: {},
      selectedAutofillAddress: false,
      unverifiedAddress: {},
      usingGoogleAddr: false,
      prePopulateProfileName: null,
      isEmailMismatch: false,
      enableBriteVerify: true /* false */,
      API_URL: '/gpcommercewebservices/v2/gppro/apiproxy',
      config: {
        authUrl: '/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials',
        urlPrefix: '',
        auth_token: '',
      },
    };
  },
  mounted() {
    const self = this;
    const initAutoComplete = function() {
      self.autocomplete = new self.google.maps.places.Autocomplete(
        document.getElementById('autocompleteEstores'),
        { types: ['geocode'] },
      );
      self.autocomplete.setFields(['address_component']);
      const contriesRestrictions = self.globals.isB2C() ? ['us'] : ['us', 'ca'];
      self.autocomplete.setComponentRestrictions({
        country: contriesRestrictions,
      });
      self.autocomplete.addListener('place_changed', self.fillInAddress);
    };

    if (this.google) {
      initAutoComplete();
    } else {
      this.$on('mapMounted', () => {
        initAutoComplete();
      });
    }

    this.callCountryService();
    this.user.profileName = this.globals.getUrlParam('profileName') || '';
    this.prePopulateProfileName = this.globals.getUrlParam('profileName');
    this.submitButton = this.$el.querySelector('.estoresRegSubmitBtn');
    const veeCustomErrorMessage = {
      en: {
        custom: {
          profileName: {
            required: this.i18n.profileNameRequiredError,
            regex: this.i18n.profileNameInvalidError,
            max: this.i18n.profileNameInvalidError,
          },
          companyName: {
            required: this.i18n.companyNameRequiredError,
            max: this.i18n.companyNameMaxError,
          },
          firstName: {
            required: this.i18n.firstNameRequiredError,
          },
          lastName: {
            required: this.i18n.lastNameRequiredError,
          },
          emailAddress: {
            required: this.i18n.emailAddressRequiredError,
            email: this.i18n.emailAddressInvalidError,
          },
          phoneNumber: {
            required: this.i18n.phoneNumberRequiredError,
            min: this.i18n.phoneNumberMinEror,
          },
          jobTitle: {
            required: this.i18n.jobTitleRequiredError,
          },
          address1: {
            required: this.i18n.address1RequiredError,
          },
          city: {
            required: this.i18n.cityRequiredError,
          },
          country: {
            required: this.i18n.countryRequiredError,
          },
          state: {
            required: this.i18n.stateRequiredError,
          },
          zipCode: {
            required: this.i18n.zipCodeRequiredError,
          },
          termsCondition: {
            required: this.i18n.termsConditionRequiredError,
          },
          ext: {
            regex: this.i18n.extInvalidError,
          },
          confirmEmail: {
            required: this.i18n.confirmEmailRequiredError,
          },
        },
      },
    };
    Validator.localize(veeCustomErrorMessage);
  },
  methods: {
    /**
     * Calls the service for populating all the countries
     */
    callCountryService() {
      this.$refs.spinner.showSpinner();
      this.commonService.getCountries(
        {},
        this.handleGetCountriesResponse,
        this.handleGetCountriesError,
      );
      this.callRegionService(this.defaultCountry);
    },
    /**
     * Populates the country details in the Frontend and sets United States of America in the Country dropdown
     * @param {object} response
     */
    handleGetCountriesResponse(response) {
      this.$refs.spinner.hideSpinner();
      if (response && response.data) {
        this.countryData = response.data.countries.reverse();
        this.$refs.countryDropdown.resetDropdown();
        this.countryList.options = [];
        for (let i = 0; i < this.countryData.length; i += 1) {
          this.$set(this.countryList.options, i, {
            label: this.countryData[i].name,
            value: this.countryData[i].isocode,
          });
        }
      }
      this.$refs.countryDropdown.setDropdownLabel(this.defaultCountry.label);
    },
    /**
     * Hides the spinner when the service call for populating the Country fails
     */
    handleGetCountriesError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Calls the service for populating states
     * @param {object} evt
     */
    callRegionService(evt) {
      if (evt.value === this.user.country.value) {
        this.user.country = evt;
      } else {
        this.$refs.spinner.showSpinner();
        this.user.country = evt;
        this.commonService.getRegions(
          {},
          this.handleGetRegionsResponse,
          this.handleGetRegionsError,
          evt.value,
        );
      }
    },
    /**
     * Populates the state details in the Frontend and sets the first state as selected in the dropdown
     * @param {object} response
     */
    handleGetRegionsResponse(response) {
      this.$refs.spinner.hideSpinner();
      this.regionsData = response.data.regions;
      this.$refs.stateDropdown.resetDropdown();
      this.regionList.options = [];
      for (let i = 0; i < this.regionsData.length; i += 1) {
        this.$set(this.regionList.options, i, {
          label: this.regionsData[i].name,
          value: this.regionsData[i].isocodeShort,
          countryIso: this.regionsData[i].countryIso,
          isocode: this.regionsData[i].isocode,
        });
      }
      // if (this.regionList.options && this.regionList.options.length) {
      //   this.$refs.stateDropdown.setDropdownLabel(
      //     this.regionList.options[0].label,
      //   );
      //   this.user.state = this.regionList.options[0];
      // }
    },
    /**
     * Hides the spinner when service call for populating the state
     */
    handleGetRegionsError() {
      this.$refs.spinner.hideSpinner();
    },
    /**
     * Carries out Validations when register button is pressed and registerUser call happens when all validation pass
     * @param {object} event
     */
    submitForm(event) {
      const self = this;
      event.preventDefault();
      this.$validator.validateAll().then((result) => {
        if (result) {
          self.DisableSubmit();
          self.ValidateBriteVerify((success) => {
            if (success) {
              const requestConfig = {};
              requestConfig.data = self.createRequestBody();
              self.registrationLoginService.registerUser(
                requestConfig,
                self.handleRegisterResponse,
                self.handleRegisterError,
              );
              // TODO: See if business would like to display a message here instead of only hiding spinner
              self.$refs.spinner.showSpinner(self.globals.pardotFailureTimeout, self.handlePostRegistration);
            } else {
              self.showFlyout(
                'error',
                'Error! The value of the email field is not valid',
                false,
              );
              self.EnableSubmit();
            }
          });
        } else {
          this.globals.setFocusByName(this.$el, this.globals.getElementName(this.errors));
        }
      });
    },
    /**
     * Creates request body for registration
     */
    createRequestBody() {
      const reqbody = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        password: '',
        country: this.user.country.value,
        titleCode: 'mr',
        gender: '',
        dateOfBirth: '',
        uid: this.user.emailAddress,
        addToMarketComm: this.user.b2bMailerCheck,
        profileName: this.user.profileName,
        jobTitle: this.user.jobTitle,
        jobDescription: '',
        address: {
          country: {
            isocode: this.user.country.value,
            name: this.user.country.label,
          },
          firstName: this.user.firstName,
          line1: this.user.address1,
          line2: this.user.address2,
          postalCode: this.user.zipCode,
          town: this.user.city,
          region: {
            countryIso: this.user.country.value,
            isocode: `${this.user.country.value}-${this.user.state.value}`,
            isocodeShort: this.user.state.value,
            name: this.user.state.label,
          },
          lastName: this.user.lastName,
          titleCode: 'mr',
          phone: this.user.phoneNumber,
          companyName: this.user.companyName,
        },
      };
      return reqbody;
    },
    /**
     * Once the registration is complete, user will be redirected to login page and a flyout message will be shown.
     */
    handleRegisterResponse() {
      if (this.user.b2bMailerCheck) {
        const { emailAddress } = this.user;
        this.PardotService.readProspectWithEmail(emailAddress, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
      } else {
        this.handlePostRegistration();
      }
    },

    handlePostRegistration() {
      this.$refs.spinner.hideSpinner();
      this.showFlyoutNextPage(
        'success',
        this.i18n.registerSuccessMsg,
        false,
        true,
      );
      window.location.href = this.globals.getNavigationUrl('login');
    },
    /**
     * Hides the spinner when service call for registration fails
     */
    handleRegisterError(error) {
      const { errors } = error.response.data;
      /* const errorMsg =
        errors && errors[0].code === '197'
          ? this.i18n.registrationExpirationProfileErrorMsg
          : this.i18n.registrationErrorMsg; */
      let errorMsg;
      if (errors && errors[0].code === '9007') {
        errorMsg = this.i18n.registrationEmailAlreadyExistsErrorMsg;
      } else if (errors && errors[0].code === '9008') {
        errorMsg = this.i18n.registrationProfileNotFoundErrorMsg;
      } else if (errors && errors[0].code === '197') {
        errorMsg = this.i18n.registrationExpirationProfileErrorMsg;
      } else if (errors && errors[0].message === '210') {
        errorMsg = this.i18n.registrationDomainErrorMsg;
      }

      this.$refs.spinner.hideSpinner();
      this.showFlyout(flyoutStatus.error, errorMsg, false);
    },

    handleCommunicationPreferencesError(response) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const status = xmlDoc.getElementsByTagName('rsp')[0].getAttribute('stat');
      if (status === 'fail') {
        // user profile does not exist
        const code = xmlDoc.getElementsByTagName('err')[0].getAttribute('code');
        if (code === '4') {
          const {
            emailAddress, country: { value }, firstName, lastName, address1, address2, zipCode, state: { label }, phoneNumber, jobTitle, city,
          } = this.user;
          const params = `${emailAddress}?country=${value}&first_name=${firstName}&last_name=${lastName}&address_one=${address1}&address_two=${address2}&state=${label}&zip=${zipCode}&city=${city}&phone=${phoneNumber}&job_title=${jobTitle}&source=estores-Registration&campaign_id=58469`.replace(/ /g, '%20');
          this.PardotService.createProspect(params, () => {
            this.handlePostRegistration();
          }, () => {
            // error creating prospect
            this.handlePostRegistration();
          });
        } else {
          this.handlePostRegistration();
        }
      }
    },
    handleCommunicationPreferences(response) {
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(response, 'text/xml');
      const optedOut = xmlDoc.getElementsByTagName('opted_out')[0].innerHTML === '1';
      const prospectID = xmlDoc.getElementsByTagName('id')[0].innerHTML;
      // check if user has already been opted-out
      if (optedOut && prospectID !== null) {
        // opt-in the user since user checked the checkbox
        this.PardotService.updateProspect(`${prospectID}?opted_out=0`, this.handleUpdatePreferences, this.handleUpdatePreferencesError);
      }
      this.handlePostRegistration();
    },

    handleEmailChange(e) {
      if (this.globals.isB2B()) {
        const self = this;
        this.$refs.spinner.showSpinner(self.globals.pardotFailureTimeout, self.handlePostRegistration);
        const email = e.target.value;
        this.PardotService.readProspectWithEmail(email, this.handleCommunicationPreferences, this.handleCommunicationPreferencesError);
      }
    },
    updateEmailMismatch() {
      if (this.user.emailAddress === this.user.confirmEmail) {
        this.isEmailMismatch = false;
      } else {
        this.isEmailMismatch = true;
      }
    },
    handlePasteAction(evt) {
      evt.preventDefault();
    },
    fillInAddress() {
      this.selectedAutofillAddress = true;
      const place = this.autocomplete.getPlace();
      let streetNum = '';
      let streetName = '';
      let zipcode = '';
      let zipcodeSuffix = '';
      for (let i = 0; i < place.address_components.length; i += 1) {
        const addressType = place.address_components[i].types[0];
        const addressVal = place.address_components[i];
        switch (addressType) {
          case 'street_number':
            streetNum = addressVal.long_name;
            break;
          case 'route':
            streetName += ` ${addressVal.long_name}`;
            break;
          case 'locality':
            this.user.city = addressVal.long_name;
            break;
          case 'administrative_area_level_1':
            this.$refs.stateDropdown.setDropdownLabel(addressVal.long_name);
            this.user.state = {
              label: addressVal.long_name,
              value: addressVal.short_name,
            };
            // this.setDropdownOption('region', this.$refs.stateDropdown.selectedItem);
            break;
          case 'country':
            this.$refs.countryDropdown.setDropdownLabel(addressVal.long_name);
            this.user.country = {
              label: addressVal.long_name,
              value: addressVal.short_name,
            };
            // this.setDropdownOption('country', this.$refs.countryDropdown.selectedItem);
            break;
          case 'postal_code':
            zipcode = addressVal.long_name;
            break;
          case 'postal_code_suffix':
            zipcodeSuffix += `-${addressVal.long_name}`;
            break;
          default:
        }
      }
      this.user.address1 = `${streetNum}${streetName}`;
      this.user.zipCode = zipcode + (zipcodeSuffix || '');
      this.usingGoogleAddr = true;
    },

    ValidateBriteVerify(callback) {
      const capitalize = (tempSiteID) => {
        if (typeof tempSiteID !== 'string') return '';
        return tempSiteID.charAt(0).toUpperCase() + tempSiteID.slice(1);
      };
      const newSiteID = capitalize(this.globals.siteId);
      const request = {
        remoteName: `${newSiteID}-BV`,
        remoteUrl: '/',
        remoteHeaders: ['Content-Type:application/json', 'Authorization:ApiKey: {$remote_access_token}'],
        postData: `{"email": "${this.user.emailAddress}"}`,
      };
      this.GetAuthToken((success) => {
        if (success) {
          const options = {
            headers: {
              Authorization: `bearer ${this.config.access_token}`,
              'Content-Type': 'application/json',
            },
          };
          axios.post(
            this.API_URL,
            request,
            options,
          ).then(({ data: response }) => {
            if (
              response
              && response.email.status !== 'invalid'
              && response.email.disposable !== true
              && response.email.disposable !== 'true') {
              callback.call(this, true);
            } else {
              callback.call(this, false);
            }
          }).catch(() => {
            callback.call(this, true);
          });
        }
      });
    },

    GetAuthToken(callback) {
      const self = this;
      if (this.config.access_token) {
        if (callback) {
          callback.call(self, true);
        }
      } else {
        // eslint-disable-next-line prefer-template
        axios.post(this.config.urlPrefix + this.config.authUrl)
          .then((response) => {
            if (response && response.data && response.data.access_token) {
              this.config.access_token = response.data.access_token;
              if (callback) {
                callback.call(self, true);
              }
            } else {
              this.handleSendFormDataError.call('The server is unable to accept this form post at this time.');
            }
          })
          .catch(this.handleSendFormDataError);

        // https://localhost:9002/authorizationserver/oauth/token?client_id=mobile_android&client_secret=secret&grant_type=client_credentials
        /*
          {
              "access_token": "e13b0ab7-b93a-4fa0-b38d-b1c05e2b04aa",
              "token_type": "bearer",
              "expires_in": 42074,
              "scope": "basic openid"
          }
        */
      }
    },

    DisableSubmit() {
      if (this.submitButton) {
        this.submitButton.setAttribute('disabled', true);
        this.submitButton.setAttribute('data-enabled-value', this.submitButton.getAttribute('value'));
        this.submitButton.setAttribute('value', 'Validating Email...');
        if (this.$refs.spinner) {
          this.$refs.spinner.showSpinner();
        }
      }
    },

    EnableSubmit() {
      if (this.submitButton) {
        this.submitButton.removeAttribute('disabled');
        this.submitButton.setAttribute('value', this.submitButton.getAttribute('data-enabled-value'));
        this.submitButton.removeAttribute('data-enabled-value');
        if (this.$refs.spinner) {
          this.$refs.spinner.hideSpinner();
        }
      }
    },
  },
  watch: {
    'user.emailAddress': function() {
      this.updateEmailMismatch();
    },
    'user.confirmEmail': function() {
      if (this.user.confirmEmail.length) {
        this.updateEmailMismatch();
      }
    },
  },
};
